import theme from '../../../themes/theme';

const headerStyle: React.CSSProperties = {
  display: 'flex',
  width: '90%',
  padding: '0 5% 0 5%',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'absolute',
  top: 0,
  left: 0,
  height: '120px',
  background: 'linear-gradient(to top, transparent, black)',
};

const styles: Record<string, React.CSSProperties> = {

  rightSideButtonsWrapper: {
    display: 'flex',
    gap: '2rem',
  },

  rightSideButtonsWrapperSmallScreen: {
    display: 'flex',
    gap: '3vw',
    flexDirection: 'row-reverse',
  },

  menuIcon: {
    color: theme.palette.primary.contrastText,
    margin: '0.2rem 0 0 0',
  },

  welcomeHeader: {
    ...headerStyle,
  },
  welcomeHeaderTransparent: {
    ...headerStyle,
    background: 'transparent',
  },
  reservationButton: {
    borderRadius: 0,
    padding: '0.5rem 1rem 0.5rem 1rem',
    fontFamily: theme.typography.h4.fontFamily,
    fontSize: '1.1rem',
    height: '3rem',
    // @ts-ignore
    '&:active': {
      transform: 'scale(0.98)',
    },
  },

  navigationButton: {
    fontFamily: theme.typography.h2.fontFamily,
    fontSize: '1.1rem',
    margin: '0 0.6rem',
    color: 'white',
    // @ts-ignore
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: 'transparent',
    },
  },

  drawer: {
    width: '15rem',
    padding: '1rem 1rem 0 1rem',
    overflow: 'hidden',
  },

  drawerChildrenWrapper: {
    padding: 0,
  },

  navigationButtonsWrapper: {
    padding: '1rem 0 0 0',
    margin: 0,
  },
  navigationButtons: {
    fontSize: '1.5rem',
  },
};
export default styles;
