import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiService from '../../../services/api-service';
import { formatDateToYearMonthDay } from '../../../utils/utils';

const initialState = {
  value: {
    selectedDateSlots: null,
    allSlots: { containers: [] },
  },
};

const companySlotsSlice = createSlice({
  name: 'COMPANY_RESOURCES_SLOTS',
  initialState,
  reducers: {
    setCompanyResourcesSlots(state, action) {
      state.value.allSlots = action.payload;
    },
    setCompanyResourcesSlotsForProvidedDate(state, action) {
      const { allSlots } = state.value;
      const todaySlots = allSlots.containers.filter((slotGroup) => {
        return slotGroup.date === action.payload;
      });
      state.value.selectedDateSlots = todaySlots[0]?.slots;
    },
  },
});

export const { setCompanyResourcesSlots, setCompanyResourcesSlotsForProvidedDate } = companySlotsSlice.actions;
export default companySlotsSlice.reducer;

// Async thunk
export const setCompanyResourceSlotsThunk = createAsyncThunk(
  'COMPANY_SERVICES',
  async (payload: any, { dispatch, rejectWithValue }) => {
    try {
      const companySlotsResponse = await ApiService.getHomeService().getCompanyServiceSlots(
        payload.serviceId,
        formatDateToYearMonthDay(payload.from),
        formatDateToYearMonthDay(payload.to),
      );
      dispatch(setCompanyResourcesSlots(companySlotsResponse));
    } catch (error) {
      return rejectWithValue({
        code: error.code,
        message: error.message,
        status: error.status,
      });
    }
  },
);
