import ApiClient from '../../../services/api-client';
import apiUtil from '../../../services/api-util';
import {
  ICompanyServiceSlotsResponse,
  ICompanyServiceResourceResponseItem,
  ICompanyServiceResponseItem,
  IHomeService,
  IAppointmentServiceResponse,
  IGetAppointmentsServiceResponseItem,
} from '../../../services/serviceTypes';

export default class ApiHomeServices implements IHomeService {
  public async getCompanyServices() {
    const data = await ApiClient.get<ICompanyServiceResponseItem[]>(`/services`, {});
    return data;
  }

  public async getCompanyServiceResources(serviceId: string) {
    const data = await ApiClient.get<ICompanyServiceResourceResponseItem[]>(`/services/${serviceId}/resources`, {});
    return data;
  }

  public async getCompanyServiceSlots(serviceId: string, from?: string, to?: string) {
    const config = apiUtil.parseQueryParams({ from: from, to: to });
    const data = await ApiClient.get<ICompanyServiceSlotsResponse>(`/services/${serviceId}/slots`, config);
    return data;
  }

  public async registerAppointment(hash: string, note: string = ''): Promise<IAppointmentServiceResponse> {
    const data = await ApiClient.post<{ order_id: string }>(`/appointments`, {
      note: note,
      appointments: [
        {
          note: note,
          hash: hash,
        },
      ],
    });
    return data;
  }

  public async getAppointments(limit?: number, offset?: number, order?: 'ASC' | 'DESC') {
    const config = apiUtil.parseQueryParams({ limit, offset, order });
    const data = await ApiClient.get<IGetAppointmentsServiceResponseItem[]>(`/appointments`, config);
    return data;
  }

  public async cancelAppointment(appointmentId: string) {
    await ApiClient.delete<IGetAppointmentsServiceResponseItem[]>(`/appointments/${appointmentId}`);
  }
}
