import React from 'react';
import styles from './SunriseServicePage.style';
import SunriseLogo from '../../components/SunriseLogo/SunriseLogo';
import BookitIcon from '../../../components/foundation/BookitIcon/BookitIcon';
import { links } from '../../../constants/constants';

interface IProps {
  title: string;
  subtitle: string;
}

const SunriseServicePage = ({ title, subtitle }: IProps) => {
  return (
    <div style={styles.pageWrapper}>
      <div style={styles.topContainer}>
        <p style={styles.title}>{title}</p>
        <p style={styles.subtitle}>{subtitle}</p>
      </div>
      <div style={styles.bottomContainer}>
        <div style={styles.iconsWrapper}>
          <a href={links.FACEBOOK_LINK} target='_blank' title={'facebook'} rel='noreferrer'>
            <BookitIcon type='facebook' style={styles.icon} />
          </a>
          <a href={links.INSTAGRAM_LINK} target='_blank' title={'instagram'} rel='noreferrer'>
            <BookitIcon type='instagram' style={styles.icon} />
          </a>
        </div>
        <SunriseLogo size='large' />
      </div>
    </div>
  );
};

export default SunriseServicePage;
