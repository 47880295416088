import theme from '../../themes/theme';

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  title: {
    fontSize: theme.typography.h2.fontSize,
    fontFamily: theme.typography.h2.fontFamily,
    color: theme.palette.primary.contrastText,
    marginTop: '40px',
  },
  text: {
    fontSize: theme.typography.body1.fontSize,
    fontFamily: theme.typography.body1.fontFamily,
    color: theme.palette.primary.contrastText,
  },
  icon: {
    fontSize: '80px',
    color: theme.palette.primary.contrastText,
  },
};
export default styles;
