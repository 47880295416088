import { createSlice } from '@reduxjs/toolkit';

const initialState: { online: boolean } = {
  online: true,
};

const appSlice = createSlice({
  name: 'NETWORK_STATUS',
  initialState,
  reducers: {
    onChangeStatus(state, action) {
      state.online = action.payload;
    },
  },
});

export const { onChangeStatus } = appSlice.actions;
export default appSlice.reducer;
