import React from 'react';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import styles from './GoogleAuth.style';

interface IProps {
  onSuccess: (token: string) => void;
  onError: () => void;
}

const GoogleAuth = ({ onSuccess, onError }: IProps) => {
  const handleSuccess = (credentialResponse: CredentialResponse) => {
    if (!credentialResponse.credential) {
      return;
    }
    onSuccess(credentialResponse.credential);
  };

  const handleError = () => {
    onError();
  };

  return (
    <div style={styles.wrapper}>
      <GoogleLogin text={'continue_with'} size={'large'} locale={'SR-RS'} onSuccess={handleSuccess} onError={handleError} />
    </div>
  );
};

export default GoogleAuth;
