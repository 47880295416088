import theme from '../../themes/theme';

const styles: Record<string, React.CSSProperties> = {
  mainContainer: {
    display: 'flex',
    backgroundColor: theme.palette.secondary.dark,
    fontFamily: theme.typography.fontFamily,
    padding: '1rem 1.5rem',
    justifyContent: 'center',
  },

  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

  divider: {
    height: '3rem',
    borderRight: `1px solid ${theme.palette.primary.main}`,
    margin: '0 0.8rem 0 0.8rem',
  },
};

export default styles;
