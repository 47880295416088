import React from 'react';
import SunriseServicePage from './SunriseServicePage';
import { text } from '../../../constants/constants';

const SunriseUnderConstructionPage = () => {
  return (
    <SunriseServicePage
      title={text.SERVICE_PAGES.UNDER_CONSTRUCTION_TITLE}
      subtitle={text.SERVICE_PAGES.UNDER_CONSTRUCTION_SUBTITLE}
    />
  );
};

export default SunriseUnderConstructionPage;
