import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IOngoingReservation, IStore } from '../../../types';
import { getAppointmentsThunk } from './reservationsSlice';
import ApiService from '../../../services/api-service';
import { getCompanyServicesThunk } from './companyServicesSlice';

const initialState: { value: IOngoingReservation | null; loading: boolean } = {
  value: null,
  loading: false,
};

const cancelReservationsSlice = createSlice({
  name: 'CANCEL_RESERVATION',
  initialState,
  reducers: {
    prepareReservationForCancelAction(state, action) {
      state.value = action.payload;
    },
    clearReservationForCancelAction() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(cancelAppointmentThunk.pending, (state) => {
      state.loading = true;
    });
  },
});

export const { prepareReservationForCancelAction, clearReservationForCancelAction } = cancelReservationsSlice.actions;
export default cancelReservationsSlice.reducer;

export const cancelAppointmentThunk = createAsyncThunk(
  'CANCEL_RESERVATION',
  async (payload: any, { dispatch, getState, rejectWithValue }) => {
    try {
      // @ts-ignore
      const state: IStore = getState();

      const reservationForCancel = state.reservationForCancel.value;
      await ApiService.getHomeService().cancelAppointment(reservationForCancel.id);

      dispatch(clearReservationForCancelAction());
      dispatch(getAppointmentsThunk({}));
      dispatch(getCompanyServicesThunk());
    } catch (error) {
      return rejectWithValue({
        code: error.code,
        message: error.message,
        status: error.status,
      });
    }
  },
);
