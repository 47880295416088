import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IBoAppointmentsSlice } from '../../../../types';
import ApiService from '../../../../services/api-service';
import { formatDateToYearMonthDay } from '../../../../utils/utils';
import { handleNewReservationThunk } from '../../../Home/redux/ongoingReservationSlice';

const initialState: IBoAppointmentsSlice = {
  allAppointments: [],
  next14daysAppointments: [],
  activeAppointments: [],
  scheduledAppointments: null,
  loading: false,
};

const boAppointmentsSlice = createSlice({
  name: 'BACK_OFFICE_APPOINTMENTS',
  initialState,
  reducers: {
    importBoAppointmentsAction(state, action) {
      state.next14daysAppointments = action.payload;
      const activeReservations = state.next14daysAppointments.filter((item) => {
        return item.status === 'APPROVED' && new Date(item.start_date) > new Date();
      });

      state.activeAppointments = activeReservations.sort((a, b) => {
        // @ts-ignore
        return new Date(a.start_date) - new Date(b.start_date);
      });
    },

    importBoAllAppointmentsAction(state, action) {
      state.allAppointments = action.payload;
    },

    importBoScheduleAppointmentsAction(state, action) {
      state.scheduledAppointments = action.payload;
    },

    clearReservationsAction(state) {
      state.next14daysAppointments = [];
      state.activeAppointments = [];
      state.allAppointments = [];
      state.scheduledAppointments = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addBoAppointmentThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(addBoAppointmentThunk.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addBoAppointmentThunk.rejected, (state, action) => {
        state.loading = false;
        // @ts-ignore
        state.error = action?.payload || 'eer'; // Error message passed via rejectWithValue
      });
  },
});

export const { importBoAppointmentsAction, importBoAllAppointmentsAction, importBoScheduleAppointmentsAction } =
  boAppointmentsSlice.actions;
export default boAppointmentsSlice.reducer;
// Async thunk
export const getBoAppointmentsThunk = createAsyncThunk(
  'GET_BACK_OFFICE_APPOINTMENTS',
  async (_payload, { dispatch, rejectWithValue }) => {
    try {
      const today = new Date();
      const nextTwoWeeks = new Date(new Date().setDate(new Date().getDate() + 14));

      const from = formatDateToYearMonthDay(today);
      const to = formatDateToYearMonthDay(nextTwoWeeks);
      const response = await ApiService.backOfficeHomeService().getBoAppointments(from, to);
      dispatch(importBoAppointmentsAction(response));
    } catch (error) {
      return rejectWithValue({
        code: error.code,
        message: error.message,
        status: error.status,
      });
    }
  },
);

export const getBoScheduledAppointmentsThunk = createAsyncThunk(
  'GET_BACK_OFFICE_SCHEDULE_APPOINTMENTS',
  async (payload: { date: string }, { dispatch, rejectWithValue }) => {
    try {
      const formatedDate = formatDateToYearMonthDay(payload.date);
      const response = await ApiService.backOfficeHomeService().getBoScheduledAppointments(formatedDate);
      dispatch(importBoScheduleAppointmentsAction(response));
    } catch (error) {
      return rejectWithValue({
        code: error.code,
        message: error.message,
        status: error.status,
      });
    }
  },
);

export const getBoAppointmentsThunkByDate = createAsyncThunk(
  'GET_BACK_OFFICE_APPOINTMENTS',
  async (payload: { from: Date; to: Date }, { dispatch, rejectWithValue }) => {
    try {
      const from = formatDateToYearMonthDay(payload.from);
      const to = formatDateToYearMonthDay(payload.to);
      const response = await ApiService.backOfficeHomeService().getBoAppointments(from, to);
      dispatch(importBoAllAppointmentsAction(response));
    } catch (error) {
      return rejectWithValue({
        code: error.code,
        message: error.message,
        status: error.status,
      });
    }
  },
);

export const addBoAppointmentThunk = createAsyncThunk(
  'ADD_NEW_BACK_OFFICE_APPOINTMENT',
  async (appointment: any, { dispatch, rejectWithValue }) => {
    try {
      await ApiService.backOfficeHomeService().newBoAppointment(appointment);
    } catch (error) {
      return rejectWithValue({
        code: error.code,
        message: error.message,
        status: error.status,
      });
    }
  },
);

export const cancelBoAppointmentThunk = createAsyncThunk(
  'CANCEL_BACK_OFFICE_APPOINTMENT',
  async (appointmentId: string, { dispatch, getState, rejectWithValue }) => {
    try {
      await ApiService.backOfficeHomeService().cancelBoAppointment(appointmentId);
      dispatch(getBoAppointmentsThunk());
    } catch (error) {
      return rejectWithValue({
        code: error.code,
        message: error.message,
        status: error.status,
      });
    }
  },
);
