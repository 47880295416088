import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../../../../../store/store';
import { loginBoThunk } from '../../../redux/boAuthSlice';
import { text } from '../../../../../../constants/constants';
import styles from './BackOfficeLogin.style';
import BookitButton from '../../../../../../components/foundation/BookitButton/BookitButton';
import BookitInputField from '../../../../../../components/foundation/BookitInputField/BookitInputField';
import SunriseLogo from '../../../../../../customerComponents/components/SunriseLogo/SunriseLogo';
import { handleNewErrorThunk } from '../../../../../App/redux/errorSlice';
import { IStore } from '../../../../../../types';

const BackOfficeLogin = () => {
  const [userName, setUserName] = useState('');
  const [pass, setPass] = useState('');
  const [emailValid, setEmailValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const { loading } = useSelector((store: IStore) => store.backOfficeAuth);


  const buttonDisabled = !emailValid || !passwordValid;

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const handleLoginClick = async () => {
    try {
      await dispatch(loginBoThunk({ email: userName, password: pass })).unwrap();
      navigate('/backoffice');
    } catch (e) {
      dispatch(handleNewErrorThunk(e));
    }
  };

  const handleEnterKeyDown = (event) => {
    if (event.key === 'Enter' && !buttonDisabled) {
      handleLoginClick();
    }
  };


  return (
    <div style={styles.wrapper} onKeyDown={handleEnterKeyDown}>
      <SunriseLogo size={'large'} />
      <h2 style={styles.titleText}> Admin panel</h2>
      <div style={styles.formWrapper}>
        <BookitInputField
          label={text.LOGIN.EMAIL}
          variant='outlined'
          value={userName}
          onChange={(e, valid) => {
            setUserName(e.target.value);
            setEmailValid(valid);
          }}
          type='email'
          helperText={text.EMAIL_HELPER_TEXT}
          required
          size='medium'
          fieldTheme='light'
        />
        <BookitInputField
          variant='outlined'
          label={text.LOGIN.PASSWORD}
          onChange={(e, valid) => {
            setPass(e.target.value);
            setPasswordValid(valid);
          }}
          value={pass}
          style={styles.inputForm}
          type='password'
          required
          size='medium'
          fieldTheme='light'
        />
        <div style={styles.forgotPassBtnWrapper}>
          <BookitButton
            type='basic'
            variant='text'
            color='primary'
            label={text.LOGIN.FORGOT_PASS}
            onClick={() => {
              navigate('/forgot-password-bo');
            }}
            style={styles.forgotPasswordButton}
          />
        </div>
        <BookitButton
          type='basic'
          variant='contained'
          color='primary'
          label={text.LOGIN.LOGIN}
          onClick={handleLoginClick}
          disabled={buttonDisabled}
          style={styles.loginButton}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default BackOfficeLogin;
