import theme from '../../../themes/theme';
import images from '../../images/constants';

export const getStyles = (isMobile) => {
  return styles;
};

const styles: Record<string, React.CSSProperties> = {
  wrapper: {
    userSelect: 'none',
    backgroundColor: theme.palette.secondary.dark,
    fontFamily: theme.typography.fontFamily,
  },

  welcomeWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },

  welcomeImage: {
    height: '40rem',
    width: '100%',
    objectFit: 'cover',
    opacity: 0.3,
  },

  welcomeTitle: {
    fontFamily: theme.typography.h1.fontFamily,
    fontSize: '2.3rem',
    margin: 0,
  },

  welcomeTextWrapper: {
    color: theme.palette.primary.contrastText,
    fontSize: '1rem',
    position: 'absolute',
    textAlign: 'center',
    textShadow: '2px 2px 6px #000000',
  },

  aboutUsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '2rem',
  },

  aboutUsTextWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '3vw',
    margin: 0,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.dark,
    height: '50%',
  },

  aboutUsTitle: {
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.h2.fontFamily,
    fontSize: '1.7rem',
    margin: '0 0 0.8rem 0',
  },

  aboutUsSubtitle: {
    margin: 0,
    fontSize: '0.9rem',
  },

  aboutUsImageWrapper: {
    height: '50%',
  },

  aboutUsImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    opacity: 0.6,
  },

  leaguesWrapper: {
    backgroundColor: theme.palette.secondary.dark,
    marginBottom: '2rem',
  },

  leaguesTitle: {
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.h1.fontFamily,
    fontSize: '2.3rem',
    margin: '1vw 0 3vw 5vw',
  },

  leaguesContentWrapper: {
    width: '90%',
    margin: '0 auto 0 auto',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },

  leaguesTextWrapper: {
    backgroundColor: '#F4F2F0',
    paddingBottom: '3vw',
  },

  leaguesContentsTitle: {
    fontFamily: theme.typography.h3.fontFamily,
    fontSize: '1.7rem',
    marginTop: '5vw',
  },

  leaguesContentsSubtitle: {
    padding: '0 2rem',
    fontSize: '1rem',
  },

  leaguesImages: {
    height: '33%',
    width: '100%',
    aspectRatio: '16/9',
  },

  schoolsWrapper: {
    width: '100%',
    height: '100rem',
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.primary.contrastText,
    marginBottom: '2rem',
  },

  schoolsImageWrapper: {
    flex: 0.25,
  },

  schoolsImage: {
    objectFit: 'cover',
    height: '100%',
    width: '100%',
  },

  schoolsTitle: {
    fontSize: '2rem',
    margin: 0,
    padding: '4vw 5vw 2vw 4vw',
    alignItems: 'left',
    textAlign: 'left',
    fontFamily: theme.typography.h1.fontFamily,
  },

  schoolsDescription: {
    margin: 0,
    fontSize: '1.1rem',
    padding: '3vw 5vw 2vw 4vw',
    textAlign: 'left',
  },

  schoolsButton: {
    borderRadius: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: theme.typography.h4.fontFamily,
    fontSize: '1.4rem',
    color: theme.palette.primary.contrastText,
    padding: '0 0.5rem',
    margin: '5vw 0 2vw 4vw',
    width: '10rem',
    height: '3rem'
  },

  schoolsButtonIcon: {
    padding: '0.7rem 0 0 0',
    fontSize: '2rem',
  },

  footbalSchoolContentWrapper: {
    flex: 0.25,
    backgroundImage: `url(${images.SCHOOL2})`,
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    padding: '3vw',
    margin: 0,
  },

  tennisSchoolContentWrapper: {
    flex: 0.25,
    display: 'flex',
    flexDirection: 'column',
    padding: '3vw',
    margin: 0,
    backgroundColor: theme.palette.primary.dark,
  },

  recreationalSportsWrapper: {
    backgroundColor: '#F4F2F0',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '2rem',
  },

  recreationalSportsContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '50%',
    padding: '3vw 2vw 5vw 6vw',
  },

  recreationalSportsTitle: {
    fontFamily: theme.typography.h1.fontFamily,
    fontSize: '2rem',
    margin: 0,
  },

  recreationalSportsSubtitle: {
    width: '80%',
    fontSize: '1rem',
    textAlign: 'left',
    padding: 0,
  },

  pricingButton: {
    borderRadius: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: theme.typography.h4.fontFamily,
    fontSize: '1.4rem',
    color: 'black',
    padding: '0.4rem 0 0.2rem 0.8rem',
    width: '85%',
    height: '3rem',
    marginTop: '5vw',
  },

  pricingButtonIcon: {
    fontSize: '5vw',
    padding: '0.6rem 0.5rem 0 0',
  },

  recreationalSportsImagesWrapper: {
    height: '50%',
    display: 'flex',
  },

  recreationalSportsImages: {
    objectFit: 'cover',
    width: '25%',
  },

  contactDetailsWrapper: {
    position: 'relative',
    height: '30rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '2rem',
  },

  contactDetailsImage: {
    width: '100%',
    opacity: 0.6,
  },

  contactDetailsTitle: {
    fontFamily: theme.typography.h1.fontFamily,
    fontSize: '2rem',
    color: 'white',
    zIndex: 1,
    position: 'absolute',
    margin: '3% 0 0 0',
    textShadow: '2px 2px 6px #000000',
  },
  mapWrapper: {
    position: 'absolute',
    width: '100%',
    height: '80%',
    margin: '4rem 0 0 0',
    zIndex: 1,
  },

  contactCard: {
    width: '11rem',
    height: '11rem',
    position: 'absolute',
    zIndex: 2,
    top: '60%',
    backgroundColor: theme.palette.primary.dark,
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.primary.contrastText,
    justifyContent: 'space-between',
  },
  contactCardIcons: {
    fontSize: '1.3rem',
    color: theme.palette.primary.contrastText,
  },

  contactCardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    padding: '0.5rem',
  },

  contactCardInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette.primary.contrastText,
  },

  contactCardIconAndTitleWrapper: {
    display: 'flex',
  },

  contactCardInfoContainerTitle: {
    fontSize: '1rem',
    fontFamily: theme.typography.h4.fontFamily,
    margin: 0,
    padding: '0 0 0 0.2rem',
  },

  contactCardInfoContainersubtitle: {
    margin: '0 0 0.4rem 0',
    fontSize: '0.9rem',
    padding: '0 0 0 1.5rem',
  },

  seeMoreButtonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-end',
  },

  seeMoreButton: {
    textDecoration: 'underline',
    textTransform: 'initial',
    padding: '0 1rem 0 0',
    fontWeight: theme.typography.h4.fontWeight,
    // @ts-ignore
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
};

export default styles;
