import { makeStyles } from '@mui/styles';
import theme from '../../../../../themes/theme';

export const useStyles = makeStyles({
  tableExtraSmallCell: {
    flex: '0.1 !important',
  },
  tableSmallCell: {
    flex: '0.3 !important',
  },
  tableMediumCell: {
    flex: '0.5 !important',
  },
  tableLargeCell: {
    flex: '0.7 !important',
  },

  tableActionCell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    flex: 0.2,
    '&:hover': {
      color: `${theme.palette.primary.main} !important`,
    },
  },
});
