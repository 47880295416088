const ERROR_MESSAGES = {
  NO_INTERNET_ERROR: 'No internet connection.\nPlease, make sure that your Wi-Fi or Cellular data is turned on, then try again.',
  DEFAULT_SERVER_ERROR: 'Something went wrong',
};

export class NoInternetError extends Error {
  constructor() {
    super(ERROR_MESSAGES.NO_INTERNET_ERROR);
  }
}

export class ServerError {
  status: number;
  code: number;
  message: string;

  constructor(message?: string) {
    this.message = message || ERROR_MESSAGES.DEFAULT_SERVER_ERROR;
    this.status = 500;
    this.code = 0;
  }
}
