import React from 'react';
import clsx from 'clsx';
import { useStyles } from '../BookitTable/BookitTable.style';

const BookitTable = ({ tableData }) => {
  const styles = useStyles();

  const renderHeader = () => {
    if (!tableData.length) {
      return null;
    }

    const firstRow = tableData[0];
    return (
      <thead>
        <tr className={styles.tableHeader}>
          {Object.keys(firstRow).map((key, index) => {
            return (
              !firstRow[key].hidden && (
                <th key={`${index}-renderTableHeader`} className={clsx(styles.defaultTableCell, firstRow[key].style)}>
                  {firstRow[key].columnName}
                </th>
              )
            );
          })}
        </tr>
      </thead>
    );
  };

  const renderTable = () => (
    <table style={{ width: '100%' }}>
      {renderHeader()}
      <tbody>
        {tableData.map((row, index) => {
          return (
            <tr key={`${index}-renderTableRow`} className={styles.tableRow}>
              {Object.keys(row).map((rowKey) => {
                const rowValue = row[rowKey];
                const { style, content, hidden } = rowValue;
                if (hidden) return null;
                return <td className={clsx(styles.defaultTableCell, style)}>{content}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
  return renderTable();
};

export default BookitTable;
