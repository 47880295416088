import BookitAlertBox from '../foundation/BookitAlertBox/BookitAlertBox';
import { text } from '../../constants/constants';
import BookitButton from '../foundation/BookitButton/BookitButton';
import styles from './ConfirmAccountAlertBox.style';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../types';
import { resendAccountConfirmationEmailThunk } from '../../features/Auth/redux/authSlice';
import { AppDispatch } from '../../store/store';
import { handleNewErrorThunk } from '../../features/App/redux/errorSlice';

interface IProps {
  onConfirm?: () => void;
}

const ConfirmAccountAlertBox = ({ onConfirm }: IProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const profile = useSelector((store: IStore) => store.profile.data);
  const handleConfirmAcc = async () => {
    try {
      await dispatch(resendAccountConfirmationEmailThunk(profile.email)).unwrap();
      onConfirm && onConfirm();
    } catch (e) {
      dispatch(handleNewErrorThunk(e));
    }
  };
  return (
    <div style={styles.wrapper}>
      <BookitAlertBox title={`${text.HEADER.CONFIRM_ACC_TITLE} ${profile.email}.`} type={'warning'}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {text.HEADER.CONFIRM_ACC_TEXT}
          <div>
            <BookitButton
              variant='contained'
              label={text.HEADER.RESEND_CONFIRM_BUTTON}
              onClick={handleConfirmAcc}
              type='basic'
              color='primary'
              style={styles.confirmAccButton}
            />
          </div>
        </div>
      </BookitAlertBox>
    </div>
  );
};

export default ConfirmAccountAlertBox;
