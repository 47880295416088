import React, { useEffect, useState } from 'react';
import { getBoAppointmentsThunkByDate } from '../../redux/boAppointmentsSlice';
import { formatDateToHoursString, formatDateToYearMonthDay } from '../../../../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../../types';
import { AppDispatch } from '../../../../../store/store';
import { useStyles } from './BackOfficeAllAppointmentsTable.style';
import BookitTable from '../../../../../components/foundation/BookitTable/BookitTable';
import BookitCalendar from '../../../../../components/foundation/BookitCalendar/BookitCalendar';
import { addDays } from 'date-fns';

interface ITableDataItem {
  columnName: string;
  value: string | JSX.Element;
  hidden?: boolean;
  style?: React.CSSProperties;
}

const BackOfficeAllAppointmentsTable = () => {
  const styles = useStyles();

  const [tableData, setTableData] = useState<ITableDataItem[]>([]);
  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(addDays(from, 30));

  const appointments = useSelector((state: IStore) => state.backOfficeAppointments);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getBoAppointmentsThunkByDate({ from, to }));
  }, [from, to]);

  useEffect(() => {
    if (appointments?.allAppointments) {
      const mappedTableData = tableDataMap(appointments.allAppointments);
      setTableData(mappedTableData);
    }
  }, [appointments, from, to]);

  const tableDataMap = (initialData) => {
    return initialData.map((item, index) => {
      return {
        id: { columnName: item.id, content: item.id, hidden: true },
        no: { columnName: '#', content: index + 1, style: styles.tableExtraSmallCell },
        date: { columnName: 'Datum', content: formatDateToYearMonthDay(item.start_date), style: styles.tableMediumCell },
        time: { columnName: 'Vreme', content: formatDateToHoursString(item.start_date), style: styles.tableSmallCell },
        serviceName: { columnName: 'Sport', content: item.service.name, style: styles.tableMediumCell },
        resourceName: { columnName: 'Teren', content: item.resource.name, style: styles.tableLargeCell },
        userFullName: {
          columnName: 'Ime i prezime',
          content: `${item.retail_user?.first_name} ${item.retail_user?.last_name}`,
        },
        userPhoneNumber: {
          columnName: 'Broj telefona',
          content: `${item.retail_user?.phone_number.international_format}`,
          style: styles.tableLargeCell,
        },
        userEmail: { columnName: 'Email', content: `${item.retail_user?.email}` },
        note: { columnName: 'Beleška', content: `${item.note}`, style: styles },
        status: { columnName: 'Status', content: `${item.status}` },
      };
    });
  };

  const renderCalendarPickers = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          width: '100%',
          marginTop: '1rem',
          marginBottom: '1rem',
        }}
      >
        <div style={{ marginRight: '1rem' }}>
          <BookitCalendar
            label={'Od'}
            selectedDate={from}
            setSelectedDate={(date) => {
              setFrom(date);
            }}
          />
        </div>
        <BookitCalendar
          label={'Do'}
          selectedDate={to}
          setSelectedDate={(date) => {
            setTo(date);
          }}
        />
      </div>
    );
  };

  return (
    <div style={{ width: '90%', paddingTop: '3rem' }}>
      {renderCalendarPickers()}
      <BookitTable tableData={tableData} />
    </div>
  );
};

export default BackOfficeAllAppointmentsTable;
