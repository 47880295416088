import React from 'react';
import { AppBar } from '@mui/material';

interface IProps {
  position: 'fixed' | 'absolute' | 'sticky' | 'static' | 'relative';
  style: React.CSSProperties;
  contentStyle: React.CSSProperties;
  children: any;
}

const BookitHeader = ({ position, style, contentStyle, children }: IProps) => {
  return (
    <>
      <AppBar position={position} sx={style}>
        <div style={contentStyle}>{children}</div>
      </AppBar>
    </>
  );
};

export default BookitHeader;
