import React from 'react';
import BookitButton from '../../components/foundation/BookitButton/BookitButton';
import { text } from '../../constants/constants';
import styles from './ConfirmReservationDrawer.style';
import BookitDrawer from '../foundation/BookitDrawer/BookitDrawer';
import { IStore } from '../../types';
import { TextField } from '@mui/material';
import { useIsMobile } from '../../hooks/IsMobileHook';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store/store';
import { addOngoingReservationNoteAction } from '../../features/Home/redux/ongoingReservationSlice';
import BookitAlertBox from '../foundation/BookitAlertBox/BookitAlertBox';
import { isUserLoggedIn } from '../../utils/utils';

const formattedDate = (date) => {
  if (!date) {
    return;
  }
  return date.toLocaleDateString('sr-Latn-RS', { weekday: 'long', year: 'numeric', month: 'long', day: '2-digit' });
};

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  bookedField: {
    name: string;
    description: string;
    image_url: string;
  };
  selectedDate: Date;
  selectedTime: string;
}

const ConfirmReservationDrawer = ({ isOpen, onClose, onConfirm, bookedField, selectedDate, selectedTime }: IProps) => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch<AppDispatch>();
  const note = useSelector((store: IStore) => store.ongoingReservation.note);
  const price = useSelector((store: IStore) => store.ongoingReservation.price);
  const loading = useSelector((store: IStore) => store.ongoingReservation.loading);
  const isUserLogged = isUserLoggedIn();

  const handleConfirm = () => {
    onConfirm();
  };

  const handleCancel = () => {
    dispatch(addOngoingReservationNoteAction(''));
    onClose();
  };

  const renderLoginAlert = () => (
    <>
      <BookitAlertBox type={'warning'}>{text.LOGIN.LOGIN_FIRST}</BookitAlertBox>
      <div style={styles.loginAlertWrapper}>
        <BookitButton
          variant='outlined'
          routerPath={'/login'}
          type='routerLink'
          label={text.SIGNUP.LOGIN_BUTTON}
          color='primary'
        />
      </div>
    </>
  );

  const renderNote = () => (
    <div style={styles.noteWrapper}>
      <p style={styles.noteTitle}>{text.CONFIRM_RESERVATION_DRAWER.NOTE_TITLE}</p>
      <TextField
        multiline
        placeholder={text.CONFIRM_RESERVATION_DRAWER.NOTE_PLACEHOLDER}
        value={note}
        onChange={(e) => dispatch(addOngoingReservationNoteAction(e.target.value))}
        maxRows={4}
        sx={styles.note}
      />
    </div>
  );

  const renderPricing = () => (
    <>
      <hr style={styles.divider} />
      {price > 0 && (
        <p style={styles.price}>
          <span style={styles.label}>{text.CONFIRM_RESERVATION_DRAWER.PRICE}</span>
          <span>{`${price} RSD`}</span>
        </p>
      )}
    </>
  );

  const renderButtons = () => {
    if (isMobile) {
      return (
        <div style={styles.mobileButtonContainer}>
          {isUserLogged && (
            <BookitButton
              variant='contained'
              label={text.CONFIRM_RESERVATION_DRAWER.SUBMIT_BUTTON}
              type='basic'
              color='primary'
              style={styles.mobileSubmitButton}
              onClick={handleConfirm}
              loading={loading}
              disableRipple
            />
          )}
          <BookitButton
            variant='text'
            label={text.CONFIRM_RESERVATION_DRAWER.DISCARD_BUTTON}
            type='basic'
            color='secondary'
            style={styles.mobileDiscardButton}
            onClick={handleCancel}
            disableRipple
          />
        </div>
      );
    }
    return (
      <div style={styles.buttonContainer}>
        <BookitButton
          variant='text'
          label={text.CONFIRM_RESERVATION_DRAWER.DISCARD_BUTTON}
          type='basic'
          color='secondary'
          style={styles.discardButton}
          onClick={handleCancel}
          disableRipple
        />
        {isUserLogged && (
          <BookitButton
            variant='contained'
            label={text.CONFIRM_RESERVATION_DRAWER.SUBMIT_BUTTON}
            type='basic'
            color='primary'
            style={styles.submitButton}
            onClick={handleConfirm}
            loading={loading}
            disableRipple
          />
        )}
      </div>
    );
  };

  return (
    <BookitDrawer
      isOpen={isOpen}
      onClose={onClose}
      drawerHeaderStyle={isMobile ? styles.mobileDrawerHeader : styles.drawerHeader}
      childrenStyle={{ padding: 0, overflow: 'auto' }}
      style={!isMobile && styles.drawer}
      title={text.CONFIRM_RESERVATION_DRAWER.TITLE}
    >
      {!isUserLogged && renderLoginAlert()}
      <div style={styles.drawerContainer}>
        <div style={isMobile ? styles.mobileDrawerData : styles.drawerData}>
          <div style={isMobile ? styles.mobileInfoContainer : styles.infoContainer}>
            {[
              { value: <img src={bookedField.image_url} alt='Selected field' style={styles.image} /> },
              { label: text.CONFIRM_RESERVATION_DRAWER.SELECTED_FIELD, value: bookedField.name },
              { label: text.CONFIRM_RESERVATION_DRAWER.SELECTED_FIELD_DETAILS, value: bookedField.description },
              { label: text.CONFIRM_RESERVATION_DRAWER.SELECTED_DATE, value: formattedDate(selectedDate) },
              { label: text.CONFIRM_RESERVATION_DRAWER.SELECTED_TIME, value: selectedTime },
            ].map((item, index) => (
              <p key={index} style={styles.drawerInfo}>
                <span style={styles.label}>{item.label}</span>
                <span>{item.value}</span>
              </p>
            ))}
            {renderNote()}
            {renderPricing()}
          </div>
        </div>
        {renderButtons()}
      </div>
    </BookitDrawer>
  );
};

export default ConfirmReservationDrawer;
