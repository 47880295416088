import { getStyles } from '../../components/SunriseInfoPage/SunriseInfoPage.style';

const parentStyles = (isMobile: boolean): Record<string, React.CSSProperties> => {
  const styles = getStyles(isMobile);

  return {
    wrapper: {
      ...styles.parentWrapper,
    },

    title: {
      ...styles.parentTitle,
    },

    content: {
      ...styles.parentContent,
    },
  };
};

export default parentStyles;
