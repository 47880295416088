import theme from '../../themes/theme';

const emptyDrawerContainerStyle: React.CSSProperties = {
  display: 'flex',
  minHeight: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: theme.typography.h4.fontSize,
  color: theme.palette.secondary.light,
};

const styles: Record<string, React.CSSProperties> = {
  accountIcon: {
    color: theme.palette.primary.contrastText,
    margin: '0.4rem 0 0 0',
  },

  mainDrawerContainer: {
    display: 'flex',
    color: theme.palette.primary.contrastText,
    height: '100%',
    flexDirection: 'column',
  },
  mainDrawerContainerMobile: {
    display: 'flex',
    color: theme.palette.primary.contrastText,
    maxHeight: '60rem',
    minHeight: '30rem',
    flexDirection: 'column',
  },

  emptyDrawerContainer: {
    ...emptyDrawerContainerStyle,
  },
  emptyDrawerContainerMobile: {
    ...emptyDrawerContainerStyle,
    minHeight: '30rem',
    maxHeight: '60rem',
    textAlign: 'center',
  },
  image: {
    width: '9rem',
    height: '9rem',
    margin: '0 0 2rem 0',
  },

  activeReservationsButton: {
    color: theme.palette.primary.contrastText,
    // @ts-ignore
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: 'transparent',
    },
  },
};

export default styles;
