import React from 'react';
import BookitHeader from '../../components/foundation/BookitHeader/BookitHeader';
import styles from './Header.style';

import SunriseLogo from '../../customerComponents/components/SunriseLogo/SunriseLogo';
import ActiveReservations from '../ActiveReservations/ActiveReservations';
import Profile from '../Profile/Profile';

const Header = () => {
  const renderLogo = () => {
    return (
      <div style={styles.logoContainer}>
        <SunriseLogo size='medium' />
      </div>
    );
  };

  return (
    <BookitHeader position='relative' style={styles.mainContainer} contentStyle={styles.contentContainer}>
      <div>{renderLogo()}</div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <ActiveReservations />
        <div style={styles.divider} />
        <Profile />
      </div>
    </BookitHeader>
  );
};

export default Header;
