import React from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import styles from './BookitTabs.style';

interface ITabData {
  label: string;
}
interface IProps {
  tabs: ITabData[];
  value: number;
  onChange: (e: React.ChangeEvent<{}>, newValue: number) => void;
}

const BookitTabs = ({ tabs, value, onChange }: IProps) => {
  return (
    <div>
      <Box sx={styles.box}>
        <Tabs centered value={value} onChange={onChange} textColor={'primary'} indicatorColor={'primary'}>
          {tabs.map((tab, index) => (
            <Tab sx={styles.inactiveTab} key={`${tab.label}+${index}`} label={tab.label} />
          ))}
        </Tabs>
      </Box>
      {/*{tabs[value].render}*/}
    </div>
  );
};

export default BookitTabs;
