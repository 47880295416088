import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiService from '../../../services/api-service';

interface IPayload {
  id: string;
  name: string;
  description: string;
}

const initialState = {
  value: {
    id: null,
    name: null,
    description: null,
    resources: [],
  },
};

const companySelectedResourcesSlice = createSlice({
  name: 'SELECTED_COMPANY_SERVICE',
  initialState,
  reducers: {
    setSelectedServiceAction(state, action) {
      state.value.id = action.payload.id;
      state.value.name = action.payload.name;
      state.value.description = action.payload.description;
    },
    setSelectedServiceResourcesAction(state, action) {
      state.value.resources = action.payload.sort((a, b) => a.name.localeCompare(b.name));
    },
  },
});

export const { setSelectedServiceAction, setSelectedServiceResourcesAction } = companySelectedResourcesSlice.actions;
export default companySelectedResourcesSlice.reducer;

// Async thunk
export const setCompanySelectedServiceDataThunk = createAsyncThunk(
  'SELECTED_COMPANY_SERVICE',
  async (payload: IPayload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setSelectedServiceAction(payload));
      const companyServiceResponse = await ApiService.getHomeService().getCompanyServiceResources(payload.id);
      dispatch(setSelectedServiceResourcesAction(companyServiceResponse));
    } catch (error) {
      return rejectWithValue({
        code: error.code,
        message: error.message,
        status: error.status,
      });
    }
  },
);
