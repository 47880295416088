import React, { ReactNode } from 'react';
import { Autocomplete } from '@mui/material';

interface IProps<T> {
  options: T[];
  value: string;
  onChange: (event, newValue) => void;
  getOptionLabel: (option: T) => string;
  placeholder: (params: object) => ReactNode;
  style: React.CSSProperties;
  disableClearable: boolean;
  autoHighlight: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

function BookitDropdown<T>({
  options,
  value,
  onChange,
  getOptionLabel,
  placeholder,
  style,
  disableClearable,
  autoHighlight,
  onOpen,
  onClose,
}: IProps<T>) {
  return (
    <Autocomplete
      options={options}
      value={value}
      onChange={onChange}
      getOptionLabel={getOptionLabel}
      renderInput={placeholder}
      sx={style}
      disableClearable={disableClearable}
      autoHighlight={autoHighlight}
      onOpen={onOpen}
      onClose={onClose}
    />
  );
}

export default BookitDropdown;
