import theme from '../../../themes/theme';

const styles: Record<string, React.CSSProperties> = {
  drawer: {
    backgroundColor: theme.palette.secondary.main,
    fontFamily: theme.typography.fontFamily,
    width: '27.5rem',
    padding: '1.5rem',
    overflow: 'hidden',
  },

  drawerMobile: {
    backgroundColor: theme.palette.secondary.main,
    fontFamily: theme.typography.fontFamily,
    width: '100vm',
    padding: '1.5rem',
    maxHeight: '80vh',
  },

  childrenWrapper: {
    height: '92vh',
    padding: '0 0.5rem 2rem 0',
  },

  drawerCloseIcon: {
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
    fontFamily: theme.typography.h1.fontFamily,
    fontSize: theme.typography.h2.fontSize,
  },

  drawerHeaderWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },

  title: {
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.h1.fontFamily,
    fontSize: theme.typography.h2.fontSize,
    marginTop: '0',
  },

  openIconWrapper: {
    borderTopLeftRadius: '6px',
    borderBottomLeftRadius: '6px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.main,
    overflow: 'visible',
    width: '4rem',
    height: '4rem',
    cursor: 'pointer',
    zIndex: 10,
  },

  openIcon: {
    color: 'white',
    fontSize: '3rem',
    // @ts-ignore
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
};

export default styles;
