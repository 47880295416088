import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../../store/store';
import { IStore } from '../../../../../types';
import { getBoUsersThunk } from '../../redux/boUsersSlice';
import BookitTable from '../../../../../components/foundation/BookitTable/BookitTable';
import { formatDateToYearMonthDay } from '../../../../../utils/utils';
import { useStyles } from './BackOfficeUsersTable.style';
import { BackOfficeBlockUserAction } from '../BackOfficeBlockUserAction/BackOfficeBlockUserAction';
import clsx from 'clsx';
import BookitButton from '../../../../../components/foundation/BookitButton/BookitButton';

type TFilterStatus = 'ALL' | 'ACCOUNT_NOT_VERIFIED' | 'BLOCKED' | 'ACTIVE' | null;
interface IButtonDataItem {
  label: string;
  filter: TFilterStatus;
  handleClick: () => void;
}

const BackOfficeUsersTable = () => {
  const styles = useStyles();

  const { allUsers } = useSelector((state: IStore) => state.backOfficeUsers);
  const [users, setUsers] = useState([]);
  const [activeFilter, setActiveFilter] = useState<TFilterStatus>('ALL');

  const dispatch = useDispatch<AppDispatch>();

  const usersPreparation = (filterByStatus: TFilterStatus = null) => {
    if (allUsers && allUsers.length > 0) {
      const sortedUsers = [...allUsers].sort((a, b) => {
        const aDate = new Date(a.created_at).getTime();
        const bDate = new Date(b.created_at).getTime();

        return bDate - aDate;
      });

      const preparedUsers = prepareTableData(sortedUsers);
      switch (filterByStatus) {
        case 'ACTIVE':
        case 'ACCOUNT_NOT_VERIFIED':
        case 'BLOCKED':
          const filteredPreparedUsers = preparedUsers.filter((preparedUser) => preparedUser.status.content === filterByStatus);
          setUsers(filteredPreparedUsers);
          break;
        case 'ALL':
        default:
          setUsers(preparedUsers);
      }
    }
  };

  useEffect(() => {
    dispatch(getBoUsersThunk());
  }, []);

  useEffect(() => {
    usersPreparation(activeFilter);
  }, [allUsers, activeFilter]);

  const buttonsData: IButtonDataItem[] = [
    {
      label: 'Svi',
      filter: 'ALL',
      handleClick: () => {
        setActiveFilter('ALL');
      },
    },
    {
      label: 'Aktivni',
      filter: 'ACTIVE',
      handleClick: () => {
        setActiveFilter('ACTIVE');
      },
    },
    {
      label: 'Blokirani',
      filter: 'BLOCKED',
      handleClick: () => {
        setActiveFilter('BLOCKED');
      },
    },
    {
      label: 'Čekaju verifikaciju',
      filter: 'ACCOUNT_NOT_VERIFIED',
      handleClick: () => {
        setActiveFilter('ACCOUNT_NOT_VERIFIED');
      },
    },
  ];

  const prepareTableData = (initialData) => {
    return initialData.map((user, index) => {
      return {
        id: { columnName: user?.id, content: user?.id, hidden: true },
        userNo: { columnName: '#', content: index + 1, style: styles.tableExtraSmallCell },
        creationDate: {
          columnName: 'Datum registracije',
          content: formatDateToYearMonthDay(user?.created_at),
          style: styles.tableMediumCell,
        },
        name: { columnName: 'Ime i prezime', content: `${user?.first_name} ${user?.last_name}` },
        phoneNumber: {
          columnName: 'Telefon',
          content: `${user?.phone_number.international_format}`,
          style: styles.tableMediumCell,
        },
        email: { columnName: 'Email', content: `${user?.email}` },
        status: { columnName: 'Status', content: `${user?.status}` },
        action: {
          columnName: '',
          content: user?.status && <BackOfficeBlockUserAction user={user} />,
          style: clsx(styles.tableActionCell),
        },
      };
    });
  };

  return (
    <div style={{ width: '90%', paddingTop: '3rem' }}>
      <div style={{ margin: '1rem 0 1rem 0' }}>
        {buttonsData.map(({ label, filter, handleClick }, index) => (
          <BookitButton
            style={{ marginRight: '0.5rem' }}
            variant={filter === activeFilter ? 'contained' : 'outlined'}
            label={label}
            type={'basic'}
            color={'primary'}
            onClick={handleClick}
          />
        ))}
      </div>
      <BookitTable tableData={users} />
    </div>
  );
};

export default BackOfficeUsersTable;
