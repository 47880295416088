import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiService from '../../../../services/api-service';

const initialState = {
  allUsers: [],
};

const botUsersSlice = createSlice({
  name: 'BACK_OFFICE_USERS',
  initialState,
  reducers: {
    importBoUserAction(state, action) {
      state.allUsers = action.payload;
    },
  },
});

export const { importBoUserAction } = botUsersSlice.actions;
export default botUsersSlice.reducer;

export const getBoUsersThunk = createAsyncThunk('GET_BACK_OFFICE_USERS', async (payload, { dispatch, rejectWithValue }) => {
  try {
    const data = await ApiService.backOfficeHomeService().getBoAllUsers();
    dispatch(importBoUserAction(data));
  } catch (error) {
    return rejectWithValue({
      code: error.code,
      message: error.message,
      status: error.status,
    });
  }
});

export const blockBoUserThunk = createAsyncThunk(
  'BLOCK_BACK_OFFICE_USER',
  async (payload: { userId: string }, { dispatch, rejectWithValue }) => {
    try {
      await ApiService.backOfficeHomeService().blockBoUser(payload.userId);
      dispatch(getBoUsersThunk());
    } catch (error) {
      return rejectWithValue({
        code: error.code,
        message: error.message,
        status: error.status,
      });
    }
  },
);

export const unblockBoUserThunk = createAsyncThunk(
  'UNBLOCK_BACK_OFFICE_USER',
  async (payload: { userId: string }, { dispatch, rejectWithValue }) => {
    try {
      await ApiService.backOfficeHomeService().unblockBoUser(payload.userId);
      dispatch(getBoUsersThunk());
    } catch (error) {
      return rejectWithValue({
        code: error.code,
        message: error.message,
        status: error.status,
      });
    }
  },
);
