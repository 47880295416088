import theme from '../../themes/theme';

const mainContainer: React.CSSProperties = {
  width: '25rem',
  height: '34rem',
  margin: '2.5rem 0.5rem 0 0.5rem',
  backgroundColor: theme.palette.secondary.dark,
  fontFamily: theme.typography.fontFamily,
  transition: 'filter 0.3s',
  textAlign: 'center',
  boxSizing: 'border-box',
};

const styles: Record<string, React.CSSProperties> = {
  container: {
    ...mainContainer,
    filter: 'brightness(80%)',
    // @ts-ignore
    // TODO - fix this
    '&:hover': {
      filter: 'brightness(100%)',
      transition: 'filter 0.3s',
    },
  },

  containerSelected: {
    ...mainContainer,
    filter: 'brightness(100%)',
  },

  containerNotSelected: {
    ...mainContainer,
    filter: 'brightness(80%)',
    // @ts-ignore
    // TODO - fix this
    '&:hover': {
      filter: 'brightness(100%)',
      transition: 'filter 0.3s',
    },
  },

  containerDisabled: {
    ...mainContainer,
    pointerEvents: 'none',
    opacity: '0.3',
    filter: 'brightness(80%)',
  },

  imageContainer: {
    position: 'relative',
    height: 270,
  },

  imagePlaceholderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    borderBottom: '1px solid grey',
  },

  imagePlaceholder: {
    fontSize: 50,
    color: theme.palette.primary.contrastText,
  },

  timeslotsPlaceholder: {
    fontSize: 50,
    color: theme.palette.primary.contrastText,
    margin: '2rem 0 0 0',
  },

  timeslotsPlaceholderText: {
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.h4.fontSize,
  },

  image: {
    width: '100%',
  },

  title: {
    margin: '0 0 0.5rem 0.5rem',
    textAlign: 'left',
    userSelect: 'none',
    fontSize: theme.typography.h3.fontSize,
    fontWeight: theme.typography.h4.fontWeight,
    color: theme.palette.primary.contrastText,
  },

  note: {
    fontSize: theme.typography.body1.fontSize,
    textAlign: 'left',
    margin: '0 0 1rem 0.5rem',
    color: theme.palette.primary.contrastText,
    userSelect: 'none',
  },
};

export default styles;
