import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiService from '../../../services/api-service';

const initialState = {
  value: null,
};

const companyServicesSlice = createSlice({
  name: 'COMPANY_SERVICES',
  initialState,
  reducers: {
    getCompanyServicesAction(state, action) {
      state.value = action.payload;
    },
  },
});

export const { getCompanyServicesAction } = companyServicesSlice.actions;
export default companyServicesSlice.reducer;
// Async thunk
export const getCompanyServicesThunk = createAsyncThunk('COMPANY_SERVICES', async (payload, { dispatch, rejectWithValue }) => {
  try {
    const companyServiceResponse = await ApiService.getHomeService().getCompanyServices();
    dispatch(getCompanyServicesAction(companyServiceResponse));
  } catch (error) {
    return rejectWithValue({
      code: error.code,
      message: error.message,
      status: error.status,
    });
  }
});
