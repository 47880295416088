import { format } from 'date-fns';

export const isUserLoggedIn = () => {
  const jwt = localStorage.getItem('ACCESS_TOKEN');
  if (!jwt) {
    return false;
  }
  return true;
};

export const isBoUserLoggedIn = () => {
  const jwt = localStorage.getItem('BO_ACCESS_TOKEN');
  if (!jwt) {
    return false;
  }
  return true;
};

export const setAccessToken = (token: string) => {
  localStorage.setItem('ACCESS_TOKEN', token);
};

export const setBoAccessToken = (token: string) => {
  localStorage.setItem('BO_ACCESS_TOKEN', token);
};

export const deleteAccessToken = () => {
  try {
    const jwt = localStorage.getItem('ACCESS_TOKEN');
    if (!jwt) {
      return false;
    }
    localStorage.removeItem('ACCESS_TOKEN');
  } catch (e) {
    console.error(e);
  }
};

export const deleteBoAccessToken = () => {
  try {
    const jwt = localStorage.getItem('BO_ACCESS_TOKEN');
    if (!jwt) {
      return false;
    }
    localStorage.removeItem('BO_ACCESS_TOKEN');
  } catch (e) {
    console.error(e);
  }
};

export const increaseForHourS = (dateStr: string, increaseForHours: number = 1) => {
  const date = new Date(dateStr);
  date.setHours(date.getHours() + increaseForHours);

  const newDateStr = date.toISOString();
  return newDateStr;
};

export const formatDateToYearMonthDay = (dateStr: Date | string) => {
  return format(new Date(dateStr), 'yyyy-MM-dd');
};

export const formatDateToHoursString = (dateStr: string) => {
  return `${format(new Date(dateStr), 'HH:mm')}h`;
};

export const formatDateToRegular = (date) => {
  return new Date(date).toLocaleDateString('sr-Latn-RS', { weekday: 'long', year: 'numeric', month: 'long', day: '2-digit' });
};

export const customSortBySport = (sports) => {
  return sports.sort((a, b) => {
    if (a.name === 'Fudbal') return -1;
    if (b.name === 'Fudbal') return 1;
    if (a.name === 'Tenis') return -1;
    if (b.name === 'Tenis') return 1;
    return a.name.localeCompare(b.name);
  });
};
