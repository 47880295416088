import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { text } from '../../constants/constants';
import { DEFAULT_COUNTRY_CODE, countriesData } from '../../constants/countries';
import { ICountry } from '../../types';
import BookitDropdown from '../foundation/BookitDropdownField/BookitDropdownField';
import styles from './CountryCodesDropdownField.style';

interface IProps {
  selectedCountry: string;
  setSelectedCountry: React.Dispatch<React.SetStateAction<ICountry | null>>;
}

const CountryCodesDropdownField = ({ selectedCountry, setSelectedCountry }: IProps) => {
  const [showCountryName, setShowCountryName] = useState(false);

  useEffect(() => {
    const defaultIndex = countriesData.findIndex((country) => country.code === DEFAULT_COUNTRY_CODE);
    if (defaultIndex !== -1) {
      setSelectedCountry({
        code: countriesData[defaultIndex].code,
        phoneCode: countriesData[defaultIndex].phoneCode,
        name: countriesData[defaultIndex].name,
        emojiFlag: countriesData[defaultIndex].emojiFlag,
      });
    }
  }, []);

  const handleDropdownLabel = (option: ICountry): string => {
    if (showCountryName) {
      return `${option.emojiFlag} +${option.phoneCode} ${option.name}`;
    } else {
      return `${option.emojiFlag} +${option.phoneCode}`;
    }
  };

  const handleOpen = () => {
    setShowCountryName(true);
    setSelectedCountry(null);
  };

  const handleClose = () => {
    setShowCountryName(false);
  };


  return (
    <BookitDropdown<ICountry>
      options={countriesData}
      value={selectedCountry}
      getOptionLabel={handleDropdownLabel}
      placeholder={(params) => <TextField {...params} label={text.SIGNUP.PHONE_CODE_INPUT_LABEL} />}
      onChange={(event, newValue) => {
        if (newValue) {
          setSelectedCountry(newValue);
        }
      }}
      onOpen={handleOpen}
      onClose={handleClose}
      disableClearable
      autoHighlight
      style={styles.countryCodes}
    />
  );
};

export default CountryCodesDropdownField;
