import theme from '../../themes/theme';

const styles: Record<string, React.CSSProperties> = {
  countryCodes: {
    userSelect: 'none',
    cursor: 'pointer',
    width: '11rem',
    margin: '0 0.5rem 0 0',
    // @ts-ignore
    '& .MuiInputLabel-root': {
      color: theme.palette.secondary.light,
      fontSize: '0.85rem',
      margin: '-0.26rem 0 0 0',
      '&.Mui-focused': {
        color: theme.palette.primary.main,
        margin: '-0.26rem 0 0 0',
      },
    },
    // @ts-ignore
    '& .MuiInputBase-root': {
      color: theme.palette.primary.contrastText,
    },
    // @ts-ignore
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.secondary.light,
        color: theme.palette.secondary.light,
        borderWidth: '2px',
        borderRadius: '1px',
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
      height: '2.6rem',
      padding: '0 0 0 0.5rem',
    },
    '& .MuiAutocomplete-popupIndicator': {
      color: theme.palette.secondary.light,
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  },
};

export default styles;
