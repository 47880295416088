import React, { useState } from 'react';
import BookitDrawer from '../foundation/BookitDrawer/BookitDrawer';
import styles from './ActiveReservations.style';
import { text } from '../../constants/constants';
import BookitIcon from '../foundation/BookitIcon/BookitIcon';
import ActiveReservationsItem from '../ActiveReservationsItem/ActiveReservationsItem';
import images from '../../images/constants';
import BookitSnackbar from '../foundation/BookitSnackbar/BookitSnackbar';
import { useIsMobile } from '../../hooks/IsMobileHook';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../types';
import { cancelAppointmentThunk } from '../../features/Home/redux/cancelReservatonSlice';
import { handleNewErrorThunk } from '../../features/App/redux/errorSlice';
import { AppDispatch } from '../../store/store';
import { Badge } from '@mui/material';
import BookitButton from '../foundation/BookitButton/BookitButton';

const ActiveReservations = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const isMobile = useIsMobile();
  const dispatch = useDispatch<AppDispatch>();

  const activeReservations = useSelector((store: IStore) => store.reservations.activeAppointments);

  const handleCancelReservation = async () => {
    try {
      await dispatch(cancelAppointmentThunk({})).unwrap();
      setIsCancelDialogOpen(false);
      setSnackbarOpen(true);
    } catch (e) {
      dispatch(handleNewErrorThunk(e));
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const renderActiveReservationsIcon = () => <BookitIcon type='calendar' style={styles.accountIcon} />;
  const renderButtonLabel = () => (
    <Badge badgeContent={activeReservations.length} color='error' invisible={activeReservations.length === 0}>
      {isMobile ? renderActiveReservationsIcon() : <div>{text.HEADER.ACTIVE_RESERVATIONS}</div>}
    </Badge>
  );

  const renderActiveReservations = () => {
    return (
      <div style={isMobile ? styles.mainDrawerContainerMobile : styles.mainDrawerContainer}>
        {activeReservations.length ? (
          <>
            {activeReservations.map((item) => (
              <ActiveReservationsItem
                isDialogOpen={isCancelDialogOpen}
                setIsDialogOpen={setIsCancelDialogOpen}
                reservation={item}
                handleCancelReservation={handleCancelReservation}
              />
            ))}
          </>
        ) : (
          <>
            <div style={isMobile ? styles.emptyDrawerContainerMobile : styles.emptyDrawerContainer}>
              <img
                src={images.NO_RESERVATIONS}
                alt='Orange magnifying glass with cards around it on a black surface'
                style={styles.image}
              />
              {text.HEADER.NO_ACTIVE_RESERVATIONS_DRAWER_TITLE}
            </div>
          </>
        )}
        <BookitSnackbar open={snackbarOpen} text={text.HEADER.SUCCESS_CANCEL_RESERVATION_MESSAGE} onClose={handleSnackbarClose} />
      </div>
    );
  };

  return (
    <>
      <BookitButton
        variant='text'
        type='basic'
        style={styles.activeReservationsButton}
        label={renderButtonLabel()}
        onClick={() => {
          setIsDrawerOpen(true);
        }}
      />
      <BookitDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        title={text.HEADER.ACTIVE_RESERVATIONS}
        childrenStyle={{ overflow: 'auto' }}
      >
        {renderActiveReservations()}
      </BookitDrawer>
    </>
  );
};

export default ActiveReservations;
