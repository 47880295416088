import React from 'react';
import { IField } from '../../../types';
import BookitButton from '../BookitButton/BookitButton';
import styles from './BookitTimePicker.style';

interface IProps {
  field: IField;
  bookedField: IField;
  time: string;
  selectedTime: string;
  disabled: boolean;
  onClick: (field: IField, time: string, hash?: string) => void;
  hash?: string;
}

const BookitTimePicker = ({ field, bookedField, time, selectedTime, disabled, onClick, hash }: IProps) => {
  const buttonStyle = () => {
    if (disabled) {
      return styles.disabled;
    }
    if (field.id !== bookedField.id) {
      return styles.time;
    }
    if (selectedTime !== time) {
      return styles.time;
    }
    return styles.selectedTime;
  };

  const onTimeClick = (time) => {
    onClick(field, time, hash);
  };

  return (
    <BookitButton
      type='timePicker'
      color='secondary'
      variant='outlined'
      label={time}
      disabled={disabled}
      onClick={() => onTimeClick(time)}
      style={buttonStyle()}
    />
  );
};

export default BookitTimePicker;
