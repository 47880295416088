import React from 'react';
import { text } from '../../constants/constants';
import styles from './ActiveReservationsItem.style';
import CancelReservationDialog from '../../dialogs/CancelReservationDialog/CancelReservationDialog';
import { useDispatch } from 'react-redux';
import { prepareReservationForCancelAction } from '../../features/Home/redux/cancelReservatonSlice';
import { AppDispatch } from '../../store/store';
import { formatDateToHoursString, formatDateToRegular } from '../../utils/utils';
import BookitIcon from '../foundation/BookitIcon/BookitIcon';

interface IProps {
  reservation: any;
  handleCancelReservation: () => Promise<void>;
  isDialogOpen: boolean;
  setIsDialogOpen: any;
}

const ActiveReservationsItem = ({ reservation, handleCancelReservation, isDialogOpen, setIsDialogOpen }: IProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const toggleDialog = () => {
    setIsDialogOpen((prevState) => !prevState);
  };

  const handleCancelReservationButtonClick = (reservationForCancel) => {
    toggleDialog();
    dispatch(prepareReservationForCancelAction(reservationForCancel));
  };

  const renderReservation = () => {
    const { resource } = reservation;
    return (
      <div style={styles.mainContainer}>
        <div style={styles.reservationDate}>
          {formatDateToRegular(reservation.start_date)}
          <BookitIcon
            type={'delete'}
            style={styles.deleteButton}
            onClick={() => {
              handleCancelReservationButtonClick(reservation);
            }}
          />
        </div>
        <div>
          {[
            { value: <img src={resource.image_url} alt='Booked field' style={styles.image} /> },
            { label: text.DIALOG_TEXT.SELECTED_FIELD, value: resource.name },
            { label: text.DIALOG_TEXT.SELECTED_FIELD_DETAILS, value: resource.description },
            { label: text.DIALOG_TEXT.SELECTED_TIME, value: formatDateToHoursString(reservation.start_date) },
          ].map((item, index) => (
            <p key={index} style={styles.data}>
              <span style={styles.dataLabel}>{item.label}</span>
              <span>{item.value}</span>
            </p>
          ))}
          <hr style={styles.divider} />
        </div>
      </div>
    );
  };

  const renderCancelReservationDialog = () => {
    return (
      <CancelReservationDialog
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
        }}
        onConfirm={handleCancelReservation}
      >
        <p style={styles.dialogText}>
          {`Termin ${formatDateToRegular(reservation.start_date)} u ${formatDateToHoursString(
            reservation.start_date,
          )} biće otkazan.`}
        </p>
      </CancelReservationDialog>
    );
  };

  return (
    <>
      {renderReservation()}
      {renderCancelReservationDialog()}
    </>
  );
};

export default ActiveReservationsItem;
