import React from 'react';
import SunriseInfoPage from '../../components/SunriseInfoPage/SunriseInfoPage';
import images from '../../images/constants';
import { text } from '../../constants';
import parentStyles from './SunriseReacreationalSportPage.style';
import { useIsMobile } from '../../../hooks/IsMobileHook';
import { Link } from 'react-router-dom';
import theme from '../../../themes/theme';

const SunriseLeaguesPage = () => {
  const isMobile = useIsMobile();

  return (
    <SunriseInfoPage
      topImageSource={images.LEAGUES2}
      leftImage1={images.SCHOOL1}
      leftImage2={images.FOOTBALL_FIELD_03}
      leftImage3={images.FOOTBALL_FIELD_04}
      rightImage={images.TABLES}
    >
      <div style={parentStyles(isMobile).wrapper}>
        <h1 style={parentStyles(isMobile).title}>{text.RECREATIONAL_SPORT.TITLE}</h1>
        <div style={parentStyles(isMobile).content}>
          <p>{text.RECREATIONAL_SPORT.DSC_01}</p>
          <p>{text.RECREATIONAL_SPORT.DSC_02}</p>
          <p>{text.RECREATIONAL_SPORT.DSC_03}</p>
          <p>
            {text.RECREATIONAL_SPORT.PRICE_INFO}{' '}
            <Link to='/pricing' style={{ color: theme.palette.primary.main }}>
              {text.RECREATIONAL_SPORT.LINK_TEXT}
            </Link>
          </p>
        </div>
      </div>
    </SunriseInfoPage>
  );
};

export default SunriseLeaguesPage;
