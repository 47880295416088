import React from 'react';
import SunriseInfoPage from '../../components/SunriseInfoPage/SunriseInfoPage';
import images from '../../images/constants';
import { text } from '../../constants';
import parentStyles from './SunriseTennisPage.style';
import { useIsMobile } from '../../../hooks/IsMobileHook';

const SunriseLeaguesPage = () => {
  const isMobile = useIsMobile();

  return (
    <SunriseInfoPage
      topImageSource={images.ABOUT_US_TENNIS}
      leftImage1={images.SCHOOL3}
      leftImage2={images.LEAGUES3}
      leftImage3={images.TENNIS_04}
      rightImage={images.TENNIS_05}
    >
      <div style={parentStyles(isMobile).wrapper}>
        <h1 style={parentStyles(isMobile).title}>{text.TENNIS_SCHOOL.TITLE}</h1>
        <div style={parentStyles(isMobile).content}>
          <h2>{text.TENNIS_SCHOOL.SC_TITLE}</h2>
          <p>{text.TENNIS_SCHOOL.SC_DSC_01}</p>
          <p>{text.TENNIS_SCHOOL.SC_DSC_02}</p>
          <p>{text.TENNIS_SCHOOL.SC_LIST_TITLE}</p>
          <ul>
            {text.TENNIS_SCHOOL.SC_LIST.map((item: any, index: number) => (
              <li>{item}</li>
            ))}
          </ul>
          <p>{text.TENNIS_SCHOOL.SC_DSC_03}</p>
          <h2>{text.TENNIS_SCHOOL.TC_TITLE}</h2>
          <p>{text.TENNIS_SCHOOL.TC_DSC_01}</p>
          <p>{text.TENNIS_SCHOOL.TC_DSC_02}</p>
          <p>{text.TENNIS_SCHOOL.TC_DSC_03}</p>
        </div>
      </div>
    </SunriseInfoPage>
  );
};

export default SunriseLeaguesPage;
