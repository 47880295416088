import React from 'react';
import theme from '../../../themes/theme';

export const getStyles = (isMobile?: boolean): Record<string, any> => {
  const textWrapperStyle: React.CSSProperties = {
    backgroundColor: theme.palette.secondary.main,
    zIndex: 1,
    margin: '-8rem 2rem 1rem 2rem',
    padding: '0 10vw 2vw 10vw',
  };
  const reservationButtonStyle: React.CSSProperties = {
    borderRadius: 0,
    padding: '0.5rem 1rem 0.5rem 1rem',
    fontFamily: theme.typography.h4.fontFamily,
    fontSize: '1.1rem',
    // @ts-ignore
    '&:active': {
      transform: 'scale(0.98)',
    },
  };
  return {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
    },

    topImageWrapper: {
      position: 'relative',
      height: '20rem',
      width: '100%,',
    },
    topImage: {
      objectFit: 'cover',
      width: '100%',
      height: '100%',
      opacity: 0.6,
    },
    textWrapper: {
      ...textWrapperStyle,
    },

    mobileTextWrapper: {
      ...textWrapperStyle,
      padding: '0 2vw',
      margin: '-10rem 1rem 1rem 1rem',
    },

    mobileImagesWrapper: {
      margin: '0 1rem 0.5rem 1rem',
      display: 'flex',
      flexDirection: 'column',
    },

    mobileImage: {
      width: '100%',
      objectFit: 'cover',
      marginBottom: '0.7rem',
      height: '14rem',
    },

    bottomImagesWrapper: {
      display: 'flex',
      height: '27rem',
      margin: '0 2rem 2rem 2rem',
    },
    leftSide: {
      display: 'flex',
      flexDirection: 'column',
      width: '50%',
      height: '100%',
      gap: '1rem',
      boxSizing: 'border-box',
    },
    topLeftImages: {
      display: 'flex',
      height: '50%',
      width: '100%',
      gap: '1rem',
    },
    topLeftImageWrapper: {
      height: '100%',
      width: '50%',
    },
    topLeftImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    bottomLeftImage: {
      width: '100%',
      height: 'calc(50% - 1rem)',
      objectFit: 'cover',
      boxSizing: 'border-box',
    },
    rightSide: {
      width: '50%',
      height: '100%',
      marginLeft: '1rem',
    },
    rightImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },

    reservationButton: {
      ...reservationButtonStyle,
      position: 'absolute',
      top: '2rem',
      right: '2rem',
      zIndex: 1,
    },

    parentWrapper: {
      textAlign: 'center',
      color: theme.palette.primary.contrastText,
    },
    parentTitle: {
      fontSize: isMobile ? theme.typography.h2.fontSize : theme.typography.h1.fontSize,
      fontFamily: theme.typography.h1.fontFamily,
    },
    parentContent: {
      textAlign: 'left',
      color: theme.palette.primary.contrastText,
      fontSize: theme.typography.body1.fontSize,
      fontFamily: theme.typography.body1.fontFamily,
      lineHeight: 1.5,
    },
  };
};

export default getStyles;
