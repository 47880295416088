import React from 'react';
import SunriseInfoPage from '../../components/SunriseInfoPage/SunriseInfoPage';
import images from '../../images/constants';
import { text } from '../../constants';
import parentStyles from './SunriseAboutUsPage.style';
import { useIsMobile } from '../../../hooks/IsMobileHook';

const SunriseAboutUsPage = () => {
  const isMobile = useIsMobile();
  return (
    <SunriseInfoPage
      topImageSource={images.KIDS_CELEBRATION}
      leftImage1={images.TEAM_04}
      leftImage2={images.SCHOOL3}
      leftImage3={images.FOOTBALL_FIELD_02}
      rightImage={images.FOOTBALL_GAME_01}
    >
      <div style={parentStyles(isMobile).wrapper}>
        <h1 style={parentStyles(isMobile).title}>{text.ABOUT_US.TITLE}</h1>
        <div style={parentStyles(isMobile).content}>
          <p>{text.ABOUT_US.LONG_DESC_01}</p>
          <p>{text.ABOUT_US.LIST_01_TITLE}</p>
          <ul>
            {text.ABOUT_US.LIST_01.map((item: any, index: number) => (
              <li>{item}</li>
            ))}
            <ul>
              {text.ABOUT_US.LIST_02.map((item: any, index: number) => (
                <li>{item}</li>
              ))}
            </ul>
            {text.ABOUT_US.LIST_03.map((item: any, index: number) => (
              <li>{item}</li>
            ))}
          </ul>

          <p>{text.ABOUT_US.IN_FUTURE} </p>
          <p>{text.ABOUT_US.FINISH_LINE} </p>
        </div>
      </div>
    </SunriseInfoPage>
  );
};

export default SunriseAboutUsPage;
