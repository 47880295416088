import theme from '../../../../../themes/theme';

const styles: Record<string, React.CSSProperties> = {
  dialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.palette.secondary.main,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primary.contrastText,
    padding: '1rem',
    width: '25rem',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.secondary.dark,
    padding: '1rem',
  },

  button: {
    width: '7rem',
    margin: '0 1rem 0 1rem',
  },

  title: {
    background: theme.palette.secondary.dark,
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.h1.fontFamily,
    fontSize: theme.typography.h2.fontSize,
    textAlign: 'center',
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  filedWrapper: { display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' },

  noteWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    color: theme.palette.primary.contrastText,
  },

  noteTitle: {
    padding: '1rem 0 0.5rem',
    margin: 0,
    fontSize: '0.9rem',
  },

  note: {
    width: '100%',
    // @ts-ignore
    '& .MuiOutlinedInput-root': {
      color: theme.palette.primary.contrastText,
      '& fieldset': {
        borderColor: theme.palette.secondary.light,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.contrastText,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
        borderWidth: '1px',
      },
      '& textarea': {
        minHeight: '4.5rem',
        maxHeight: '4.5rem',
      },
      '& input': {
        color: theme.palette.primary.contrastText,
      },
    },
    '& .MuiInputBase-input::placeholder': {
      color: theme.palette.secondary.light,
    },
  },
};

export default styles;
