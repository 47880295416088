import theme from '../../themes/theme';

const styles: Record<string, React.CSSProperties> = {
  buttonContainer: {
    backgroundColor: theme.palette.secondary.dark,
  },

  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },

  image: {
    width: '7rem',
    height: '7rem',
    margin: '1.5rem 0 0 0',
  },

  button: {
    width: '7rem',
    margin: '0 1rem 1rem 1rem',
  },

  title: {
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.h1.fontFamily,
    fontSize: theme.typography.h2.fontSize,
    fontWeight: theme.typography.h4.fontWeight,
    textAlign: 'center',
  },

  text: {
    textAlign: 'center',
    fontSize: theme.typography.body1.fontSize,
    fontFamily: theme.typography.body1.fontFamily,
    color: theme.palette.primary.contrastText,
    margin: '0.5rem 0 0 0',
  },
};

export default styles;
