import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiService from '../../../services/api-service';
import { IProfileDTO } from '../../../services/serviceTypes';
import { deleteAccessToken, setAccessToken } from '../../../utils/utils';
import { getCompanyServicesThunk } from '../../Home/redux/companyServicesSlice';
import { clearReservationsAction } from '../../Home/redux/reservationsSlice';
import { clearProfileDataAction } from '../../Home/redux/profileSlice';
import { clearReservationForCancelAction } from '../../Home/redux/cancelReservatonSlice';
import { clearOngoingReservationAction } from '../../Home/redux/ongoingReservationSlice';
import { ILoading } from '../../../types';

interface IPayload {
  email: string;
  password: string;
}

const initialState: { loading: ILoading; data: any | null; error: string | null } = {
  loading: {
    login: false,
    logout: false,
    register: false,
    changePassword: false,
    forgotPassword: false,
  },
  data: null,
  error: null,
};

const authSlice = createSlice({
  name: 'AUTH',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginThunk.pending, (state) => {
        state.loading.login = true;
        state.error = null;
      })
      .addCase(loginThunk.fulfilled, (state, action) => {
        state.loading.login = false;
        state.data = action.payload;
      })
      .addCase(loginThunk.rejected, (state, action) => {
        state.loading.login = false;
        // @ts-ignore
        state.error = action?.payload || 'eer'; // Error message passed via rejectWithValue
      })

      .addCase(logoutThunk.pending, (state) => {
        state.loading.logout = true;
        state.error = null;
      })
      .addCase(logoutThunk.fulfilled, (state, action) => {
        state.loading.logout = false;
        state.data = action.payload;
      })
      .addCase(logoutThunk.rejected, (state, action) => {
        state.loading.logout = false;
        // @ts-ignore
        state.error = action?.payload || 'eer'; // Error message passed via rejectWithValue
      })

      .addCase(registerThunk.pending, (state) => {
        state.loading.register = true;
        state.error = null;
      })
      .addCase(registerThunk.fulfilled, (state, action) => {
        state.loading.register = false;
        state.data = action.payload;
      })
      .addCase(registerThunk.rejected, (state, action) => {
        state.loading.register = false;
        // @ts-ignore
        state.error = action?.payload || 'eer'; // Error message passed via rejectWithValue
      })

      .addCase(changePasswordThunk.pending, (state) => {
        state.loading.changePassword = true;
        state.error = null;
      })
      .addCase(changePasswordThunk.fulfilled, (state, action) => {
        state.loading.changePassword = false;
        state.data = action.payload;
      })
      .addCase(changePasswordThunk.rejected, (state, action) => {
        state.loading.changePassword = false;
        // @ts-ignore
        state.error = action?.payload || 'eer'; // Error message passed via rejectWithValue
      })

      .addCase(forgotPasswordThunk.pending, (state) => {
        state.loading.forgotPassword = true;
        state.error = null;
      })
      .addCase(forgotPasswordThunk.fulfilled, (state, action) => {
        state.loading.forgotPassword = false;
        state.data = action.payload;
      })
      .addCase(forgotPasswordThunk.rejected, (state, action) => {
        state.loading.forgotPassword = false;
        // @ts-ignore
        state.error = action?.payload || 'eer'; // Error message passed via rejectWithValue
      });
  },
});
export default authSlice.reducer;

// Async thunks
export const loginThunk = createAsyncThunk('LOGIN', async (payload: IPayload, { dispatch, rejectWithValue }) => {
  try {
    const token = await ApiService?.getAuthService()?.loginEmailAndPassword(payload.email, payload.password);
    setAccessToken(token);
  } catch (error) {
    return rejectWithValue({
      code: error.code,
      message: error.message,
      status: error.status,
    });
  }
});

export const logoutThunk = createAsyncThunk('LOGOUT', async (payload, { dispatch, rejectWithValue }) => {
  try {
    await ApiService?.getAuthService()?.logout();
    deleteAccessToken();
    dispatch(clearReservationsAction());
    dispatch(clearReservationForCancelAction());
    dispatch(clearProfileDataAction());
    dispatch(clearOngoingReservationAction());
    dispatch(getCompanyServicesThunk());
  } catch (error) {
    return rejectWithValue({
      code: error.code,
      message: error.message,
      status: error.status,
    });
  }
});

export const registerThunk = createAsyncThunk('REGISTER', async (payload: IProfileDTO, { rejectWithValue }) => {
  try {
    const token = await ApiService?.getAuthService()?.registerEmailAndPassword(payload);
    localStorage.setItem('ACCESS_TOKEN', token);
  } catch (error) {
    return rejectWithValue({
      code: error.code,
      message: error.message,
      status: error.status,
    });
  }
});

export const changePasswordThunk = createAsyncThunk('CHANGE_PASSWORD', async (payload: string, { rejectWithValue }) => {
  try {
    await ApiService?.getAuthService()?.changePassword(payload);
  } catch (error) {
    return rejectWithValue({
      code: error.code,
      message: error.message,
      status: error.status,
    });
  }
});

export const forgotPasswordThunk = createAsyncThunk('FORGOT_PASSWORD', async (payload: string, { rejectWithValue }) => {
  try {
    await ApiService?.getAuthService()?.forgotPassword(payload);
  } catch (error) {
    return rejectWithValue({
      code: error.code,
      message: error.message,
      status: error.status,
    });
  }
});

export const resendAccountConfirmationEmailThunk = createAsyncThunk(
  'RESENT_REGISTER_EMAIL',
  async (payload: string, { rejectWithValue }) => {
    try {
      await ApiService?.getAuthService()?.resendAccountConfirmationEmail(payload);
    } catch (error) {
      return rejectWithValue({
        code: error.code,
        message: error.message,
        status: error.status,
      });
    }
  },
);
