import welcome_field from './welcome_field.jpeg';
import about_us_tennis from './about_us_tennis.JPG';
import leagues_football from './leagues1.JPG';
import leagues_field from './leagues2.JPG';
import leagues_tennis from './leagues3.JPG';
import football_school from './football_school.jpg';
import tennis_school from './tennis_school.JPG';
import football_school2 from './football_school2.JPG';
import football_ball from './football_ball.JPG';
import basketball_ball from './basketball_ball.JPG';
import tennis_ball from './tennis_ball.JPG';
import volleyball_ball from './volleyball_ball.JPG';
import kids from './deca.jpg';
import football_outdoor_01 from './teren_fudbal.JPG'
import football_outdoor_02 from './teren_fudbal02.jpg'
import football_outdoor_03 from './teren_fudbal03.jpg'
import football_game01 from './utakmica01.jpg';
import football_field_01 from './veliki_teren.JPG'
import drone from './drone.JPG'
import drone_01 from './drone_01.jpg'
import drone_02 from './drone_02.jpg'
import football_01 from './football_01.jpg'
import football_02 from './football_02.jpg'
import football_03 from './football_03.jpg'
import football_04 from './football_04.jpg'
import football_05 from './football_05.jpg'
import football_06 from './football_06.jpg'
import football_07 from './football_07.jpg'
import football_08 from './football_08.jpg'
import football_09 from './football_09.jpg'
import football_10 from './football_10.jpg'
import football_11 from './football_11.jpg'
import football_12 from './football_12.jpg'
import football_13 from './football_13.jpg'
import trophies from './trophies.jpg'
import football_14 from './football_14.jpg'
import football_15 from './football_15.jpg'
import football_16 from './football_16.jpg'
import football_17 from './football_17.jpg'
import football_18 from './football_18.jpg'
import football_19 from './football_19.jpg'
import football_20 from './football_20.jpg'
import football_21 from './football_21.jpg'
import football_22 from './football_22.jpg'
import tennis_01 from './tennis_01.jpg'
import tennis_02 from './tennis_02.jpg'
import tennis_03 from './tennis_03.jpg'
import tennis_04 from './tennis_04.jpg'
import tennis_05 from './tennis_05.jpg'
import tables from './orange.JPG'
import team_01 from './team_01.jpg'
import team_02 from './team_02.jpg'
import team_03 from './team_03.jpg'
import team_04 from './team_04.jpg'
import team_05 from './team_05.jpg'
import team_06 from './team_06.jpg'
import team_07 from './team_07.jpg'
import team_08 from './team_08.jpg'
import team_09 from './team_09.jpg'
import team_10 from './team_10.jpg'
import team_11 from './team_11.jpg'

const images = {
  WELCOME_FIELD: welcome_field,
  ABOUT_US_TENNIS: about_us_tennis,
  LEAGUES1: leagues_football,
  LEAGUES2: leagues_field,
  LEAGUES3: leagues_tennis,
  SCHOOL1: football_school,
  SCHOOL2: football_school2,
  SCHOOL3: tennis_school,
  BALL1: football_ball,
  BALL2: basketball_ball,
  BALL3: tennis_ball,
  BALL4: volleyball_ball,
  KIDS_CELEBRATION: kids,
  FOOTBALL_FIELD_01: football_outdoor_01,
  FOOTBALL_FIELD_02: football_outdoor_02,
  FOOTBALL_FIELD_03: football_outdoor_03,
  FOOTBALL_FIELD_04: football_field_01,
  FOOTBALL_GAME_01: football_game01,
  DRONE_SHOT_01: drone,
  DRONE_01: drone_01,
  DRONE_02: drone_02,
  TROPHIES: trophies,
  FOOTBALL_01: football_01,
  FOOTBALL_02: football_02,
  FOOTBALL_03: football_03,
  FOOTBALL_04: football_04,
  FOOTBALL_05: football_05,
  FOOTBALL_06: football_06,
  FOOTBALL_07: football_07,
  FOOTBALL_08: football_08,
  FOOTBALL_09: football_09,
  FOOTBALL_10: football_10,
  FOOTBALL_11: football_11,
  FOOTBALL_12: football_12,
  FOOTBALL_13: football_13,
  FOOTBALL_14: football_14,
  FOOTBALL_15: football_15,
  FOOTBALL_16: football_16,
  FOOTBALL_17: football_17,
  FOOTBALL_18: football_18,
  FOOTBALL_19: football_19,
  FOOTBALL_20: football_20,
  FOOTBALL_21: football_21,
  FOOTBALL_22: football_22,
  TENNIS_01: tennis_01,
  TENNIS_02: tennis_02,
  TENNIS_03: tennis_03,
  TENNIS_04: tennis_04,
  TENNIS_05: tennis_05,
  TABLES: tables,
  TEAM_01: team_01,
  TEAM_02: team_02,
  TEAM_03: team_03,
  TEAM_04: team_04,
  TEAM_05: team_05,
  TEAM_06: team_06,
  TEAM_07: team_07,
  TEAM_08: team_08,
  TEAM_09: team_09,
  TEAM_10: team_10,
  TEAM_11: team_11,
};

export default images;
