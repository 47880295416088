import theme from '../../../themes/theme';

interface Style {
  titleStyle: React.CSSProperties;
  textStyle: React.CSSProperties;
}

interface Styles {
  small: Style;
  medium: Style;
  large: Style;
}

const styles: Styles = {
  small: {
    titleStyle: {
      color: theme.palette.primary.main,
      fontSize: theme.typography.h4.fontSize,
      letterSpacing: '0.1rem',
      userSelect: 'none',
      textDecoration: 'underline',
      textUnderlineOffset: '0.3rem',
      textDecorationThickness: '0.05rem',
      fontFamily: 'Engravers',
      marginTop: '0',
      marginBottom: '0',
    },
    textStyle: {
      color: theme.palette.primary.contrastText,
      fontFamily: theme.typography.fontFamily,
      fontSize: '0.3rem',
      letterSpacing: '0.23rem',
      textTransform: 'lowercase',
      userSelect: 'none',
      marginTop: '0.1rem',
    },
  },

  medium: {
    titleStyle: {
      color: theme.palette.primary.main,
      fontSize: theme.typography.h2.fontSize,
      letterSpacing: '0.1rem',
      userSelect: 'none',
      textDecoration: 'underline',
      textUnderlineOffset: '0.3rem',
      textDecorationThickness: '0.05rem',
      fontFamily: 'Engravers',
      marginTop: '0',
      marginBottom: '0',
    },
    textStyle: {
      color: theme.palette.primary.contrastText,
      fontFamily: theme.typography.fontFamily,
      fontSize: '0.5rem',
      letterSpacing: '0.3rem',
      textTransform: 'lowercase',
      userSelect: 'none',
      marginTop: '0.1rem',
    },
  },

  large: {
    titleStyle: {
      color: theme.palette.primary.main,
      fontSize: theme.typography.h1.fontSize,
      letterSpacing: '0.1rem',
      userSelect: 'none',
      textDecoration: 'underline',
      textUnderlineOffset: '0.3rem',
      textDecorationThickness: '0.05rem',
      fontFamily: 'Engravers',
      marginTop: '0',
      marginBottom: '0',
    },
    textStyle: {
      color: theme.palette.primary.contrastText,
      fontFamily: theme.typography.fontFamily,
      fontSize: '0.7rem',
      letterSpacing: '0.42rem',
      textTransform: 'lowercase',
      userSelect: 'none',
      marginTop: '0.1rem',
      textAlign: 'center',
    },
  },
};

export default styles;
