import {
  IBackOfficeHomeService,
  IBoAppointmentsItem,
  IBoScheduleAppointments,
  IBoUsersItem,
} from '../../../../services/serviceTypes';
import ApiClient from '../../../../services/api-client';
import apiUtil from '../../../../services/api-util';

export default class ApiBackOfficeHomeServices implements IBackOfficeHomeService {
  public async getBoAppointments(from: string, to: string): Promise<IBoAppointmentsItem[]> {
    const config = apiUtil.parseQueryParams({ from: from, to: to });

    const data = await ApiClient.get<IBoAppointmentsItem[]>(`/appointments`, config, true);
    return data;
  }

  public async getBoScheduledAppointments(date: string): Promise<IBoScheduleAppointments> {
    const config = apiUtil.parseQueryParams({ date: date });

    const data = await ApiClient.get<IBoScheduleAppointments>(`/schedule`, config, true);
    return data;
  }

  public async newBoAppointment(appointment: any) {
    await ApiClient.post(`/appointments`, appointment, true);
  }

  public async cancelBoAppointment(appointmentId: string) {
    await ApiClient.delete(`/appointments/${appointmentId}`, true);
  }

  public async getBoAllUsers(): Promise<IBoUsersItem[]> {
    const data = await ApiClient.get<IBoUsersItem[]>(`/retail-users`, {}, true);
    return data;
  }

  public async blockBoUser(userId): Promise<void> {
    await ApiClient.post<{ jwt: string }>(`/retail-users/${userId}/blocks`, {}, true);
  }

  public async unblockBoUser(userId): Promise<void> {
    await ApiClient.delete<{ jwt: string }>(`/retail-users/${userId}/blocks`, true);
  }
}
