import React, { useEffect, useRef } from 'react';
import images from '../../images/constants';
import FooterAdvanced from '../../../components/Footer/FooterAdvanced';
import BookitButton from '../../../components/foundation/BookitButton/BookitButton';
import { text } from '../../constants';
import BookitMap from '../../../components/BookitMap/BookitMap';
import { clientInfo } from '../../../constants/constants';
import BookitIcon from '../../../components/foundation/BookitIcon/BookitIcon';
import { TIcon } from '../../../types';
import { getStyles } from './SunriseHomePageMobile.style';
import { useIsMobile } from '../../../hooks/IsMobileHook';
import SunriseHeader from '../../components/SunriseHeader/SunriseHeader';
import { useLocation, useNavigate } from 'react-router';

const SunriseHomePageMobile = () => {
  const isMobile = useIsMobile();
  const styles = getStyles(isMobile);
  const { state } = useLocation();

  const navigate = useNavigate();
  const contactRef = useRef(null);

  useEffect(() => {
    if (state?.scrollTo === 'contact') {
      navigate('.', { state: null });
      contactRef?.current?.scrollIntoView();
    }
  }, [state, navigate]);

  const renderMoreDetails = (textColor: string, path: string) => (
    <div style={styles.seeMoreButtonWrapper}>
      <BookitButton
        type='routerLink'
        variant='text'
        routerPath={path}
        label={text.SEE_MORE}
        style={{ ...styles.seeMoreButton, color: textColor }}
        disableRipple
      />
    </div>
  );

  const renderWelcomeBlock = () => {
    return (
      <>
        <div style={styles.welcomeWrapper}>
          <div style={{ zIndex: 1 }}>
            <SunriseHeader isTransparent={true} />
          </div>
          <img src={images.FOOTBALL_FIELD_02} alt='Aerial view of the sport center "Sunrise"' style={styles.welcomeImage} />
          <div style={styles.welcomeTextWrapper}>
            <p style={styles.welcomeTitle}>{text.WELCOME.TITLE}</p>
            <p>{text.WELCOME.SUBTITLE}</p>
          </div>
        </div>
      </>
    );
  };

  const renderAboutUs = () => {
    return (
      <div style={styles.aboutUsWrapper}>
        <div style={styles.aboutUsTextWrapper}>
          <p style={styles.aboutUsTitle}>{text.ABOUT_US.TITLE}</p>
          <p style={styles.aboutUsSubtitle}>{text.ABOUT_US.SHORT_DSC}</p>
          {renderMoreDetails('white', 'about-us')}
        </div>
        <div style={styles.aboutUsImageWrapper}>
          <img src={images.ABOUT_US_TENNIS} alt='Tennis ball on a clay court' style={styles.aboutUsImage} />
        </div>
      </div>
    );
  };

  const renderLeagues = () => {
    return (
      <div style={styles.leaguesWrapper}>
        <p style={styles.leaguesTitle}>{text.LEAGUES.TITLE}</p>

        <div style={styles.leaguesContentWrapper}>
          <div style={styles.leaguesTextWrapper}>
            <p style={styles.leaguesContentsTitle}>{text.LEAGUES.LEAGUE_01_TITLE}</p>
            <p style={styles.leaguesContentsSubtitle}>{text.LEAGUES.LEAGUE_01_SHORT_DSC}</p>
            {renderMoreDetails('black', 'leagues')}
          </div>
          <img src={images.LEAGUES1} alt='Four boys playing a football game' style={styles.leaguesImages} />
          <div style={styles.leaguesTextWrapper}>
            <p style={styles.leaguesContentsTitle}>{text.LEAGUES.LEAGUE_02_TITLE}</p>
            <p style={styles.leaguesContentsSubtitle}>{text.LEAGUES.LEAGUE_02_SHORT_DSC}</p>
            {renderMoreDetails('black', 'leagues')}
          </div>
        </div>
        <div style={{ ...styles.leaguesContentWrapper, marginBottom: '5rem' }}>
          <img src={images.LEAGUES2} alt='Football grass field lines' style={styles.leaguesImages} />
          <div style={styles.leaguesTextWrapper}>
            <p style={styles.leaguesContentsTitle}>{text.LEAGUES.LEAGUE_03_TITLE}</p>
            <p style={styles.leaguesContentsSubtitle}>{text.LEAGUES.LEAGUE_03_SHORT_DSC}</p>
            {renderMoreDetails('black', 'leagues')}
          </div>
          <img src={images.LEAGUES3} alt='Tennis racket with a ball on it' style={styles.leaguesImages} />
        </div>
      </div>
    );
  };

  const renderCallUsButton = () => {
    return (
      <BookitButton
        variant='outlined'
        label='Pozovite'
        type='basic'
        style={styles.schoolsButton}
        onClick={() => (window.location.href = `tel: ${text.PHONE_NUMBER}`)}
      >
        <BookitIcon type='longRightArrow' style={styles.schoolsButtonIcon} />
      </BookitButton>
    );
  };

  const renderSchools = () => {
    return (
      <div style={styles.schoolsWrapper}>
        <div style={styles.footbalSchoolContentWrapper}>
          <p style={styles.schoolsTitle}>{text.FOOTBALL_SCHOOL.TITLE}</p>
          <p style={styles.schoolsDescription}>{text.FOOTBALL_SCHOOL.SC_SHORT_DSC}</p>
          {renderMoreDetails('white', 'football')}
          {renderCallUsButton()}
        </div>
        <div style={styles.schoolsImageWrapper}>
          <img src={images.SCHOOL1} alt='Football team of boys wearing medals around their necks celebrating with two coaches with their hands in the air' style={styles.schoolsImage} />
        </div>
        <div style={styles.tennisSchoolContentWrapper}>
          <p style={styles.schoolsTitle}>{text.TENNIS_SCHOOL.TITLE}</p>
          <p style={styles.schoolsDescription}>{text.TENNIS_SCHOOL.SHORT_DSC}</p>
          {renderMoreDetails('white', 'tennis')}

          {renderCallUsButton()}
        </div>
        <div style={styles.schoolsImageWrapper}>
          <img src={images.SCHOOL3} alt='Girl playing tennis on a clay court' style={styles.schoolsImage} />
        </div>
      </div>
    );
  };

  const renderRecreationalSports = () => {
    return (
      <div style={styles.recreationalSportsWrapper}>
        <div style={styles.recreationalSportsContent}>
          <p style={styles.recreationalSportsTitle}>{text.RECREATIONAL_SPORT.TITLE}</p>
          <p style={styles.recreationalSportsSubtitle}>{text.RECREATIONAL_SPORT.SHORT_DSC}</p>
          {renderMoreDetails('black', 'recreational-sport')}
          <BookitButton
            variant='outlined'
            label={text.PRICING_BUTTON}
            type='routerLink'
            routerPath='/pricing'
            style={styles.pricingButton}
          >
            <BookitIcon type='longRightArrow' style={styles.pricingButtonIcon} />
          </BookitButton>
        </div>
        <div style={styles.recreationalSportsImagesWrapper}>
          <img src={images.BALL1} alt='A football ball' style={styles.recreationalSportsImages} />
          <img src={images.BALL2} alt='A basketball ball' style={styles.recreationalSportsImages} />
          <img src={images.BALL3} alt='A tennis ball' style={styles.recreationalSportsImages} />
          <img src={images.BALL4} alt='A volleyball ball' style={styles.recreationalSportsImages} />
        </div>
      </div>
    );
  };

  const contactDetails: { type: TIcon; title: string; subtitle: string; href: string }[] = [
    {
      type: 'mapPin',
      title: `${text.CONTACT_CARD.ADDRESS_TITLE}`,
      subtitle: `${text.CONTACT_CARD.ADDRESS_SUBTITLE}`,
      href: `https://www.google.com/maps/place/%D0%A1%D0%B0%D0%BD+%D0%A0%D0%B0%D1%98%D1%81/@44.7561987,19.6721605,17z/data=!3m1!4b1!4m6!3m5!1s0x475bca54c631499d:0x1aecbdbcf1bdbf1e!8m2!3d44.7561949!4d19.6747354!16s%2Fg%2F11bbrkbtsh?entry=ttu&g_ep=EgoyMDI0MTExMy4xIKXMDSoASAFQAw%3D%3D${encodeURIComponent(
        text.CONTACT_CARD.ADDRESS_SUBTITLE,
      )}`,
    },
    {
      type: 'phone',
      title: `${text.CONTACT_CARD.PHONE_TITLE}`,
      subtitle: `${text.CONTACT_CARD.PHONE_SUBTITLE}`,
      href: `tel:${text.CONTACT_CARD.PHONE_SUBTITLE}`,
    },
    {
      type: 'email',
      title: `${text.CONTACT_CARD.EMAIL_TITLE}`,
      subtitle: `${text.CONTACT_CARD.EMAIL_SUBTITLE}`,
      href: `mailto:${text.CONTACT_CARD.EMAIL_SUBTITLE}`,
    },
  ];

  const renderContactDetails = () => {
    return (
      <div ref={contactRef} style={styles.contactDetailsWrapper}>
        <p style={styles.contactDetailsTitle}>{text.CONTACT_DETAILS_TITLE}</p>
        <div style={styles.contactCard}>
          <div style={styles.contactCardWrapper}>
            {contactDetails.map(({ type, title, subtitle, href }) => (
              <a href={href} target='_blank' rel=' noopener noreferrer' key={title} style={styles.contactCardInfoContainer}>
                <div style={styles.contactCardIconAndTitleWrapper}>
                  <BookitIcon type={type} style={styles.contactCardIcons} />
                  <p style={styles.contactCardInfoContainerTitle}>{title}</p>
                </div>
                <p style={styles.contactCardInfoContainersubtitle}>{subtitle}</p>
              </a>
            ))}
          </div>
        </div>
        <div style={styles.mapWrapper}>
          <BookitMap
            position={{ lat: clientInfo.LOCATION.LATITUDE, lng: clientInfo.LOCATION.LONGITUDE }}
            popup={text.MAP_MARKER_POPUP}
          />
        </div>
        <img src={images.WELCOME_FIELD} alt='Aerial view of the sport center "Sunrise"' style={styles.contactDetailsImage} />
      </div>
    );
  };

  const renderFooter = () => <FooterAdvanced />;

  return (
    <div style={styles.wrapper}>
      {renderWelcomeBlock()}
      {renderAboutUs()}
      {renderLeagues()}
      {renderSchools()}
      {renderRecreationalSports()}
      {renderContactDetails()}
      {renderFooter()}
    </div>
  );
};

export default SunriseHomePageMobile;
