import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { AppDispatch, store } from './store/store';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import ErrorPage from './features/App/pages/ErrorPage/ErrorPage';
import ForgotPasswordPage from './features/Auth/pages/ForgotPasswordPage/ForgotPasswordPage';
import LoginPage from './features/Auth/pages/LoginPage/LoginPage';
import SignupPage from './features/Auth/pages/SignupPage/SignupPage';
import BackOfficeHomePage from './features/BackOffice/BackOfficeHome/pages/BackOfficeHomePage/BackOfficeHomePage';
import BackOfficeLogin from './features/BackOffice/BackOfficeAuth/pages/BackOfficeForgotPasswordPage/BackOfficeLoginPage/BackOfficeLogin';
import HomePage from './features/Home/pages/HomePage/HomePage';
import useNetwork from './hooks/NetworkHooks';

import styles from './index.style';
import theme from './themes/theme';
import PricingPage from './features/AboutUs/pages/PricingPage/PricingPage';
import SunriseHomePage from './customerComponents/pages/SunriseHomePage/SunriseHomePage';
import BookitSnackbar from './components/foundation/BookitSnackbar/BookitSnackbar';
import { IStore } from './types';
import { clearErrorAction } from './features/App/redux/errorSlice';
import { useEffect } from 'react';
import SunriseHomePageMobile from './customerComponents/pages/SunriseHomePage/SunriseHomePageMobile';
import { useIsMobile } from './hooks/IsMobileHook';
import SunriseAboutUsPage from './customerComponents/pages/SunriseAboutUsPage/SunriseAboutUsPage';
import SunriseLeaguesPage from './customerComponents/pages/SunriseLeaguesPage/SunriseLeaguesPage';
import SunriseFootballPage from './customerComponents/pages/SunriseFootballPage/SunriseFootballPage';
import SunriseTennisPage from './customerComponents/pages/SunriseTennisPage/SunriseTennisPage';
import SunriseReacreationalSportPage from './customerComponents/pages/SunriseReacreationalSportPage/SunriseReacreationalSportPage';
import SunriseUnderConstructionPage from './customerComponents/pages/SunriseServicePages/SunriseUnderConstructionPage';
import SunriseNotFoundPage from './customerComponents/pages/SunriseServicePages/SunriseNotFoundPage';
import BackOfficeForgotPassword from './features/BackOffice/BackOfficeAuth/pages/BackOfficeForgotPasswordPage/BackOfficeForgotPassword';

const App = () => {
  const [setNetworkStatus] = React.useState('online');
  const [errorSnackbarOpen, setErrorSnackbarOpen] = React.useState(false);
  const isMobile = useIsMobile();

  const router = createBrowserRouter([
    {
      path: '/',
      element: isMobile ? <SunriseHomePageMobile /> : <SunriseHomePage />,
      errorElement: <ErrorPage />,
    },
    {
      path: '/reservations',
      element: <HomePage />,
    },
    {
      path: 'signup',
      element: <SignupPage />,
    },
    {
      path: 'about-us',
      element: <SunriseAboutUsPage />,
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'forgot-password',
      element: <ForgotPasswordPage />,
    },
    {
      path: 'login_bo',
      element: <BackOfficeLogin />,
    },
    {
      path: 'backoffice',
      element: <BackOfficeHomePage />,
    },
    {
      path: 'forgot-password-bo',
      element: <BackOfficeForgotPassword />,
    },
    {
      path: 'pricing',
      element: <PricingPage />,
    },
    {
      path: 'leagues',
      element: <SunriseLeaguesPage />,
    },
    {
      path: 'football',
      element: <SunriseFootballPage />,
    },
    {
      path: 'tennis',
      element: <SunriseTennisPage />,
    },
    {
      path: 'recreational-sport',
      element: <SunriseReacreationalSportPage />,
    },
    {
      path: 'under-construction',
      element: <SunriseUnderConstructionPage />,
    },
    {
      path: 'not-found',
      element: <SunriseNotFoundPage />,
    },
  ]);

  const Content = () => {
    const dispatch = useDispatch<AppDispatch>();
    const error = useSelector((state: IStore) => state.error);

    const handleCloseSnackbar = () => {
      setErrorSnackbarOpen(false);
      dispatch(clearErrorAction());
    };

    useEffect(() => {
      if (error.message) {
        setErrorSnackbarOpen(true);
      }
    }, [error]);

    useNetwork(setNetworkStatus, dispatch);
    return (
      <ThemeProvider theme={theme}>
        <React.StrictMode>
          <RouterProvider router={router} />
          <BookitSnackbar open={errorSnackbarOpen} type={'error'} onClose={handleCloseSnackbar} text={error.message} />
        </React.StrictMode>
      </ThemeProvider>
    );
  };

  return (
    <div style={styles.root}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_APP_ID}>
        <Provider store={store}>
          <Content />
        </Provider>
      </GoogleOAuthProvider>
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
