import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './styles.css';

const localizer = momentLocalizer(moment);

// Custom event display to include resource name
const CustomEvent = ({ event }) => {
  return (
    <div>
      {event.title && <strong>{event.title}</strong>}
      <br />
      {event.resourceTitle && <span>{event.resourceTitle}</span>}
      <span>{event.userPhoneNumber}</span>
      <br />
      <span>{event.note}</span>
    </div>
  );
};

const CustomHeader = ({ resource }) => {
  return (
    <div style={{ textAlign: 'center', padding: '10px' }}>
      <strong>{resource.resourceTitle}</strong>
    </div>
  );
};

const CustomTimeGutterHeader = () => {
  return <div style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}>Vreme</div>;
};

const CustomDayCell = ({ value }) => {
  return <div style={{ height: '100%' }} />;
};

const ScheduleAppointmentsCalendar = ({ appointments, onDateChange, onSelectSlotClick, onEventSlotClick }) => {
  const { containers } = appointments;
  const startWorkingHour = new Date(new Date().setHours(9, 0, 0));
  const endWorkingHour = new Date(new Date().setHours(23, 59, 0));

  const resources = useMemo(() => {
    return containers.map((item) => ({
      resourceId: item.resource.id,
      resourceTitle: item.resource.name,
    }));
  }, [containers]);

  const events = useMemo(() => {
    const customAppointments = [];
    containers.forEach((resource) => {
      if (resource?.appointments.length === 0) {
        return;
      }
      resource?.appointments?.forEach((appointment) => {
        console.log(appointment);
        const customEvent = {
          resourceId: resource.resource.id,
          resourceName: resource.resource.name,
          appointmentId: appointment.id,
          userId: appointment.user?.id,
          userName: appointment.user?.first_name ? `${appointment.user?.first_name} ${appointment.user?.last_name}` : null,
          userPhoneNumber: appointment.user?.phone_number_international_format,
          title: appointment.user ? `${appointment.user?.first_name} ${appointment.user?.last_name}` : appointment.note,
          start: new Date(appointment.start_date),
          end: new Date(appointment.end_date),
          note: appointment.user ? appointment.note : null,
        };
        customAppointments.push(customEvent);
      });
    });
    return customAppointments;
  }, [containers]);

  const handleSelectSlot = (slotInfo) => {
    const selectedResource = containers.find((container) => container.resource.id === slotInfo.resourceId);
    const additionalData = selectedResource ? selectedResource.services : [];

    const selectedSlotWithData = {
      ...slotInfo,
      services: additionalData,
      resourceName: selectedResource.resource.name,
    };
    onSelectSlotClick(selectedSlotWithData);
  };

  return (
    <div style={{ overflow: 'auto', width: '90%', paddingTop: '3rem' }}>
      <Calendar
        localizer={localizer}
        events={events}
        resources={resources}
        resourceIdAccessor='resourceId'
        resourceTitleAccessor='resourceTitle'
        defaultView='day' // Use "day" or "week"
        views={['day']} // Allow day and week views
        components={{
          event: CustomEvent,
          resourceHeader: CustomHeader,
          dateCellWrapper: CustomDayCell,
          timeGutterHeader: CustomTimeGutterHeader,
        }}
        style={{ height: '120vh' }}
        min={startWorkingHour}
        max={endWorkingHour}
        step={60} // 60-minute time slots
        timeslots={1} // 1 slots per hour (for finer granularity)
        selectable={true}
        onNavigate={onDateChange}
        onSelectSlot={handleSelectSlot}
        onSelectEvent={onEventSlotClick}
      />
    </div>
  );
};

export default ScheduleAppointmentsCalendar;
