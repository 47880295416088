import theme from '../../../../../../themes/theme';

const styles: Record<string, React.CSSProperties> = {
  wrapper: {
    display: 'flex',
    flex: '1',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.main,
  },

  formWrapper: {
    display: 'flex',
    width: '40rem',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
  },

  forgotPassBtnWrapper: {
    display: 'flex',
    flex: '0.5',
    justifyContent: 'center',
    margin: '0.5rem 0 0 0',
  },

  inputForm: {
    margin: '1rem 0 0 0',
  },

  forgotPasswordButton: {
    textTransform: 'initial',
    fontSize: theme.typography.body1.fontSize,
    // @ts-ignore
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.light,
    },
  },

  loginButton: {
    margin: '1rem 0 0 0',
    width: '23rem',
    borderRadius: 0,
    height: '2.75rem',
    // @ts-ignore
    '&.Mui-disabled': {
      backgroundColor: theme.palette.secondary.light,
      color: 'lightgray',
    },
  },
  titleText: {
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.h1.fontFamily,
    fontSize: theme.typography.h2.fontSize,
  },
};

export default styles;
