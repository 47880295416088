import React, { useState } from 'react';
import SunriseLogo from '../../../../../customerComponents/components/SunriseLogo/SunriseLogo';
import BookitInputField from '../../../../../components/foundation/BookitInputField/BookitInputField';
import BookitButton from '../../../../../components/foundation/BookitButton/BookitButton';
import styles from './BackOfficeForgotPassword.style';
import { text } from '../../../../../constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../../types';
import { AppDispatch } from '../../../../../store/store';
import { handleNewErrorThunk } from '../../../../App/redux/errorSlice';
import { forgotPasswordBoThunk } from '../../redux/boAuthSlice';

const BackOfficeForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(false);
  const [showResetPassMsg, setShowResetPassMsg] = useState(false);
  const { loading } = useSelector((store: IStore) => store.backOfficeAuth);

  const dispatch = useDispatch<AppDispatch>();

  const buttonDisabled = !emailValid;

  const handleConfirm = async () => {
    try {
      await dispatch(forgotPasswordBoThunk(email)).unwrap();
      setShowResetPassMsg(true);
      setEmail('');
    } catch (e) {
      dispatch(handleNewErrorThunk(e));
    }
  };

  const handleEnterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !buttonDisabled) {
      handleConfirm();
      (event.target as HTMLElement).blur();
    }
  };

  const renderPage = () => {
    return (
      <div style={styles.wrapper} onKeyDown={handleEnterKeyDown}>
        <SunriseLogo size='large' />
        <p style={styles.titleText}>{text.FORGOT_PASSWORD.TITLE}</p>
        <p style={styles.description}>{text.FORGOT_PASSWORD.DESCTRIPTION}</p>
        <div style={styles.formWrapper}>
          <BookitInputField
            variant='outlined'
            label={text.FORGOT_PASSWORD.EMAIL}
            onChange={(e, valid) => {
              setEmail(e.target.value);
              setEmailValid(valid);
            }}
            value={email}
            helperText={text.EMAIL_HELPER_TEXT}
            type='email'
            required
          />
          {showResetPassMsg ? (
            <p style={styles.resetPasswordMessage}>{text.FORGOT_PASSWORD.RESET_PASSWORD_MESSAGE}</p>
          ) : (
            <BookitButton
              variant='contained'
              type='basic'
              color='primary'
              label={text.FORGOT_PASSWORD.CONFIRM_BUTTON}
              style={styles.confirmButton}
              disabled={buttonDisabled}
              onClick={handleConfirm}
              loading={loading}
            />
          )}
          <BookitButton
            variant='text'
            type='routerLink'
            routerPath='/login_bo'
            color='primary'
            label={text.FORGOT_PASSWORD.LOGIN_BUTTON}
            style={styles.loginButton}
          />
        </div>
      </div>
    );
  };

  return <>{renderPage()}</>;
};

export default BackOfficeForgotPassword;
