import React from 'react';
import BookitFooterAdvanced from '../foundation/BookitFooter/BookitFooterAdvanced';
import SunriseLogo from '../../customerComponents/components/SunriseLogo/SunriseLogo';
import { links, text } from '../../constants/constants';
import BookitButton from '../foundation/BookitButton/BookitButton';
import styles from './FooterAdvanced.style';
import BookitIcon from '../foundation/BookitIcon/BookitIcon';
import { useIsMobile } from '../../hooks/IsMobileHook';
import { useNavigate, useLocation } from 'react-router';
import SunriseNavigationButtons from '../../customerComponents/components/SunriseNavigationButtons/SunriseNavigationButtons';
import featureFlags from '../../featureFlags';

const FooterAdvanced = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const showReservationButton = pathname !== '/reservations' && featureFlags.showReservationsButton;

  const handleReserveButtonClick = () => {
    navigate('/reservations');
  };

  const renderDesktopView = () => {
    return (
      <>
        <div style={styles.companyInfoSection}>
          <div style={styles.logoAndSocialsWrapper}>
            <div style={{ display: 'flex' }}>
            <SunriseLogo size='large' isLogoWhite />
            </div>
            <p style={styles.followUsText}>{text.FOOTER.FOLLOW_US_TEXT}</p>
            <div>
              <a href={links.FACEBOOK_LINK} target='_blank' title={text.ABOUT_US.FACEBOOK_TITLE} rel='noreferrer'>
                <BookitIcon type='facebook' style={styles.icons} />
              </a>
              <a href={links.INSTAGRAM_LINK} target='_blank' title={text.ABOUT_US.INSTAGRAM_TITLE} rel='noreferrer'>
                <BookitIcon type='instagram' style={styles.icons} />
              </a>
            </div>
          </div>
          <SunriseNavigationButtons />
        </div>
        <div style={styles.reservationWrapper}>
          <p style={styles.reservationTitle}>{text.FOOTER.RESERVATION_TITLE}</p>
          <p style={styles.reservationDescription}>{text.FOOTER.RESERVATION_DESCRIPTION}</p>
          {showReservationButton && (
            <BookitButton
              variant='contained'
              label={text.FOOTER.RESERVATION_BUTTON}
              type='basic'
              style={styles.reservationButton}
              disableRipple
              onClick={handleReserveButtonClick}
            />
          )}
        </div>
      </>
    );
  };
  const renderMobileView = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
        <div style={styles.logoAndSocialsWrapperMobile}>
          <SunriseLogo size='medium' isLogoWhite />
          <p style={styles.followUsTextMobile}>{text.FOOTER.FOLLOW_US_TEXT}</p>
          <div>
            <a href={links.FACEBOOK_LINK} target='_blank' title={text.ABOUT_US.FACEBOOK_TITLE} rel='noreferrer'>
              <BookitIcon type='facebook' style={styles.iconsMobile} />
            </a>
            <a href={links.INSTAGRAM_LINK} target='_blank' title={text.ABOUT_US.INSTAGRAM_TITLE} rel='noreferrer'>
              <BookitIcon type='instagram' style={styles.iconsMobile} />
            </a>
          </div>
        </div>
        <div style={styles.companyInfoSectionMobile}>
          <SunriseNavigationButtons />
        </div>
        <div>
          <p style={styles.reservationTitleMobile}>{text.FOOTER.RESERVATION_TITLE}</p>
          <p style={styles.reservationDescriptionMobile}>{text.FOOTER.RESERVATION_DESCRIPTION}</p>
          {showReservationButton && (
            <BookitButton
              variant='contained'
              label={text.FOOTER.RESERVATION_BUTTON}
              type='basic'
              style={styles.reservationButtonMobile}
              disableRipple
              onClick={handleReserveButtonClick}
            />
          )}
        </div>
      </div>
    );
  };

  return <BookitFooterAdvanced>{isMobile ? renderMobileView() : renderDesktopView()}</BookitFooterAdvanced>;
};

export default FooterAdvanced;
