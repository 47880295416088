import theme from '../../../../themes/theme';

const styles: Record<string, React.CSSProperties> = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.secondary.main,
  },

  headerWrapper: {
    display: 'flex',
    flex: 0.1,
  },

  contentWrapper: {
    display: 'flex',
    flex: 0.9,
    flexDirection: 'column',
    overflow: 'auto',
    alignItems: 'center',
  },

  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
  },

  mobileContentContainer: {
    width: '95%',
  },

  footerWrapper: {
    width: '100%',
  },

  tabsContainer: {
    marginBottom: '2rem',
  },

  calendarWrapper: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: '2rem',
    marginLeft: '20%',
    marginRight: '20%',
  },

  submitButtonWrapper: {
    display: 'flex',
    justifyContent: 'right',
  },

  submitButton: {
    width: '10rem',
    margin: '0.8rem 1rem 0.2rem 0',
    padding: '7px',
  },

  loginButton: {
    display: 'flex',
    justifyContent: 'right',
    width: '3.4rem',
    minWidth: 0,
    fontSize: theme.typography.body2.fontSize,
    margin: '0 0 0 auto',
  },

  boButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '10rem',
    minWidth: 0,
    fontSize: theme.typography.body2.fontSize,
    margin: '0 0 0 auto',
  },

  divider: {
    color: theme.palette.primary.contrastText,
    width: '100%',
    borderWidth: '0.5px',
  },

  fields: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
};

export default styles;
