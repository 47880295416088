import React from 'react';
import SunriseInfoPage from '../../components/SunriseInfoPage/SunriseInfoPage';
import images from '../../images/constants';
import { text } from '../../constants';
import parentStyles from './SunriseLeaguesPage.style';
import { useIsMobile } from '../../../hooks/IsMobileHook';

const SunriseLeaguesPage = () => {
  const isMobile = useIsMobile();

  return (
    <SunriseInfoPage
      topImageSource={images.DRONE_SHOT_01}
      leftImage1={images.FOOTBALL_06}
      leftImage2={images.TROPHIES}
      leftImage3={images.FOOTBALL_GAME_01}
      rightImage={images.LEAGUES1}
    >
      <div style={parentStyles(isMobile).wrapper}>
        <h1 style={parentStyles(isMobile).title}>{text.LEAGUES.TITLE}</h1>
        <div style={parentStyles(isMobile).content}>
          <h2>{text.LEAGUES.LEAGUE_01_TITLE}</h2>
          <p>{text.LEAGUES.LEAGUE_01_DSC}</p>
          <h2>{text.LEAGUES.LEAGUE_02_TITLE}</h2>
          <p>{text.LEAGUES.LEAGUE_02_DSC}</p>
          <h2>{text.LEAGUES.LEAGUE_03_TITLE}</h2>
          <p>{text.LEAGUES.LEAGUE_03_DSC}</p>
        </div>
      </div>
    </SunriseInfoPage>
  );
};

export default SunriseLeaguesPage;
