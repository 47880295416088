import React from 'react';
import { Drawer } from '@mui/material';
import { useIsMobile } from '../../../hooks/IsMobileHook';
import styles from './BookitDrawer.style';
import BookitIcon from '../BookitIcon/BookitIcon';
import theme from '../../../themes/theme';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title?: string;
  style?: React.CSSProperties;
  childrenStyle?: React.CSSProperties;
  drawerHeaderStyle?: React.CSSProperties;
  anchor?: 'left' | 'right' | 'bottom' | 'top';
  onIconButtonClick?: () => void;
}

const BookitDrawer = ({
  isOpen,
  onClose,
  children,
  title,
  style,
  childrenStyle,
  drawerHeaderStyle,
  anchor,
  onIconButtonClick,
}: IProps) => {
  const isMobile = useIsMobile();
  const drawerStyle = isMobile ? styles.drawerMobile : styles.drawer;
  const customAnchor = isMobile ? 'bottom' : anchor || 'right';

  const renderOpenIcon = (top = null, right = null, left = null) => {
    const openIconCustomStyle: React.CSSProperties = {
      position: 'absolute',
      top: top,
      right: right,
      left: left,
      border: isOpen ? 'none' : `1px solid ${theme.palette.primary.main}`,
      borderRight: isOpen && 'none',
    };
    if (!onIconButtonClick) {
      return null;
    }

    return (
      <div
        onClick={onIconButtonClick}
        style={{
          ...styles.openIconWrapper,
          ...openIconCustomStyle,
        }}
      >
        <BookitIcon type={isOpen ? 'rightCircleArrow' : 'leftCircleArrow'} style={styles.openIcon} />
      </div>
    );
  };

  return (
    <div>
      {renderOpenIcon(40, 0)}
      <Drawer
        anchor={customAnchor}
        open={isOpen}
        onClose={onClose}
        sx={{ '& .MuiDrawer-paper': { ...drawerStyle, ...style, overflow: 'visible' } }}
      >
        {renderOpenIcon(40, null, -60)}

        <div style={{ ...styles.drawerHeaderWrapper, ...drawerHeaderStyle }}>
          <p style={styles.title}>{title}</p>
          <BookitIcon type='close' onClick={onClose} style={styles.drawerCloseIcon} />
        </div>
        <div style={{ ...styles.childrenWrapper, ...childrenStyle }}>{children}</div>
      </Drawer>
    </div>
  );
};

export default BookitDrawer;
