import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BookitButton from '../../../../components/foundation/BookitButton/BookitButton';
import BookitInputField from '../../../../components/foundation/BookitInputField/BookitInputField';
import { text } from '../../../../constants/constants';
import styles from './ForgotPasswordPage.style';
import { forgotPasswordThunk } from '../../redux/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../store/store';
import SunriseLogo from '../../../../customerComponents/components/SunriseLogo/SunriseLogo';
import { useIsMobile } from '../../../../hooks/IsMobileHook';
import { handleNewErrorThunk } from '../../../App/redux/errorSlice';
import { IStore } from '../../../../types';

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(false);
  const [showResetPassMsg, setShowResetPassMsg] = useState(false);
  const { loading } = useSelector((store: IStore) => store.auth);

  const isMobile = useIsMobile();

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const buttonDisabled = !emailValid;

  const handleConfirm = async () => {
    try {
      await dispatch(forgotPasswordThunk(email)).unwrap();
      setShowResetPassMsg(true);
      setEmail('');
    } catch (e) {
      dispatch(handleNewErrorThunk(e));
    }
  };

  const handleEnterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !buttonDisabled) {
      handleConfirm();
      (event.target as HTMLElement).blur();
    }
  };

  const renderLeftSide = () => (
    <div style={isMobile ? styles.mobileImageWrapper : styles.leftSideWrapper}>
      <div style={isMobile ? styles.mobileLogoWrapper : styles.logoWrapper}>
        <SunriseLogo size='medium' isLogoWhite={true} />
      </div>
      {!isMobile && (
        <div style={styles.contentWrapper}>
          <p style={styles.title}>{text.FORGOT_PASSWORD.TITLE}</p>
          <p style={styles.text}>{text.FORGOT_PASSWORD.DESCTRIPTION}</p>
        </div>
      )}
    </div>
  );

  const renderHaveAcc = () => (
    <div style={styles.loginButtonWrapper}>
      <span style={styles.haveAccountText}>{text.FORGOT_PASSWORD.HAVE_ACCOUNT}</span>
      <BookitButton
        variant='text'
        type='basic'
        label={text.FORGOT_PASSWORD.LOGIN_BUTTON}
        color='primary'
        onClick={() => {
          navigate('/login');
        }}
        style={styles.loginButton}
      />
    </div>
  );

  const renderRightSide = () => (
    <div style={styles.rightSideWrapper}>
      {isMobile && (
        <div style={styles.mobileContentWrapper}>
          <p style={styles.mobileTitle}>{text.FORGOT_PASSWORD.TITLE}</p>
          <p style={styles.text}>{text.FORGOT_PASSWORD.DESCTRIPTION}</p>
        </div>
      )}
      <div>
        <BookitInputField
          variant='outlined'
          label={text.FORGOT_PASSWORD.EMAIL}
          onChange={(e, valid) => {
            setEmail(e.target.value);
            setEmailValid(valid);
          }}
          value={email}
          type='email'
          helperText={text.EMAIL_HELPER_TEXT}
          required
          size='medium'
          fieldTheme='light'
        />
      </div>
      {showResetPassMsg ? (
        <p style={styles.resetPasswordMessage}>{text.FORGOT_PASSWORD.RESET_PASSWORD_MESSAGE}</p>
      ) : (
        <div style={styles.confirmButtonWrapper}>
          <BookitButton
            variant='contained'
            type='basic'
            color='primary'
            onClick={handleConfirm}
            label={text.FORGOT_PASSWORD.CONFIRM_BUTTON}
            style={styles.confirmButton}
            disabled={buttonDisabled}
            loading={loading.forgotPassword}
          />
        </div>
      )}
      {renderHaveAcc()}
    </div>
  );

  return (
    <div style={isMobile ? styles.mobileWrapper : styles.wrapper} onKeyDown={handleEnterKeyDown}>
      {renderLeftSide()}
      {renderRightSide()}
    </div>
  );
};

export default ForgotPasswordPage;
