import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiService from '../../../services/api-service';
import { IReservationSlice } from '../../../types';

const initialState: IReservationSlice = {
  allAppointments: [],
  activeAppointments: [],
};

const reservationsSlice = createSlice({
  name: 'RESERVATIONS',
  initialState,
  reducers: {
    importReservationAction(state, action) {
      state.allAppointments = action.payload;
      const activeReservations = state.allAppointments.filter((item) => {
        return item.status === 'APPROVED' && new Date(item.start_date) > new Date();
      });

      state.activeAppointments = activeReservations.sort((a, b) => {
        // @ts-ignore
        return new Date(a.start_date) - new Date(b.start_date);
      });
    },
    clearReservationsAction(state) {
      state.allAppointments = [];
      state.activeAppointments = [];
    },
  },
});

export const { importReservationAction, clearReservationsAction } = reservationsSlice.actions;
export default reservationsSlice.reducer;

// Async thunk
export const getAppointmentsThunk = createAsyncThunk(
  'GET_RESERVATIONS',
  async ({ limit, offset, order }: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await ApiService.getHomeService().getAppointments(limit, offset, order);
      dispatch(importReservationAction(response));
    } catch (error) {
      return rejectWithValue({
        code: error.code,
        message: error.message,
        status: error.status,
      });
    }
  },
);
