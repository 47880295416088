import axios, { AxiosInstance, isAxiosError } from 'axios';
import qs from 'qs';
import { store } from '../store/store';
import { NoInternetError, ServerError } from './errors';

export default class ApiClient {
  private static instance: ApiClient;
  private static getInstance(): ApiClient {
    if (!ApiClient.instance) {
      ApiClient.instance = new ApiClient();
    }
    return ApiClient.instance;
  }

  private axiosInstance: AxiosInstance;
  private boAxiosInstance: AxiosInstance;

  public static async post<T>(url: string, body: object, backOfficeInstance: boolean = false): Promise<T> {
    let response;
    if (backOfficeInstance) {
      response = await this.getInstance().boAxiosInstance.post<T>(url, body);
    } else {
      response = await this.getInstance().axiosInstance.post<T>(url, body);
    }
    return response.data;
  }

  public static async put<T>(url: string, body: object, backOfficeInstance: boolean = false): Promise<T> {
    let response;
    if (backOfficeInstance) {
      response = await this.getInstance().boAxiosInstance.put<T>(url, body);
    } else {
      response = await this.getInstance().axiosInstance.put<T>(url, body);
    }
    return response.data;
  }

  public static async delete<T>(url: string, backOfficeInstance: boolean = false): Promise<T> {
    let response;
    if (backOfficeInstance) {
      response = await this.getInstance().boAxiosInstance.delete<T>(url);
    } else {
      response = await this.getInstance().axiosInstance.delete<T>(url);
    }
    return response.data;
  }

  public static async get<T>(url: string, config: object, backOfficeInstance: boolean = false): Promise<T> {
    let response;
    if (backOfficeInstance) {
      response = await this.getInstance().boAxiosInstance.get<T>(url, { params: config });
    } else {
      response = await this.getInstance().axiosInstance.get<T>(url, { params: config });
    }
    return response.data;
  }

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: `${process.env.REACT_APP_SERVER_BASE_URL}/${process.env.REACT_APP_COMPANY_ID}`,
    });

    this.boAxiosInstance = axios.create({
      baseURL: `${process.env.REACT_APP_SERVER_BO_BASE_URL}/${process.env.REACT_APP_COMPANY_ID}`,
    });

    this.axiosInstance.interceptors.request.use(ApiClient.requestInterceptor, (error) => Promise.reject(error));
    this.axiosInstance.interceptors.response.use(null, ApiClient.responseErrorInterceptor);

    this.boAxiosInstance.interceptors.request.use(ApiClient.boRequestInterceptor, (error) => Promise.reject(error));
    this.boAxiosInstance.interceptors.response.use(null, ApiClient.responseErrorInterceptor);
  }

  /**
   * Intercepts every request going through axios and sets all important headers
   */
  private static requestInterceptor(req) {
    const online = store.getState().network.online;
    if (!online) {
      throw new NoInternetError();
    }

    const newRequest = { ...req };

    newRequest.headers = {
      ...newRequest.headers,
      ...ApiClient.setAuthHeaders(),
      ...ApiClient.setContentTypeHeaders(req),
      'accept-language': 'RS',
    };

    if (newRequest.method === 'post' && newRequest.headers['Content-Type'] !== 'multipart/form-data') {
      newRequest.data = qs.stringify(newRequest.data);
    }

    return newRequest;
  }

  /**
   * Intercepts every request going through axios and sets all important headers for the BackOffice
   */
  private static boRequestInterceptor(req) {
    const online = store.getState().network.online;
    if (!online) {
      throw new NoInternetError();
    }

    const newRequest = { ...req };

    newRequest.headers = {
      ...newRequest.headers,
      ...ApiClient.setAuthHeaders(true),
      ...ApiClient.setContentTypeHeaders(req),
      'accept-language': 'RS',
    };

    if (newRequest.method === 'post' && newRequest.headers['Content-Type'] !== 'multipart/form-data') {
      newRequest.data = qs.stringify(newRequest.data);
    }

    return newRequest;
  }

  /**
   * Sets Content-Type of request
   */
  private static setContentTypeHeaders(request) {
    const { url, data, headers, method } = request;
    let contentType = headers['Content-Type'];
    let accept = headers.Accept;

    if (method === 'post') {
      contentType = 'application/x-www-form-urlencoded; charset=UTF-8';
      accept = 'application/json';
    }

    return {
      'Content-Type': contentType,
      Accept: accept,
    };
  }

  /**
   * Intercepts every error response from server
   */
  private static responseErrorInterceptor(error) {
    if (error instanceof NoInternetError) {
      return Promise.reject(new ServerError(error.message));
    }

    const err = new ServerError();
    if (!isAxiosError(error)) {
      throw err;
    }
    if (error.response) {
      err.status = error.response.status;
      err.message = error.response.data.message;
      err.code = error.response.data?.code;
    }

    return Promise.reject(err);
  }

  /**
   * Sets User Bearer token
   */
  private static setAuthHeaders(backOfficeHeaders: boolean = false) {
    let token;
    try {
      const storageToken = backOfficeHeaders ? localStorage.getItem('BO_ACCESS_TOKEN') : localStorage.getItem('ACCESS_TOKEN');
      if (storageToken === 'undefined') {
        token = null;
      } else {
        token = storageToken;
      }
    } catch (err) {}
    return {
      authorization: token && `Bearer ${token}`,
    };
  }
}
