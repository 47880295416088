import theme from '../../../themes/theme';

export const SIZE = {
  small: {
    width: '11rem',
    margin: 'auto auto 0.5rem auto',
    '& .MuiInputBase-root': {
      height: '2.2rem',
      fontSize: '0.8rem',
    },
    '& .MuiInputLabel-root': {
      fontSize: '0.8rem',
      margin: '-0.4rem 0 0 0',
      '&.Mui-focused': {
        margin: 'auto',
      },
    },
  },
  medium: {
    width: '23rem',
    margin: 'auto auto 1rem auto',
    '& .MuiInputBase-root': {
      height: '2.6rem',
      fontSize: '1rem',
      borderRadius: '1px',
    },
    '& .MuiInputLabel-root': {
      fontSize: '0.85rem',
      margin: '-0.26rem 0 0 0',
      '&.Mui-focused': {
        margin: 'auto',
      },
    },
  },
  large: {
    width: '28rem',
    margin: 'auto auto 0.5rem auto',
    '& .MuiInputBase-root': {
      height: '3rem',
      fontSize: '1rem',
    },
    '& .MuiInputLabel-root': {
      fontSize: '1.2rem',
      margin: '-0.3rem 0 0 0',
      '&.Mui-focused': {
        margin: 'auto',
      },
    },
  },
};

export const THEME = {
  light: {
    '& .MuiInputLabel-root': {
      color: theme.palette.secondary.light,
      '&.Mui-focused': { color: theme.palette.primary.main },
    },
    '& .MuiInputBase-input': {
      color: theme.palette.primary.contrastText,
    },
    '& .MuiOutlinedInput-root fieldset': {
      borderColor: theme.palette.secondary.light,
      borderWidth: '2px',
    },
    '& .MuiOutlinedInput-root:hover fieldset': {
      borderColor: theme.palette.primary.main,
      borderWidth: '2px',
    },
    // test this
    '& .MuiInputBase-input:-webkit-autofill': {
      color: 'white',
      WebkitTextFillColor: theme.palette.primary.contrastText,
      caretColor: theme.palette.primary.contrastText,
      transition: 'background-color 5000s ease-in-out 0s',
    },
  },
  dark: {
    '& .MuiInputLabel-root': {
      // color: theme.palette.secondary.dark,
      color: 'black',
      '&.Mui-focused': { color: theme.palette.primary.dark },
    },
    '& .MuiInputBase-input': {
      // color: theme.palette.secondary.dark,
      color: 'black',
    },
    '& .MuiOutlinedInput-root fieldset': {
      // borderColor: theme.palette.secondary.dark,
      borderColor: 'black',
      borderWidth: '2px',
    },
    '& .MuiOutlinedInput-root:hover fieldset': {
      borderColor: theme.palette.primary.dark,
      borderWidth: '2px',
    },
  },
  disabled: {
    '& .MuiInputLabel-root': {
      color: theme.palette.secondary.main,
      '&.Mui-focused': { color: theme.palette.primary.contrastText },
      '&.Mui-disabled': { color: theme.palette.secondary.light },
    },
    '& .MuiInputBase-input': {
      color: theme.palette.primary.contrastText,
    },
    '& .MuiOutlinedInput-root fieldset': {
      borderColor: theme.palette.secondary.light,
      borderWidth: '2px',
    },
    '& .MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor: theme.palette.secondary.light,
    },
  },
};

const iconStyle = {
  display: 'flex',
  cursor: 'pointer',
};

export const ICON_THEME = {
  light: {
    ...iconStyle,
    color: theme.palette.primary.contrastText,
  },
  dark: {
    ...iconStyle,
    color: theme.palette.secondary.dark,
  },
};

const styles: Record<string, React.CSSProperties> = {
  passwordConditionsWrapper: {
    margin: '0 0 0.5rem 0',
    padding: 0,
    color: theme.palette.secondary.light,
    fontFamily: theme.typography.fontFamily,
  },
  passwordConditions: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.9rem',
    padding: 0,
    margin: 0,
  },
  passwordConditionsIcon: {
    padding: '0 0.2rem 0 0',
    margin: 0,
    fontSize: '1.2rem',
    display: 'flex',
  },
};

export default styles;
