import React, { useCallback, useState } from 'react';
import { text } from '../../../constants/constants';
import { useIsMobile } from '../../../hooks/IsMobileHook';
import BookitButton from '../BookitButton/BookitButton';
import BookitIcon from '../BookitIcon/BookitIcon';
import styles from './DayPickerCalendar.style';

const dayNames = [
  text.WEEK_DAYS.SUNDAY,
  text.WEEK_DAYS.MONDAY,
  text.WEEK_DAYS.TUESDAY,
  text.WEEK_DAYS.WEDNESDAY,
  text.WEEK_DAYS.THURSDAY,
  text.WEEK_DAYS.FRIDAY,
  text.WEEK_DAYS.SATURDAY,
];

const DayPickerCalendar = ({ selectedDate, setSelectedDate }) => {
  const [startDate, setStartDate] = useState(new Date());

  const getCalendarData = useCallback(
    (i) => {
      const today = new Date();
      const startOfWeek = new Date(startDate);

      const currentDate = new Date(startDate);
      currentDate.setDate(startOfWeek.getDate() + i);
      const isSelected = selectedDate && currentDate.toDateString() === selectedDate.toDateString();
      const isPast = currentDate < today && currentDate.toDateString() !== today.toDateString();

      return { isSelected, isPast, currentDate };
    },
    [selectedDate, startDate],
  );

  const isMobile = useIsMobile();

  const handleDateClick = (date) => {
    setSelectedDate(date);
  };

  const handleNextWeek = () => {
    setStartDate(new Date(startDate.setDate(startDate.getDate() + 7)));
  };

  const handlePreviousWeek = () => {
    setStartDate(new Date(startDate.setDate(startDate.getDate() - 7)));
  };

  const startOfWeek = new Date(startDate);
  let monthName = startOfWeek.toLocaleString('sr-Latn', { month: 'long' });
  monthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);

  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(endOfWeek.getDate() + 6);
  const endOfWeekMonth = endOfWeek.getMonth();

  if (startOfWeek.getMonth() !== endOfWeekMonth) {
    let endMonthName = endOfWeek.toLocaleString('sr-Latn', { month: 'long' });
    endMonthName = endMonthName.charAt(0).toUpperCase() + endMonthName.slice(1);
    monthName += ` - ${endMonthName}`;
  }

  const renderDays = () => {
    const days = [];

    for (let i = 0; i < 7; i++) {
      const { isPast, isSelected, currentDate } = getCalendarData(i);
      const buttonDesktopStyle = isSelected ? styles.selectedButton : styles.dayButton;
      const buttonMobileStyle = isSelected ? styles.selectedButtonMobile : styles.dayButtonMobile;

      days.push(
        <div key={i}>
          <div style={styles.dayNames}>{dayNames[currentDate.getDay()]}</div>
          <BookitButton
            variant='text'
            label={`${currentDate.getDate()}`}
            onClick={() => handleDateClick(currentDate)}
            type='dayPicker'
            style={isMobile ? buttonMobileStyle : buttonDesktopStyle}
            color='secondary'
            disabled={isPast}
          />
        </div>,
      );
    }
    return days;
  };

  const renderPreviousWeekIcon = () => (
    <BookitIcon
      type='leftArrow'
      onClick={handlePreviousWeek}
      style={isMobile ? styles.prevWeekIconMobile : styles.prevWeekIcon}
    />
  );

  const renderNextWeekIcon = () => (
    <BookitIcon type='rightArrow' onClick={handleNextWeek} style={isMobile ? styles.nextWeekIconMobile : styles.nextWeekIcon} />
  );

  return (
    <>
      <div style={styles.monthName}>{monthName}</div>
      <div style={styles.mainContainer}>
        {renderPreviousWeekIcon()}
        {renderDays()}
        {renderNextWeekIcon()}
      </div>
    </>
  );
};

export default DayPickerCalendar;
