import { makeStyles } from '@mui/styles';
import theme from '../../../../../themes/theme';

export const useStyles = makeStyles({
  refreshText: {
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
  },

  refreshIcon: {
    marginLeft: '10px',
    cursor: 'pointer',
    color: theme.palette.primary.contrastText,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },

  tableActionCell: {
    display: 'flex',
    flex: '0.2 !important',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      color: `${theme.palette.primary.main} !important`,
    },
  },
  tableExtraSmallCell: {
    flex: '0.1 !important',
  },

  tableSmallCell: {
    flex: '0.3 !important',
  },

  tableMediumCell: {
    flex: '0.5 !important',
  },

  tableLargeCell: {
    flex: '0.7 !important',
  },
  tableNoteCell: {
    width: '10px',
    whiteSpace: 'wrap',
  },
});
