import { IProfileService, IProfileDTO, IProfileUpdateDTO } from '../../../services/serviceTypes';
import ApiClient from '../../../services/api-client';

export default class ApiProfileServices implements IProfileService {
  public async getProfileData() {
    const data = await ApiClient.get<IProfileDTO>(`/profile`, {});
    return data;
  }

  public async changeProfileData(data: IProfileUpdateDTO): Promise<void> {
    await ApiClient.put(`/profile`, data);
  }
}
