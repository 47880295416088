import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { onChangeStatus } from '../features/App/redux/appSlice';

function useNetwork(onNetworkChange, dispatch) {
  const [networkState, setNetworkState] = useState(() => ({
      online: navigator.onLine,
    }));

  useEffect(() => {
    const handleOnline = () => {
      setNetworkState((prevState) => ({
        ...prevState,
        online: true,
        since: new Date().toString(),
      }));
    };
    const handleOffline = () => {
      setNetworkState((prevState) => ({
        ...prevState,
        online: false,
        since: new Date().toString(),
      }));
    };
    const handleConnectionChange = () => {
      setNetworkState((prevState) => ({
        ...prevState,
      }));
    };
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    dispatch(onChangeStatus(networkState.online));

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [navigator.onLine, onNetworkChange, networkState]);
}
export default useNetwork;
