export default {
  parseQueryParams,
};

/**
 * Parse query params
 */
function parseQueryParams(data) {
  if (!data || !Object.keys(data).length) {
    return null;
  }

  const params = {};
  Object.keys(data).forEach((key) => {
    params[key] = data[key];
  });

  return params;
}
