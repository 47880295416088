import theme from '../../../../themes/theme';
import images from '../../../../images/constants';

const styles: Record<string, React.CSSProperties> = {
  wrapper: {
    display: 'flex',
    height: '100%',
    userSelect: 'none',
  },

  mobileWrapper: {
    display: 'flex',
    flexDirection: 'column',
    userSelect: 'none',
  },

  leftSideWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundImage: `url(${images.TENNIS_COURT_03})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    textShadow: '2px 2px 6px #000000',
  },

  mobileImageWrapper: {
    backgroundImage: `url(${images.TENNIS_COURT_03})`,
    backgroundSize: 'cover',
    height: '18rem',
  },

  logoWrapper: {
    position: 'absolute',
    top: '1.5rem',
    left: '2rem',
  },

  mobileLogoWrapper: {
    position: 'absolute',
    top: '13rem',
    left: '1rem',
  },

  contentWrapper: {
    padding: '2rem',
  },

  mobileContentWrapper: {
    padding: '0 0 2rem 1rem',
  },

  title: {
    fontSize: '2.5rem',
    fontFamily: theme.typography.h2.fontFamily,
    color: theme.palette.primary.contrastText,
  },

  mobileTitle: {
    fontSize: theme.typography.h2.fontSize,
    fontFamily: theme.typography.h2.fontFamily,
    color: theme.palette.primary.contrastText,
  },

  text: {
    fontSize: theme.typography.body1.fontSize,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primary.contrastText,
  },

  haveAccountText: {
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
  },

  rightSideWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: theme.palette.secondary.main,
  },

  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },

  confirmButtonWrapper: {
    marginTop: '1rem',
    width: '23rem',
    height: '2.75rem',
    marginBottom: '2rem',
  },


  confirmButton: {
    width: '100%',
    height: '100%',
    borderRadius: '1px',
    margin: 0,
    // @ts-ignore
    '&.Mui-disabled': {
      color: 'gray',
      backgroundColor: theme.palette.secondary.light,
    },
  },

  loginButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 1.5rem 0',
  },

  loginButton: {
    textTransform: 'initial',
    fontSize: theme.typography.body1.fontSize,
    marginTop: '1px',
    textDecoration: 'underline',
    // @ts-ignore
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.light,
    },
  },

  resetPasswordMessage: {
    color: theme.palette.primary.light,
    fontFamily: theme.typography.body1.fontFamily,
  },
};

export default styles;
