import { IBackOfficeAuth } from '../../../../services/serviceTypes';
import ApiClient from '../../../../services/api-client';

export default class ApiBackOfficeAuthServices implements IBackOfficeAuth {
  public async loginBoEmailAndPassword(email: string, password: string) {
    const data = await ApiClient.post<{ jwt: string }>(
      `/auth/login`,
      {
        email: email,
        password: password,
      },
      true,
    );
    return data.jwt;
  }
  public async forgotPasswordBo(email): Promise<void> {
    await ApiClient.post<{ jwt: string }>(
      `/auth/forgot-password`,
      {
        email: email,
      },
      true,
    );
  }
  public async logoutBo(): Promise<void> {
    await ApiClient.post<{ jwt: string }>(`/auth/logout`, {}, true);
  }
}
