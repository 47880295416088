const styles = {
  mapWrapper: {
    height: '70%',
    width: '80%',
    margin: '0 auto',
    marginTop: '2rem',
  },

  mapContainer: {
    height: '100%'
  }
};

export default styles;
