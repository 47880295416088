import theme from '../../themes/theme';

const styles: Record<string, React.CSSProperties> = {
  mainContainer: {
    backgroundColor: theme.palette.secondary.dark,
    padding: '0.7rem',
    fontFamily: theme.typography.fontFamily,
  },

  profileButton: {
    color: theme.palette.primary.contrastText,
    // @ts-ignore
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: 'transparent',
    },
  },

  accountIcon: {
    color: theme.palette.primary.contrastText,
    margin: '0.4rem 0 0 0',
  },

  image: {
    width: '9rem',
    height: '9rem',
    margin: '0 0 2rem 0',
  },

  accountDrawerMainContainer: {
    margin: '1rem',
  },

  accountDrawerTitle: {
    textAlign: 'left',
    letterSpacing: '0.05rem',
    color: theme.palette.primary.contrastText,
  },

  infoFieldsWrapper: {
    margin: '1rem 0 0 0',
  },

  infoFieldsTitle: {
    color: 'white',
    fontWeight: 600,
    margin: '0 0 2rem 1rem',
  },

  accountEmail: {
    color: 'gray',
    display: 'flex',
    justifyContent: 'center',
    margin: '0 0 1.5rem 0',
  },

  accountDataFieldWrapper: {
    display: 'flex',
    margin: 'auto',
  },

  accountDataField: {
    paddingBottom: '1.5rem',
    display: 'flex',
    justifyContent: 'right',
    // @ts-ignore
    '& .MuiOutlinedInput-root': {
      '& input': {
        color: theme.palette.secondary.contrastText,
      },
      '&.Mui-focused': {
        '& .MuiInputBase-input': {
          color: theme.palette.primary.contrastText,
        },
      },
    },
  },
  confirmDataChangesButton: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '2rem 3rem 1.5rem 3rem',
  },
  submitButton: {
    height: '2.8rem',
    width: '100%',
    marginLeft: '0.5rem',
    borderRadius: '0.1rem',
    boxShadow: 'none',
    fontWeight: theme.typography.h1.fontWeight,
    letterSpacing: '0.05rem',
    // @ts-ignore
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.dark,
    },
    '&:active': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      transform: 'scale(0.99)',
      boxShadow: 'none',
    },
  },
  discardButton: {
    height: '2.8rem',
    width: '100%',
    marginRight: '0.5rem',
    borderRadius: '0.1rem',
    color: theme.palette.secondary.light,
    fontWeight: theme.typography.h1.fontWeight,
    letterSpacing: '0.05rem',
    // @ts-ignore
    '&:hover': {
      color: theme.palette.primary.contrastText,
    },
    '&:active': {
      backgroundColor: 'transparent',
      color: theme.palette.secondary.light,
      transform: 'scale(0.98)',
    },
  },
  changePasswordButton: {
    color: theme.palette.primary.contrastText,
    fontSize: '1rem',
    margin: '0.5rem 0 0.5rem 0.5rem',
    padding: 0,
    textTransform: 'initial',
    // @ts-ignore
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  changePasswordField: {
    backgroundColor: 'white',
    margin: '1rem 1rem 1rem 0',
  },
  confirmPasswordChangeField: {
    backgroundColor: 'white',
    margin: '0 1rem 1rem 0',
  },
  confirmPasswordChangeButton: {
    margin: '0 1rem 1rem 0',
  },
  discardPasswordChangeButton: {
    margin: '0 1rem 1rem 0',
  },
  logOutButton: {
    color: theme.palette.primary.contrastText,
    fontSize: '1rem',
    textTransform: 'initial',
    margin: '1.5rem 0 0 0.5rem',
    padding: 0,
    justifyContent: 'left',
    // @ts-ignore
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },

  divider: {
    color: theme.palette.secondary.light,
    border: '1px solid',
  },

  accountIconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0 0 1rem 0',
  },

  accountInitialsIcon: {
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.primary.main,
    fontWeight: theme.typography.h1.fontWeight,
    width: '5rem',
    height: '5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    fontSize: '2.6rem',
    userSelect: 'none',
  },
};

export default styles;
