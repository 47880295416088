import { makeStyles } from '@mui/styles';
import theme from '../../../../../themes/theme';

const menuText = {
  color: theme.palette.primary.contrastText,
  fontFamily: theme.typography.fontFamily,
  cursor: 'pointer',
};

export const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flex: '1',
    flexDirection: 'row',
    height: '100%',
    backgroundColor: theme.palette.secondary.main,
  },

  menuText: menuText,
  selectedMenuText: {
    ...menuText,
    color: theme.palette.primary.main,
  },

  rightSideContentWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    flex: '1',
    margin: '0 10px 0 10px',
    overflow: 'auto',
  },

  logoWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },

  titleText: {
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.fontFamily,
  },

  logOutButton: {
    color: theme.palette.primary.contrastText,
  }
});
