import React, { useState } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { isEmail, isStrongPassword } from '../../../utils/validation.utils';
import { ICON_THEME, SIZE, THEME } from './BookitInputField.style';
import styles from './BookitInputField.style';
import BookitIcon from '../BookitIcon/BookitIcon';
import theme from '../../../themes/theme';
import { text } from '../../../constants/constants';

type InputType = 'password' | 'strongPassword' | 'email' | 'text';
type Size = 'small' | 'medium' | 'large';
type FieldTheme = 'light' | 'dark' | 'disabled';

interface IProps {
  variant: 'outlined' | 'filled' | 'standard';
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>, valid: boolean) => void;
  value: string;
  size?: Size;
  fieldTheme?: FieldTheme;
  style?: React.CSSProperties;
  type?: InputType;
  required?: boolean;
  helperText?: string;
  disabled?: boolean;
}

const TYPE_MAPPING: Map<InputType, string> = new Map<InputType, string>([
  ['password', 'password'],
  ['strongPassword', 'password'],
  ['email', 'email'],
  ['text', 'text'],
]);

const BookitInputField = ({
  variant,
  label,
  onChange,
  value,
  size = 'medium',
  fieldTheme = 'light',
  style,
  type,
  required,
  helperText,
  disabled,
}: IProps) => {
  const [showHelperText, setShowHelperText] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    upperLower: false,
    number: false,
    specialChar: false,
  });

  const isValid = (value: string): boolean => {
    if (required && !value) {
      return false;
    }
    if (type === 'email' && !isEmail(value)) {
      return false;
    }
    if (type === 'strongPassword' && !isStrongPassword(value)) {
      return false;
    }
    return true;
  };

  const updatePasswordCriteria = (value: string) => {
    setPasswordCriteria({
      length: value.length >= 8,
      upperLower: /[A-Z]/.test(value) && /[a-z]/.test(value),
      number: /\d/.test(value),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value),
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const valid = isValid(value);

    if (type === 'strongPassword') {
      updatePasswordCriteria(value);
    }
    setShowHelperText(value && !valid);
    onChange(e, valid);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const isPasswordField = type === 'password' || type === 'strongPassword';
  const iconStyle = ICON_THEME[fieldTheme];

  const helperTextColor = theme.palette.error.main;

  const renderPasswordHelperText = () => {
    const passwordConditions = [
      { key: 'length', text: text.PASSWORD_CONDITIONS_TEXT.MIN_LENGTH },
      { key: 'upperLower', text: text.PASSWORD_CONDITIONS_TEXT.UPPER_LOWER },
      { key: 'number', text: text.PASSWORD_CONDITIONS_TEXT.NUMBER },
      { key: 'specialChar', text: text.PASSWORD_CONDITIONS_TEXT.SPECIAL_CHAR },
    ];
    return (
      <div style={styles.passwordConditionsWrapper}>
        {passwordConditions.map(({ key, text }) => (
          <div key={key} style={{ display: 'flex' }}>
            <BookitIcon
              type='checkmark'
              style={{ ...styles.passwordConditionsIcon, color: passwordCriteria[key] && '#0bc680' }}
            />
            <p style={{ ...styles.passwordConditions, color: passwordCriteria[key] && 'white' }}>{text}</p>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <TextField
        variant={variant}
        label={label}
        onChange={handleChange}
        value={value}
        sx={{
          ...style,
          ...SIZE[size],
          ...THEME[fieldTheme],
        }}
        InputProps={{
          ...(isPasswordField && {
            type: showPassword ? 'text' : 'password',
            endAdornment: (
              <InputAdornment position='end'>
                <BookitIcon
                  type={showPassword ? 'passwordVisibilityOn' : 'passwordVisibilityOff'}
                  onClick={handleTogglePasswordVisibility}
                  style={iconStyle}
                />
              </InputAdornment>
            ),
          }),
        }}
        InputLabelProps={{
          sx: {
            fontSize: SIZE[size]['& .MuiInputLabel-root'].fontSize,
            margin: value ? '0' : SIZE[size]['& .MuiInputLabel-root'].margin,
            '&.Mui-focused': {
              margin: '0',
            },
          },
        }}
        inputProps={{
          sx: SIZE[size]['& .MuiInputBase-input'],
        }}
        type={TYPE_MAPPING.get(type)}
        required={required}
        helperText={showHelperText && helperText}
        FormHelperTextProps={{
          sx: {
            color: helperTextColor,
          },
        }}
        disabled={disabled}
      />
      {type === 'strongPassword' && renderPasswordHelperText()}
    </>
  );
};

export default BookitInputField;
