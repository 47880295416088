import React, { useEffect } from 'react';
import { getStyles } from './SunriseInfoPage.style';
import FooterAdvanced from '../../../components/Footer/FooterAdvanced';
import SunriseHeader from '../SunriseHeader/SunriseHeader';
import { useIsMobile } from '../../../hooks/IsMobileHook';

const SunriseInfoPage = ({ topImageSource, children, leftImage1, leftImage2, leftImage3, rightImage }) => {
  const isMobile = useIsMobile();
  const styles = getStyles(isMobile);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderDesktopView = () => {
    return (
      <>
        <div style={styles.textWrapper}>{children}</div>
        <div style={styles.bottomImagesWrapper}>
          <div style={styles.leftSide}>
            <div style={styles.topLeftImages}>
              <div style={styles.topLeftImageWrapper}>
                <img src={leftImage1} alt='Small' style={styles.topLeftImage} />
              </div>
              <div style={styles.topLeftImageWrapper}>
                <img src={leftImage2} alt='Small' style={styles.topLeftImage} />
              </div>
            </div>
            <img src={leftImage3} alt='Medium' style={styles.bottomLeftImage} />
          </div>

          <div style={styles.rightSide}>
            <img src={rightImage} alt='Large' style={styles.rightImage} />
          </div>
        </div>
      </>
    );
  };

  const renderMobileView = () => {
    return (
      <>
        <div style={styles.mobileTextWrapper}>{children}</div>
        <div style={styles.mobileImagesWrapper}>
          <img src={leftImage1} alt='First' style={styles.mobileImage} />
          <img src={leftImage2} alt='Second' style={styles.mobileImage} />
          <img src={leftImage3} alt='Third' style={styles.mobileImage} />
          <img src={rightImage} alt='Fourth' style={styles.mobileImage} />
        </div>
      </>
    );
  };

  return (
    <div style={styles.wrapper}>
      <div style={styles.topImageWrapper}>
        <img src={topImageSource} alt='Top of the page' style={styles.topImage} />
      </div>
      <SunriseHeader isTransparent={true} isWhiteLogo={true} />
      {isMobile ? renderMobileView() : renderDesktopView()}
      <FooterAdvanced />
    </div>
  );
};

export default SunriseInfoPage;
