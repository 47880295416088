import React from 'react';
import SunriseInfoPage from '../../components/SunriseInfoPage/SunriseInfoPage';
import images from '../../images/constants';
import { text } from '../../constants';
import parentStyles from './SunriseFootballPage.style';
import { useIsMobile } from '../../../hooks/IsMobileHook';

const SunriseLeaguesPage = () => {
  const isMobile = useIsMobile();

  return (
    <SunriseInfoPage
      topImageSource={images.FOOTBALL_FIELD_03}
      leftImage1={images.FOOTBALL_14}
      leftImage2={images.FOOTBALL_03}
      leftImage3={images.FOOTBALL_04}
      rightImage={images.FOOTBALL_20}
    >
      <div style={parentStyles(isMobile).wrapper}>
        <h1 style={parentStyles(isMobile).title}>{text.FOOTBALL_SCHOOL.TITLE}</h1>
        <div style={parentStyles(isMobile).content}>
          <h2>{text.FOOTBALL_SCHOOL.SC_TITLE}</h2>
          <p>{text.FOOTBALL_SCHOOL.SC_DSC_01}</p>
          <ul>
            {text.FOOTBALL_SCHOOL.SC_DSC_01_LIST.map((item: any, index: number) => (
              <li>{item}</li>
            ))}
          </ul>
          <p>{text.FOOTBALL_SCHOOL.SC_DSC_02}</p>
          <h2>{text.FOOTBALL_SCHOOL.FC_TITLE}</h2>
          <p>{text.FOOTBALL_SCHOOL.FC_DSC_01}</p>
          <ul>
            {text.FOOTBALL_SCHOOL.FC_DSC_01_LIST.map((item: any, index: number) => (
              <li>{item}</li>
            ))}
          </ul>
          <p>{text.FOOTBALL_SCHOOL.FC_DSC_02}</p>
          <p>{text.FOOTBALL_SCHOOL.FC_DSC_02_01}</p>
          <ul>
            {text.FOOTBALL_SCHOOL.FC_DSC_02_LIST.map((item: any, index: number) => (
              <li>{item}</li>
            ))}
          </ul>
          <p>{text.FOOTBALL_SCHOOL.FC_DSC_03}</p>
          <p>{text.FOOTBALL_SCHOOL.FC_DSC_03_LIST_TITLE}</p>
          <ul>
            {text.FOOTBALL_SCHOOL.FC_DSC_03_LIST.map((item: any, index: number) => (
              <li>{item}</li>
            ))}
          </ul>
          <h2>{text.FOOTBALL_SCHOOL.FC_DSC_04_TITLE}</h2>
          <p>{text.FOOTBALL_SCHOOL.FC_DSC_04}</p>
        </div>
      </div>
    </SunriseInfoPage>
  );
};

export default SunriseLeaguesPage;
