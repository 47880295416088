import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import styles from './BookitMap.style';

const customIcon = new L.Icon({
  iconUrl: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/ed/Map_pin_icon.svg/94px-Map_pin_icon.svg.png?20131230201244',
  iconSize: [30, 40],
  popupAnchor: [-3, -40],
});

const URL = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';

const BookitMap = ({ position, popup }) => {
  setTimeout(() => {
    window.dispatchEvent(new Event('resize'));
  }, 100);

  return (
    <div style={styles.mapWrapper}>
      <MapContainer center={position} zoom={15} style={styles.mapContainer}>
        <TileLayer url={URL} />
        <Marker position={position} icon={customIcon}>
          <Popup>{popup}</Popup>
        </Marker>
      </MapContainer>
    </div>
  );
};

export default BookitMap;
