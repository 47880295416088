import theme from '../../../themes/theme';

const mainTimeContainer: React.CSSProperties = {
  border: '1px solid white',
  borderRadius: '5px',
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.secondary.dark,
  cursor: 'pointer',
  display: 'inline-block',
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.body2.fontSize,
  width: '5rem',
  margin: '4px',
  padding: '2px 10px 2px 10px',
  textAlign: 'center',
  userSelect: 'none',
  textTransform: 'lowercase',
};

const styles: Record<string, React.CSSProperties> = {
  selectedTime: {
    ...mainTimeContainer,
    border: `1px solid ${theme.palette.primary.dark}`,
    color: theme.palette.secondary.dark,
    fontWeight: theme.typography.h3.fontWeight,
    backgroundColor: '#DDCBBB',
    // @ts-ignore
    '&:hover': {
      backgroundColor: '#DDCBBB',
      border: `1px solid ${theme.palette.primary.dark}`,
    },
  },

  disabled: {
    ...mainTimeContainer,
    backgroundColor: theme.palette.secondary.main,
    color: `${theme.palette.secondary.light} !important`,
    textDecoration: 'line-through',
    textDecorationColor: theme.palette.secondary.light,
  },

  time: {
    ...mainTimeContainer,
    // @ts-ignore
    // TODO - fix this
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
};

export default styles;
