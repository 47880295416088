import theme from '../../../themes/theme';

const styles = {
  box: {
    display: 'flex',
    justifyContent: 'left',
    margin: '2rem 0 0 0',
  },
  inactiveTab: {
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.h4.fontFamily,
    fontSize: theme.typography.h3.fontSize,
  },
};

export default styles;
