import theme from '../../../themes/theme';

export const getStyles = (isMobile: boolean, isRowLayout: boolean, currentPath: string): Record<string, any> => {
  const infoButtonsWrapperStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: isRowLayout ? 'row' : 'column',
    padding: 0,
  };

  const infoButtonsStyle = (routerPath?: string): React.CSSProperties => ({
    color: routerPath === currentPath ? theme.palette.primary.main : theme.palette.primary.contrastText,
    textUnderlineOffset: '0.3rem',
    fontFamily: theme.typography.h4.fontFamily,
    fontSize: theme.typography.h5.fontSize,
    padding: 0,
    minWidth: 'fit-content',
    margin: '0 0.7rem 0.5rem 0.7rem',
    textDecoration: routerPath === currentPath ? 'underline' : 'none',
    justifyContent: isMobile || isRowLayout ? 'center' : 'flex-start',
    textShadow: '0px 0px 3px #000000',
    // @ts-ignore
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: 'transparent',
      textDecoration: routerPath === currentPath ? 'underline' : 'none',
    },
  });

  return {
    infoButtonsWrapper: {
      ...infoButtonsWrapperStyle,
      margin: isMobile || isRowLayout ? '0.5rem 0 0 0' : '0 0 0 4rem',
      justifyContent: isMobile ? infoButtonsWrapperStyle.justifyContent : 'center',
    },

    infoButtons: infoButtonsStyle,
  };
};

export default getStyles;
