import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IProfileSocialDTO } from '../../../services/serviceTypes';
import ApiService from '../../../services/api-service';
import { setAccessToken } from '../../../utils/utils';

interface IPayload {
  token: string | null;
  client: 'GOOGLE';
}

const initialState = {
  token: null,
  client: 'GOOGLE',
  error: null,
  loading: false,
};

const authSocialSlice = createSlice({
  name: 'SOCIAL_AUTH',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(registerSocialThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerSocialThunk.rejected, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(registerSocialThunk.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(loginSocialThunk.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginSocialThunk.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(loginSocialThunk.rejected, (state, action) => {
        state.loading = false;
        // @ts-ignore
        state.error = action?.payload || 'eer'; // Error message passed via rejectWithValue
      });
  },
});

export default authSocialSlice.reducer;

export const registerSocialThunk = createAsyncThunk(
  'REGISTER_SOCIAL',
  async (payload: IProfileSocialDTO, { rejectWithValue }) => {
    try {
      const token = await ApiService?.getAuthService()?.registerSocial(payload);
      localStorage.setItem('ACCESS_TOKEN', token);
    } catch (error) {
      return rejectWithValue({
        code: error.code,
        message: error.message,
        status: error.status,
      });
    }
  },
);

export const loginSocialThunk = createAsyncThunk('LOGIN_SOCIAL', async (payload: IPayload, { dispatch, rejectWithValue }) => {
  try {
    const token = await ApiService?.getAuthService()?.loginSocial(payload.token, payload.client);
    setAccessToken(token);
  } catch (error) {
    return rejectWithValue({
      code: error.code,
      message: error.message,
      status: error.status,
    });
  }
});

export const continueViaSocialThunk = createAsyncThunk(
  'CONTINUE_VIA_SOCIAL',
  async (payload: IPayload, { dispatch, rejectWithValue }) => {
    try {
      const token = await ApiService?.getAuthService()?.continueViaSocial(payload.token, payload.client);
      setAccessToken(token);
      return token;
    } catch (error) {
      return rejectWithValue({
        code: error.code,
        message: error.message,
        status: error.status,
      });
    }
  },
);
