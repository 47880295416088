import React, { useEffect } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { setDefaultOptions } from 'date-fns';
import { format } from 'date-fns';
import { srLatn } from 'date-fns/locale';
import { text } from '../../../constants/constants';
import styles from './BookitCalendar.style';
import { TextField } from '@mui/material';

interface IProps {
  selectedDate: Date | null;
  setSelectedDate: (date: Date | null) => void;
  label?: string;
}

const BookitCalendar = ({ selectedDate, setSelectedDate, label }: IProps) => {
  const dateFormat = 'MM/dd/yyyy';

  useEffect(() => {
    setDefaultOptions({ locale: srLatn });
  }, []);
  const disableDate = (day) => {
    // TODO: get from BE
    const disabledDates = [new Date(2023, 9, 2), new Date(2023, 9, 3)];

    const dDate = disabledDates.some((d) => {
      const formatedCalendarDate = format(new Date(day), dateFormat);
      const formatedDate = format(new Date(d), dateFormat);

      return formatedDate === formatedCalendarDate;
    });

    return dDate;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        format='EEEE, dd.MM.yyyy'
        shouldDisableDate={(day) => disableDate(day)}
        disablePast={false}
        label={label || text.CALENDAR_TEXT}
        onChange={(e) => {
          setSelectedDate(e);
        }}
        value={selectedDate}
        slotProps={{
          textField: {
            sx: {
              svg: { color: 'white' }, // Input adornments (calendar icon)
              input: { color: 'white' }, // Input text color
              label: { color: 'white' }, // Label text color
              '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                  borderColor: '#F56E0F !important', // Hover border color
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#F56E0F', // Focused border color
                },
                '& fieldset': {
                  borderColor: 'gray', // Default border color
                },
              },
            },
          },
          popper: {
            sx: {
              '& .MuiPaper-root': {
                backgroundColor: 'gray', // Calendar background
                color: 'white', // Calendar text color
                borderColor: 'red',
              },
              '& .MuiPickersDay-root': {
                color: 'white', // Default day text color
              },
              '& .MuiPickersDay-root.Mui-selected': {
                backgroundColor: 'orange', // Selected day background
                color: 'white', // Selected day text color
              },
              '& .MuiPickersDay-root.Mui-disabled': {
                color: 'gray', // Disabled day color
              },
              '& .MuiPickersDay-root:hover': {
                backgroundColor: 'darkgray', // Hover color for days
              },
            },
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default BookitCalendar;
