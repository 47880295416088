import React from 'react';
import { AppBar } from '@mui/material';
import styles from './BookitFooterAdvanced.style';
import { useIsMobile } from '../../../hooks/IsMobileHook';

interface IProps {
  children: React.ReactNode;
}

const BookitFooterAdvanced = ({ children }: IProps) => {
  const isMobile = useIsMobile();

  return (
    <AppBar position='static' sx={isMobile ? styles.mainContainerMobile : styles.mainContainer}>
      {children}
    </AppBar>
  );
};

export default BookitFooterAdvanced;
