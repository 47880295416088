import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IField, IOngoingReservation, IStore } from '../../../types';
import { getAppointmentsThunk } from './reservationsSlice';
import ApiService from '../../../services/api-service';
import { setCompanyResourceSlotsThunk } from './companySlotsSlice';
import { price } from '../../AboutUs/pages/PricingPage/constants';

const initialFieldReservation: IField = {
  id: null,
  name: null,
  description: null,
  image_url: null,
};
const initialState: IOngoingReservation = {
  fieldReservation: initialFieldReservation,
  time: null,
  date: null,
  hash: null,
  serviceId: null,
  note: null,
  price: null,
  loading: false,
};

const ongoingReservationSlice = createSlice({
  name: 'ONGOING_RESERVATION',
  initialState,
  reducers: {
    addOngoingReservationDateAction(state, action) {
      state.date = action.payload;
    },
    addOngoingReservationTimeAction(state, action) {
      state.time = action.payload;
    },
    addOngoingReservationFieldAction(state, action) {
      state.fieldReservation = action.payload;
    },
    addOngoingReservationHashAction(state, action) {
      state.hash = action.payload;
    },
    addOngoingReservationNoteAction(state, action) {
      state.note = action.payload;
    },
    addOngoingReservationPriceAction(state, action) {
      state.price = action.payload;
    },
    clearOngoingReservationAction(state) {
      state.fieldReservation = initialFieldReservation;
      state.time = null;
      state.date = null;
      state.note = null;
      state.price = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleNewReservationThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(handleNewReservationThunk.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(handleNewReservationThunk.rejected, (state, action) => {
        state.loading = false;
        // @ts-ignore
        state.error = action?.payload || 'eer'; // Error message passed via rejectWithValue
      });
  },
});

export const {
  addOngoingReservationDateAction,
  addOngoingReservationTimeAction,
  addOngoingReservationFieldAction,
  addOngoingReservationHashAction,
  addOngoingReservationNoteAction,
  addOngoingReservationPriceAction,
  clearOngoingReservationAction,
} = ongoingReservationSlice.actions;
export default ongoingReservationSlice.reducer;

export const handleNewReservationThunk = createAsyncThunk(
  'ONGOING_RESERVATION',
  async (payload: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const state = getState();
      await ApiService.getHomeService().registerAppointment(payload.hash, payload.note);
      dispatch(
        setCompanyResourceSlotsThunk({
          serviceId: payload.serviceId,
          // @ts-ignore
          from: state.ongoingReservation.date,
          // @ts-ignore
          to: state.ongoingReservation.date,
        }),
      );
      await dispatch(getAppointmentsThunk({})).unwrap();
      dispatch(clearOngoingReservationAction());
    } catch (error) {
      return rejectWithValue({
        code: error.code,
        message: error.message,
        status: error.status,
      });
    }
  },
);

export const setReservationPriceThunk = createAsyncThunk(
  'ONGOING_RESERVATION_PRICE',
  async (timeSlot: string, { dispatch, getState }) => {
    // @ts-ignore
    const globalState: IStore = getState();

    dispatch(addOngoingReservationTimeAction(timeSlot));

    switch (timeSlot) {
      case '07:00h':
      case '08:00h':
      case '09:00h':
      case '10:00h':
      case '11:00h':
      case '12:00h':
      case '13:00h':
      case '14:00h':
      case '15:00h':
      case '16:00h':
      case '17:00h':
      case '18:00h':
      case '19:00h':
        switch (globalState.companySelectedResources.value.name) {
          case 'Tenis':
            switch (globalState.ongoingReservation.fieldReservation.name) {
              case 'Tenis - Teren 1':
              case 'Tenis - Teren 2':
              case 'Tenis - Teren 3':
              case 'Tenis - Teren 4':
                dispatch(addOngoingReservationPriceAction(600));
                return;
            }
        }
      case '20:00h':
      case '21:00h':
      case '22:00h':
      case '23:00h':
      default:
        switch (globalState.companySelectedResources.value.name) {
          case 'Fudbal':
            switch (globalState.ongoingReservation.fieldReservation.name) {
              case 'Fudbal - Teren 1':
                dispatch(addOngoingReservationPriceAction(price.FOOTBALL.FIELD_1));
                return;
              case 'Fudbal - Teren 2':
              case 'Fudbal - Teren 3':
                dispatch(addOngoingReservationPriceAction(price.FOOTBALL.FIELDS_2_3));
                return;
              case 'Fudbal - Teren 4':
              case 'Fudbal - Teren 5':
                dispatch(addOngoingReservationPriceAction(price.FOOTBALL.FIELDS_4_5));
                return;
              case 'Fudbal - Teren 6':
                dispatch(addOngoingReservationPriceAction(price.FOOTBALL.FIELD_6));
                return;
              default:
                return;
            }
          case 'Tenis':
            switch (globalState.ongoingReservation.fieldReservation.name) {
              case 'Multisport - Teren 1':
                dispatch(addOngoingReservationPriceAction(price.TENNIS.MULTISPORT));
                return;
              case 'Tenis - Teren 1':
              case 'Tenis - Teren 2':
              case 'Tenis - Teren 3':
              case 'Tenis - Teren 4':
                dispatch(addOngoingReservationPriceAction(price.TENNIS.FIELDS_1_2_3_4));
                return;
            }
          case 'Košarka':
          case 'Odbojka':
            switch (globalState.ongoingReservation.fieldReservation.name) {
              case 'Multisport - Teren 1':
                dispatch(addOngoingReservationPriceAction(price.VOLLEYBALL.MULTISPORT));
                return;
            }
          default:
            dispatch(addOngoingReservationPriceAction(0));
        }
    }
  },
);
