import React from 'react';
import { text } from '../../../constants/constants';
import styles from './SunriseLogo.style';
import theme from '../../../themes/theme';
import { useNavigate } from 'react-router-dom';

interface IProps {
  size: 'small' | 'medium' | 'large';
  isLogoWhite?: boolean;
}

const SunriseLogo = ({ size, isLogoWhite }: IProps) => {
  const styleSize = styles[size];
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/');
  };

  const titleStyle = {
    ...styleSize.titleStyle,
    color: isLogoWhite ? theme.palette.primary.contrastText : styleSize.titleStyle.color,
  };

  return (
    <div onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
      <p style={titleStyle}>{text.LOGO.TITLE}</p>
      <p style={styleSize.textStyle}>{text.LOGO.TITLE_TEXT}</p>
    </div>
  );
};

export default SunriseLogo;
