const styles: Record<string, React.CSSProperties> = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  confirmAccButton: {
    marginTop: '1.5rem',
  },
};
export default styles;
