import theme from '../../../themes/theme';
import images from '../../images/constants';

const styles: Record<string, React.CSSProperties> = {
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    userSelect: 'none',
    backgroundColor: theme.palette.secondary.dark,
    fontFamily: theme.typography.fontFamily,
  },

  welcomeWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },

  welcomeImage: {
    width: '100%',
    maxHeight: '60rem',
    opacity: 0.3,
    objectFit: 'cover',
  },

  welcomeTextWrapper: {
    color: theme.palette.primary.contrastText,
    position: 'absolute',
    textShadow: '2px 2px 6px #000000',
  },

  welcomeTitle: {
    fontFamily: theme.typography.h1.fontFamily,
    fontSize: 'clamp(1.5rem , 5vw, 3.5rem)',
    margin: 0,
  },

  seeMoreButtonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-end',
  },

  seeMoreButton: {
    textDecoration: 'underline',
    textTransform: 'initial',
    padding: '0 1rem 0 0',
    fontWeight: theme.typography.h4.fontWeight,
    // @ts-ignore
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },

  aboutUsWrapper: {
    display: 'flex',
  },

  aboutUsLeftSideContentWrapper: {
    display: 'flex',
    flex: 0.5,
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.dark,
    paddingBottom: '1rem'
  },

  aboutUsTextWrapper: {
    padding: 50,
    color: theme.palette.primary.contrastText,
  },

  aboutUsTitle: {
    fontFamily: theme.typography.h2.fontFamily,
    fontSize: '2.5rem',
    margin: '0 0 1rem 0',
  },

  aboutUsSubtitle: {
    margin: 0,
    fontSize: '1.1rem',
  },

  aboutUsRightSideContentWrapper: {
    display: 'flex',
    flex: 0.5,
  },

  aboutUsImage: {
    width: '100%',
    objectFit: 'cover',
    opacity: 0.6,
  },

  leaguesWrapper: {
    backgroundColor: theme.palette.secondary.dark,
  },

  leaguesTitle: {
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.h1.fontFamily,
    fontSize: '3rem',
    padding: '0 0 0 5%',
  },

  leaguesContentWrapper: {
    width: '90%',
    margin: 'auto',
    display: 'flex',
    textAlign: 'center',
  },

  leaguesTextWrapper: {
    width: '33%',
    backgroundColor: '#F4F2F0',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '0.5rem 0',
  },

  leaguesContentsTitle: {
    fontFamily: theme.typography.h3.fontFamily,
    fontSize: '2.2rem',
    margin: '0.5rem 0',
  },

  leaguesContentsSubtitle: {
    padding: '0 2rem',
    fontSize: '1.2rem',
  },

  leaguesImages: {
    width: '33%',
    objectFit: 'cover',
  },

  schoolsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
    height: '80rem',
    margin: '0 0 5rem 0',
  },

  schoolWrapper: {
    display: 'flex',
    flex: 0.5,
    flexDirection: 'row',
    overflow: 'hidden',
  },
  schoolImage: {
    objectFit: 'cover',
    width: '100%',
  },

  schoolContentWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '1vw 3vw 3vw 3vw',
  },

  schoolTitle: {
    fontSize: '2.2rem',
    fontFamily: theme.typography.h2.fontFamily,
  },

  schoolDescription: {
    fontSize: '1.1rem',
  },

  schoolsButton: {
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.h2.fontFamily,
    borderColor: theme.palette.primary.main,
    borderRadius: 0,
    justifyContent: 'space-between',
    width: '10rem',
    height: '3rem',
    fontSize: '1.4rem'
  },

  schoolsButtonIcon: {
    padding: '0.7rem 0 0 0',
  },

  footballSchoolLeftSideContentWrapper: {
    display: 'flex',
    flex: 0.5,
    overflow: 'hidden',
  },

  footballSchoolRightSideContentWrapper: {
    display: 'flex',
    flex: 0.5,
    flexDirection: 'column',
    backgroundImage: `url(${images.SCHOOL2})`,
  },

  footballSchoolRightImage: {
    width: '100%',
    objectFit: 'cover',
  },

  tennisSchoolRightSideContentWrapper: {
    display: 'flex',
    flex: 0.5,
    overflow: 'hidden',
  },

  tennisSchoolLeftSideContentWrapper: {
    display: 'flex',
    flex: 0.5,
    flexDirection: 'column',
    backgroundColor: theme.palette.primary.dark,
  },

  recreationalSportsWrapper: {
    backgroundColor: '#F4F2F0',
    display: 'flex',
    flex: 1,
    marginBottom: '5rem',
    height: '30rem',
    width: '100%',
  },

  recreationalSportsLeftSideContent: {
    flex: 0.5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  recreationalSportsTitle: {
    fontFamily: theme.typography.h1.fontFamily,
    fontSize: '2.8rem',
    padding: '2vw 0 0 2vw',
    margin: 0,
  },

  recreationalSportsSubtitle: {
    margin: 0,
    fontSize: '1rem',
    padding: '2vw',
  },

  pricingButton: {
    borderRadius: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: theme.typography.h4.fontFamily,
    fontSize: '1.4rem',
    color: 'black',
    padding: '0.4rem 0.5rem 0.2rem 0.8rem',
    margin: '1vw 0 1.5vw 2vw',
    width: '70%',
    height: '3rem',
  },

  pricingButtonIcon: {
    fontSize: '2rem',
    padding: '0.8rem 0 0 0',
  },

  recreationalSportsRightSideContent: {
    height: '30rem',
    flex: 0.5,
    display: 'flex',
  },

  recreationalSportsImageWrapper: {
    display: 'flex',
    flex: 0.25,
  },

  recreationalSportsImages: {
    objectFit: 'cover',
    width: '100%',
  },

  contactDetailsWrapper: {
    position: 'relative',
    height: '40rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '5rem',
  },

  contactDetailsImage: {
    width: '100%',
  },

  contactDetailsTitle: {
    fontFamily: theme.typography.h1.fontFamily,
    fontSize: '3rem',
    color: 'white',
    position: 'absolute',
    textShadow: '2px 2px 6px #000000',
  },
  mapWrapper: {
    position: 'absolute',
    inset: '0',
    width: '95%',
    height: '40rem',
    margin: 'auto',
    transform: 'translate(-6%, 19%)',
    zIndex: 1,
  },
  contactCard: {
    width: '16rem',
    height: '15rem',
    position: 'absolute',
    zIndex: 2,
    top: '40%',
    right: '7vw',
    backgroundColor: theme.palette.primary.dark,
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.primary.contrastText,
    justifyContent: 'center',
    alignItems: 'center',
  },

  contactCardIcons: {
    fontSize: '1.6rem',
    color: theme.palette.primary.contrastText,
  },

  contactCardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
  },

  contactCardInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette.primary.contrastText,
  },

  contactCardIconAndTitleWrapper: {
    display: 'flex',
  },

  contactCardInfoContainerTitle: {
    fontSize: '1.4rem',
    fontFamily: theme.typography.h4.fontFamily,
    margin: 0,
    padding: '0 0 0 0.2rem'
  },

  contactCardInfoContainersubtitle: {
    margin: '0 0 1rem 0',
    fontSize: '1rem',
    padding: '0 0 0 1.7rem'
  },
};

export default styles;
