import theme from '../../../../themes/theme';

const styles: Record<string, React.CSSProperties> = {
  mainWrapper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.secondary.main,
  },

  pageTitle: {
    fontFamily: theme.typography.h2.fontFamily,
    fontSize: theme.typography.h2.fontSize,
    color: theme.palette.primary.contrastText,
    margin: '9rem 0 1rem 5%',
  },

  cardsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginBottom: '2rem',
  },

  cardWrapper: {
    position: 'relative',
  },

  card: {
    margin: '1rem 0.5rem 0 0.5rem',
    backgroundColor: '#2F2F2F',
    fontFamily: theme.typography.fontFamily,
    width: '28rem',
    maxWidth: '100rem',
    // @ts-ignore
    '&> :last-child': {
      paddingBottom: '0px !important',
    },
  },

  cardTitleWrapper: {
    backgroundColor: theme.palette.primary.dark,
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.primary.contrastText,
    fontFamily: theme.typography.h3.fontFamily,
    fontSize: theme.typography.h3.fontSize,
    margin: 0,
    padding: 0,
  },

  cardTitle: {
    margin: '1rem 0',
  },

  cardHeaderWrapper: {
    backgroundColor: theme.palette.secondary.light,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 0.7rem 0 0.7rem',
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.primary.contrastText,
    position: 'relative',
  },

  cardHeaderItems: {
    maxWidth: '45%',
  },

  fieldDescription: {
    flex: 1,
    maxWidth: '44%',
    wordBreak: 'break-word',
    color: theme.palette.primary.contrastText,
    padding: '0 0 0 0.7rem',
    fontSize: '0.9rem',
  },

  fieldInfoWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.5rem 0 0.5rem 0',
  },

  priceAndTimeWrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '44%',
    textAlign: 'right',
    wordBreak: 'break-word',
    padding: '0 0.7rem 0 0',
    fontSize: '0.9rem',
  },

  fieldTime: {
    margin: '0 0 0.5rem 0',
    color: theme.palette.secondary.light,
  },

  fieldPrice: {
    color: theme.palette.primary.contrastText,
    margin: 0,
  },

  verticalDivider: {
    border: `1px solid gray`,
    left: '50%',
    height: '100%',
    position: 'absolute',
  },

  horizontalDivider: {
    border: '1px solid gray',
  },
};

export default styles;
