import React, { useEffect } from 'react';
import BookitCard from '../../../../components/foundation/BookitCard/BookitCard';
import styles from './PricingPage.style';
import { Divider } from '@mui/material';
import { text, fieldName, fieldTime, price } from './constants';
import FooterAdvanced from '../../../../components/Footer/FooterAdvanced';
import SunriseHeader from '../../../../customerComponents/components/SunriseHeader/SunriseHeader';

const cardsData = [
  {
    title: text.FOOTBALL_CARD_TITLE,
    fields: [
      { name: fieldName.FOOTBALL.FIELD_1, time: fieldTime.FULL_DAY, price: `${price.FOOTBALL.FIELD_1.toFixed(2)} RSD` },
      { name: fieldName.FOOTBALL.FIELD_2, time: fieldTime.FULL_DAY, price: `${price.FOOTBALL.FIELDS_2_3.toFixed(2)} RSD` },
      { name: fieldName.FOOTBALL.FIELD_3, time: fieldTime.FULL_DAY, price: `${price.FOOTBALL.FIELDS_2_3.toFixed(2)} RSD` },
      { name: fieldName.FOOTBALL.FIELD_4, time: fieldTime.FULL_DAY, price: `${price.FOOTBALL.FIELDS_4_5.toFixed(2)} RSD` },
      { name: fieldName.FOOTBALL.FIELD_5, time: fieldTime.FULL_DAY, price: `${price.FOOTBALL.FIELD_6.toFixed(2)} RSD` },
      { name: fieldName.FOOTBALL.FIELD_6, time: fieldTime.FULL_DAY, price: 'Kontaktirajte nas za više informacija.' },
    ],
  },
  {
    title: text.TENNIS_CARD_TITLE,
    fields: [
      {
        name: fieldName.TENNIS.FIELD_1,
        slots: [
          { time: fieldTime.TENNIS_DAY, price: `${price.TENNIS.FIELDS_1_2_3_4.toFixed(2)} RSD` },
          { time: fieldTime.TENNIS_NIGHT, price: `${price.TENNIS.FIELDS_1_2_3_4_NIGHT.toFixed(2)} RSD` },
        ],
      },
      {
        name: fieldName.TENNIS.FIELD_2,
        slots: [
          { time: fieldTime.TENNIS_DAY, price: `${price.TENNIS.FIELDS_1_2_3_4.toFixed(2)} RSD` },
          { time: fieldTime.TENNIS_NIGHT, price: `${price.TENNIS.FIELDS_1_2_3_4_NIGHT.toFixed(2)} RSD` },
        ],
      },
      {
        name: fieldName.TENNIS.FIELD_3,
        slots: [
          { time: fieldTime.TENNIS_DAY, price: `${price.TENNIS.FIELDS_1_2_3_4.toFixed(2)} RSD` },
          { time: fieldTime.TENNIS_NIGHT, price: `${price.TENNIS.FIELDS_1_2_3_4_NIGHT.toFixed(2)} RSD` },
        ],
      },
      {
        name: fieldName.TENNIS.FIELD_4,
        slots: [
          { time: fieldTime.TENNIS_DAY, price: `${price.TENNIS.FIELDS_1_2_3_4.toFixed(2)} RSD` },
          { time: fieldTime.TENNIS_NIGHT, price: `${price.TENNIS.FIELDS_1_2_3_4_NIGHT.toFixed(2)} RSD` },
        ],
      },
      {
        name: fieldName.TENNIS.MULTISPORT,
        slots: [{ time: fieldTime.FULL_DAY, price: `${price.TENNIS.MULTISPORT.toFixed(2)} RSD` }],
      },
    ],
  },
  {
    title: text.BASKETBALL_CARD_TITLE,
    fields: [
      {
        name: fieldName.BASKETBALL.MULTISPORT,
        time: fieldTime.FULL_DAY,
        price: `${price.BASKETBALL.MULTISPORT.toFixed(2)} RSD`,
      },
      {
        name: fieldName.BASKETBALL.HALF_COURT,
        time: fieldTime.FULL_DAY,
        price: `${price.BASKETBALL.HALF_COURT.toFixed(2)} RSD`,
      },
    ],
  },
  {
    title: text.VOLLEYBALL_CARD_TITLE,
    fields: [
      {
        name: fieldName.VOLLEYBALL.MULTISPORT,
        time: fieldTime.FULL_DAY,
        price: `${price.VOLLEYBALL.MULTISPORT.toFixed(2)} RSD`,
      },
    ],
  },
];
const PricingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderFieldData = (fields) => {
    return (
      <div style={styles.cardWrapper}>
        <Divider orientation='vertical' flexItem variant='fullWidth' sx={styles.verticalDivider} />
        {fields.map((field, index) => (
          <div key={index}>
            <div style={styles.fieldInfoWrapper}>
              <p style={styles.fieldDescription}>{field.name}</p>
              <div style={styles.priceAndTimeWrapper}>
                {field.slots ? (
                  field.slots.map((slot, slotIndex) => (
                    <div key={slotIndex}>
                      <p style={{ ...styles.fieldTime, paddingTop: '0.3rem' }}>{slot.time}</p>
                      <p style={{ ...styles.fieldPrice, paddingBottom: '0.3rem' }}>{slot.price}</p>
                    </div>
                  ))
                ) : (
                  <>
                    <p style={styles.fieldTime}>{field.time}</p>
                    <p style={styles.fieldPrice}>{field.price}</p>
                  </>
                )}
              </div>
            </div>
            {index < fields.length - 1 && <Divider flexItem sx={styles.horizontalDivider} />}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div style={styles.mainWrapper}>
      <SunriseHeader isTransparent />
      <div style={styles.pageTitle}>{text.PAGE_TITLE}</div>
      <div style={styles.cardsWrapper}>
        {cardsData.map((card, index) => (
          <BookitCard
            key={index}
            contentStyle={{ padding: 0 }}
            title={
              <div>
                <div style={styles.cardTitleWrapper}>
                  <p style={styles.cardTitle}>{card.title}</p>
                </div>
                <div style={styles.cardHeaderWrapper}>
                  <p style={styles.cardHeaderItems}>{text.CARD_HEADER_TITLE}</p>
                  <Divider orientation='vertical' flexItem variant='fullWidth' sx={styles.verticalDivider} />
                  <p style={styles.cardHeaderItems}>{text.CARD_HEADER_PRICE_TITLE}</p>
                </div>
              </div>
            }
            content={renderFieldData(card.fields)}
            style={styles.card}
          />
        ))}
      </div>
      <FooterAdvanced />
    </div>
  );
};

export default PricingPage;
