import React from 'react';
import { Dialog } from '@mui/material';
import BookitButton from '../BookitButton/BookitButton';
import { text } from '../../../constants/constants';
import styles from './BookitDialogAdvanced.style';
import { useIsMobile } from '../../../hooks/IsMobileHook';

interface IProps {
  open: boolean;
  children: React.ReactNode;
  onClose: () => void;
  onConfirm: (reservation: any) => void;
  loading?: boolean;
}

const BookitDialogAdvanced = ({ open, children, onClose, onConfirm, loading }: IProps) => {
  const isMobile = useIsMobile();

  return (
    <Dialog open={open} onClose={onClose}>
      <div style={isMobile ? styles.mobileMainContainer : styles.mainContainer}>
        {children}
        <div style={isMobile ? styles.mobileButtonContainer : styles.buttonContainer}>
          <BookitButton
            type='basic'
            color='secondary'
            label={text.DIALOG_TEXT.DEFAULT_DISCARD}
            variant='text'
            onClick={onClose}
            style={isMobile ? styles.mobileDiscardButton : styles.discardButton}
            disableRipple
          />
          <BookitButton
            type='basic'
            color='primary'
            label={text.DIALOG_TEXT.DEFAULT_CONFIRM}
            variant='contained'
            onClick={onConfirm}
            style={isMobile ? styles.mobileConfirmButton : styles.confirmButton}
            disableRipple
            loading={loading}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default BookitDialogAdvanced;
