import { Alert, AlertTitle } from '@mui/material';
import React from 'react';

interface IProps {
  type?: 'info' | 'warning' | 'error' | 'success';
  variant?: 'filled' | 'outlined';
  color?: 'success' | 'warning' | 'error' | 'info';
  children?: React.ReactNode;
  title?: string;
  sx?: React.CSSProperties;
  onClose?: () => void;
}

const BookitAlertBox = ({ type = 'info', variant, color, title, sx, children, onClose }: IProps) => {
  return (
    <Alert
      style={{
        marginTop: '1rem',
        marginBottom: '1rem',
      }}
      severity={type}
      variant={variant}
      color={color}
      sx={sx}
      onClose={onClose}
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      {children}
    </Alert>
  );
};

export default BookitAlertBox;
