import { createTheme } from '@mui/material';
import Engravers from '../fonts/Engravers.otf';
import '@fontsource/roboto';
import '@fontsource/bebas-neue'

const style = document.createElement('style');
style.innerHTML = `
  @font-face {
    font-family: 'Engravers';
    src: url(${Engravers}) format('opentype');
  }
`;
document.head.appendChild(style);

const theme = createTheme({
  palette: {
    primary: {
      main: '#F56E0F',
      light: '#FFAB40',
      dark: '#944915',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#393636',
      light: '#5E5E5E',
      dark: '#151419',
    },
    error: {
      main: '#F5350F',
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',

    h1: {
      fontSize: '3rem',
      fontWeight: 600,
      fontFamily: 'Bebas Neue',
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 600,
      fontFamily: 'Bebas Neue',
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 600,
      fontFamily: 'Bebas Neue',
    },
    h4: {
      fontSize: '1.3rem',
      fontWeight: 600,
      fontFamily: 'Bebas Neue',
    },
    h5: {
      fontSize: '1.1rem',
      fontWeight: 600,
      fontFamily: 'Bebas Neue',
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.8rem',
    },
  },
});

export default theme;
