import theme from '../../../themes/theme';

const styles: Record<string, React.CSSProperties> = {
  pageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100vh',
    userSelect: 'none',
    backgroundColor: theme.palette.secondary.main
  },

  topContainer: {
    textAlign: 'center',
    margin: '8rem 0 0 0',
  },
  bottomContainer: {
    textAlign: 'center',
    margin: '0 0 2rem 0',
  },

  title: {
    fontFamily: theme.typography.h1.fontFamily,
    fontSize: '4rem',
    letterSpacing: '0.3rem',
    color: theme.palette.primary.contrastText,
    margin: '0 0 2rem 0',
    padding: 0,
    fontWeight: 'bold',
  },

  subtitle: {
    fontFamily: theme.typography.h3.fontFamily,
    fontSize: theme.typography.h3.fontSize,
    letterSpacing: '0.1rem',
    color: theme.palette.primary.contrastText,
    margin: 0,
    padding: 0,
  },

  iconsWrapper: {
    display: 'flex',
    color: theme.palette.primary.contrastText,
    justifyContent: 'center',
    gap: '1rem',
  },

  icon: {
    fontSize: '2.5rem',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
};

export default styles;
