import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import BookitButton from '../BookitButton/BookitButton';

interface BookitDialogInput {
  buttonStyle: React.CSSProperties;
  buttonContainerStyle: React.CSSProperties;
  titleStyle: React.CSSProperties;
  open: boolean;
  onClose: () => void;
  onConfirm: (reservation: any) => void;
  titleLabel: string;
  discardLabel: string;
  confirmLabel: string;
  discardButtonColor: 'primary' | 'secondary';
  confirmButtonColor: 'primary' | 'secondary';
  confirmButtonDisabled?: boolean;
  children?: React.ReactElement;
}

const BookitDialog = ({
  buttonContainerStyle,
  buttonStyle,
  titleStyle,
  open,
  onClose,
  onConfirm,
  titleLabel,
  discardLabel,
  confirmLabel,
  discardButtonColor,
  confirmButtonColor,
  confirmButtonDisabled,
  children,
}: BookitDialogInput) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={titleStyle}>{titleLabel}</DialogTitle>
      {children}
      <div style={buttonContainerStyle}>
        <BookitButton
          style={buttonStyle}
          label={discardLabel}
          onClick={onClose}
          color={discardButtonColor}
          variant='outlined'
          type='basic'
          disabled={false}
        />
        <BookitButton
          style={buttonStyle}
          label={confirmLabel}
          onClick={onConfirm}
          color={confirmButtonColor}
          variant='contained'
          type='basic'
          disabled={confirmButtonDisabled}
        />
      </div>
    </Dialog>
  );
};

export default BookitDialog;
