import theme from '../../../themes/theme';

const styles: Record<string, React.CSSProperties> = {
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 0 3rem 0',
    fontFamily: theme.typography.fontFamily,
  },
  monthName: {
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.primary.contrastText,
    display: 'flex',
    justifyContent: 'center',
    margin: '0 0 1rem 0',
  },
  dayNames: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.primary.contrastText,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    userSelect: 'none',
    margin: '0 0 0.5rem 0',
  },
  dayButton: {
    fontSize: theme.typography.h5.fontSize,
    color: theme.palette.primary.contrastText,
    borderRadius: '50%',
    minWidth: 0,
    width: '3rem',
    margin: '0 0.1rem 0 0.1rem',
    // @ts-ignore
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  selectedButton: {
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.h5.fontWeight,
    backgroundColor: theme.palette.primary.main,
    transitionDuration: '0.5s',
    color: theme.palette.primary.contrastText,
    borderRadius: '50%',
    minWidth: 0,
    width: '3rem',
    margin: '0 0.1rem 0 0.1rem',
    // @ts-ignore
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  prevWeekIcon: {
    fontSize: theme.typography.h1.fontSize,
    cursor: 'pointer',
    color: theme.palette.primary.contrastText,
  },
  nextWeekIcon: {
    fontSize: theme.typography.h1.fontSize,
    cursor: 'pointer',
    color: theme.palette.primary.contrastText,
  },
  divider: {
    width: '80%',
    color: theme.palette.primary.contrastText,
  },

  dayButtonMobile: {
    fontSize: theme.typography.h5.fontSize,
    color: theme.palette.primary.contrastText,
    borderRadius: '50%',
    minWidth: 0,
    width: '2.6rem',
    height: '2.6rem',
  },
  selectedButtonMobile: {
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.h5.fontWeight,
    backgroundColor: theme.palette.primary.main,
    transitionDuration: '0.5s',
    color: theme.palette.primary.contrastText,
    borderRadius: '50%',
    minWidth: 0,
    width: '2.6rem',
    height: '2.6rem',
    // @ts-ignore
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  prevWeekIconMobile: {
    fontSize: theme.typography.h1.fontSize,
    cursor: 'pointer',
    color: theme.palette.primary.contrastText,
  },
  nextWeekIconMobile: {
    fontSize: theme.typography.h1.fontSize,
    cursor: 'pointer',
    color: theme.palette.primary.contrastText,
  },
};

export default styles;
