import ApiHomeServices from '../features/Home/services/apiHomeServices';
import MockHomeServices from '../features/Home/services/mockHomeServices';
import { IAuthService, IBackOfficeAuth, IBackOfficeHomeService, IHomeService, IProfileService } from './serviceTypes';
import ApiAuthServices from '../features/Auth/service/apiAuthServices';
import MockAuthService from '../features/Auth/service/mockAuthService';
import ApiProfileServices from '../features/Home/services/apiProfileServices';
import MockProfileServices from '../features/Home/services/mockProfileServices';
import ApiBackOfficeHomeServices from '../features/BackOffice/BackOfficeHome/service/apiBackOfficeHomeServices';
import MockBackOfficeHomeServices from '../features/BackOffice/BackOfficeHome/service/mockBackOfficeHomeServices';
import MockBackOfficeAuthServices from '../features/BackOffice/BackOfficeAuth/service/mockBackOfficeAuthServices';
import ApiBackOfficeAuthServices from '../features/BackOffice/BackOfficeAuth/service/apiBackOfficeAuthServices';

export default class ApiService {
  private static instance: ApiService;

  private static getInstance(): ApiService {
    if (!ApiService.instance) {
      ApiService.instance = new ApiService();
    }
    return ApiService.instance;
  }

  public authService: IAuthService;
  public profileService: IProfileService;
  public homeService: IHomeService;
  public backOfficeHomeService: IBackOfficeHomeService;
  public backOfficeAuthService: IBackOfficeAuth;

  constructor() {
    if (process.env.REACT_APP_USE_MOCK_DATA === 'true') {
      this.authService = new MockAuthService();
      this.profileService = new MockProfileServices();
      this.homeService = new MockHomeServices();
      this.backOfficeHomeService = new MockBackOfficeHomeServices();
      this.backOfficeAuthService = new MockBackOfficeAuthServices();
    } else {
      this.authService = new ApiAuthServices();
      this.profileService = new ApiProfileServices();
      this.homeService = new ApiHomeServices();
      this.backOfficeHomeService = new ApiBackOfficeHomeServices();
      this.backOfficeAuthService = new ApiBackOfficeAuthServices();
    }
  }

  public static getBoAuthService(): IBackOfficeAuth {
    return this.getInstance().backOfficeAuthService;
  }

  public static getAuthService(): IAuthService {
    return this.getInstance().authService;
  }

  public static getProfileService(): IProfileService {
    return this.getInstance().profileService;
  }

  public static getHomeService(): IHomeService {
    return this.getInstance().homeService;
  }

  static backOfficeHomeService() {
    return this.getInstance().backOfficeHomeService;
  }
}
