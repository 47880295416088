const navigationMiddleware = (store) => (next) => (action) => {
  const { navigateTo, navigate } = action.payload || {};
  if (navigate && navigateTo) {
    navigate(navigateTo);
  }

  return next(action);
};

export default navigationMiddleware;
