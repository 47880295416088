import React, { useCallback, useEffect, useRef, useState } from 'react';
import BookitButton from '../foundation/BookitButton/BookitButton';
import BookitDrawer from '../foundation/BookitDrawer/BookitDrawer';
import { motion } from 'framer-motion';
import { Badge } from '@mui/material';
import { isUserLoggedIn } from '../../utils/utils';
import BookitIcon from '../foundation/BookitIcon/BookitIcon';
import styles from './Profile.style';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../types';
import { useNavigate } from 'react-router';
import { text } from '../../constants/constants';
import ConfirmAccountAlertBox from '../ConfirmAccountAlertBox/ConfirmAccountAlertBox';
import BookitAlertBox from '../foundation/BookitAlertBox/BookitAlertBox';
import { IProfileUpdateDTO } from '../../services/serviceTypes';
import { getProfileDataThunk, updateProfileDataThunk } from '../../features/Home/redux/profileSlice';
import BookitInputField from '../foundation/BookitInputField/BookitInputField';
import { changePasswordThunk, logoutThunk } from '../../features/Auth/redux/authSlice';
import { handleNewErrorThunk } from '../../features/App/redux/errorSlice';
import { AppDispatch } from '../../store/store';
import { getAppointmentsThunk } from '../../features/Home/redux/reservationsSlice';
import { useIsMobile } from '../../hooks/IsMobileHook';
import BookitSnackbar from '../foundation/BookitSnackbar/BookitSnackbar';

const Profile = () => {
  const intervalRef = useRef(null);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showConfirmChangePassMsg, setShowConfirmChangePassMsg] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    phoneCountryCode: '',
    newPassword: '',
    confirmNewPassword: '',
  });
  const [tempFormData, setTempFormData] = useState(formData);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const isLoggedIn = isUserLoggedIn();
  const isMobile = useIsMobile();

  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();
  const profile = useSelector((store: IStore) => store.profile.data);
  const auth = useSelector((store: IStore) => store.auth);

  const toggleDrawer = () => {
    setShowConfirmChangePassMsg(false);
    setIsDrawerOpen((prevState) => {
      if (!prevState) {
        setTempFormData(formData);
      }
      return !prevState;
    });
  };

  const fetchProfileData = () => {
    const clearIntervalIfExist = () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };

    if (!isLoggedIn) {
      clearIntervalIfExist();
      return;
    }
    if (profile.verified_account === false) {
      if (!intervalRef.current) {
        intervalRef.current = setInterval(() => {
          dispatch(getProfileDataThunk({}));
        }, 5000);
      }
    } else {
      clearIntervalIfExist();
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      try {
        dispatch(getAppointmentsThunk({}));
        dispatch(getProfileDataThunk({}));
      } catch (e) {
        dispatch(handleNewErrorThunk(e));
      }
    }
  }, [isLoggedIn]);

  useEffect(() => {
    fetchProfileData();
  }, [profile.verified_account, isLoggedIn]);

  useEffect(() => {
    setFormData({
      ...formData,
      firstName: profile.first_name,
      lastName: profile.last_name,
      phone: profile.phone_number.phone_number,
      phoneCountryCode: profile.phone_number.country_code,
      email: profile.email,
    });
  }, [profile]);

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const handleLogOut = useCallback(() => {
    try {
      setIsDrawerOpen(false);
      setTimeout(async () => {
        await dispatch(logoutThunk()).unwrap();
      }, 500);
    } catch (e) {
      dispatch(handleNewErrorThunk(e));
    }
    setSnackbarOpen(true);
  }, [dispatch]);

  const handleConfirm = () => {
    setFormData(tempFormData);

    const updateProfileData: IProfileUpdateDTO = {
      first_name: tempFormData.firstName,
      last_name: tempFormData.lastName,
      email: tempFormData.email,
      phone_number: {
        country_code: tempFormData.phoneCountryCode,
        phone_number: tempFormData.phone,
      },
    };
    dispatch(updateProfileDataThunk(updateProfileData));
  };

  const handleDiscardChanges = () => {
    setTempFormData(formData);
    toggleDrawer();
  };

  const handlePasswordChange = async () => {
    try {
      await dispatch(changePasswordThunk(profile.email)).unwrap();
      setShowConfirmChangePassMsg(true);
    } catch (e) {
      dispatch(handleNewErrorThunk(e));
    }
  };

  const handleSnackBarClose = () => {
    setSnackbarOpen(false);
  };

  const renderProfilePicture = () => (
    <div style={styles.accountIconWrapper}>
      {profile.profile_picture ? (
        <img src={profile.profile_picture} style={styles.accountInitialsIcon} alt='Account icon' />
      ) : profile.verified_account ? (
        <div style={styles.accountInitialsIcon}>
          {`${formData.firstName[0]?.toUpperCase()}${formData.lastName[0]?.toUpperCase()}`}
        </div>
      ) : (
        <BookitIcon type='account' color='primary' style={{ ...styles.accountInitialsIcon, backgroundColor: 'none' }} />
      )}
    </div>
  );

  const renderProfileIcon = () => {
    if (!profile.verified_account) {
      return (
        <motion.div
          animate={{
            scale: [1, 1.2, 1],
          }}
          transition={{
            duration: 1,
            ease: 'easeInOut',
            repeat: Infinity,
            repeatType: 'loop',
          }}
        >
          <Badge badgeContent={'!'} color='warning' invisible={!isLoggedIn || profile.verified_account}>
            <BookitIcon type='account' style={styles.accountIcon} />
          </Badge>
        </motion.div>
      );
    }

    return <BookitIcon type='account' style={styles.accountIcon} />;
  };

  const renderProfileLabel = () => (isMobile ? renderProfileIcon() : <div>{text.HEADER.PROFILE}</div>);

  const renderChangePasswordButton = () => {
    if (profile.social_account) {
      return;
    }
    return (
      <>
        <hr style={styles.divider} />
        <BookitButton
          variant='outlined'
          label={text.HEADER.CHANGE_PASSWORD_BUTTON}
          type='basic'
          color='secondary'
          onClick={handlePasswordChange}
          style={styles.changePasswordButton}
          loading={auth.loading.changePassword}
        />
      </>
    );
  };

  const renderProfileFields = () => (
    <>
      <div style={styles.infoFieldsWrapper}>
        <p style={styles.infoFieldsTitle}>{text.HEADER.INFO_FIELDS_TITLE}</p>
        <div style={styles.accountDataFieldWrapper}>
          <BookitInputField
            variant='outlined'
            label={text.HEADER.FIRST_NAME_INPUT_FIELD}
            onChange={(e) => setTempFormData({ ...tempFormData, firstName: e.target.value })}
            value={tempFormData.firstName}
            style={styles.accountDataField}
            size='medium'
            fieldTheme='light'
          />
        </div>
        <div style={styles.accountDataFieldWrapper}>
          <BookitInputField
            variant='outlined'
            label={text.HEADER.LAST_NAME_INPUT_FIELD}
            onChange={(e) => setTempFormData({ ...tempFormData, lastName: e.target.value })}
            value={tempFormData.lastName}
            style={styles.accountDataField}
            size='medium'
            fieldTheme='light'
          />
        </div>
        <div style={styles.accountDataFieldWrapper}>
          <BookitInputField
            variant='outlined'
            label={text.HEADER.PHONE_INPUT_FIELD}
            onChange={(e) => setTempFormData({ ...tempFormData, phone: e.target.value })}
            value={tempFormData.phone}
            style={styles.accountDataField}
            size='medium'
            fieldTheme='light'
          />
        </div>
      </div>
      <div style={styles.confirmDataChangesButton}>
        <BookitButton
          variant='outlined'
          label={'odustani'}
          onClick={handleDiscardChanges}
          type='basic'
          color='secondary'
          style={styles.discardButton}
          disableRipple
        />
        <BookitButton
          variant='contained'
          label={text.HEADER.CONFIRM_DATA_CHANGES_BUTTON}
          onClick={handleConfirm}
          type='basic'
          color='primary'
          style={styles.submitButton}
          disableRipple
        />
      </div>
    </>
  );

  const renderAccount = () => {
    return (
      <div style={styles.accountDrawerMainContainer}>
        {renderProfilePicture()}
        <p style={styles.accountEmail}>{formData.email}</p>
        <hr style={styles.divider} />
        {!profile.verified_account && <ConfirmAccountAlertBox onConfirm={handleConfirm} />}
        {profile.verified_account && renderProfileFields()}

        {renderChangePasswordButton()}
        {showConfirmChangePassMsg && <BookitAlertBox type={'info'}>{text.HEADER.RESET_PASSWORD_MESSAGE}</BookitAlertBox>}
        <hr style={styles.divider} />
        <BookitButton
          variant='text'
          label={text.HEADER.LOG_OUT_BUTTON}
          onClick={handleLogOut}
          type='basic'
          color='secondary'
          style={styles.logOutButton}
          loading={auth.loading.logout}
        />
      </div>
    );
  };

  return (
    <>
      <BookitButton
        variant={'text'}
        type={'basic'}
        style={styles.profileButton}
        label={renderProfileLabel()}
        onClick={() => {
          if (isLoggedIn) {
            toggleDrawer();
          } else {
            navigate('/login');
          }
        }}
      />
      <BookitDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        title={text.HEADER.PROFILE}
        childrenStyle={{ overflow: 'auto', padding: '0 0 2rem 0' }}
      >
        {renderAccount()}
      </BookitDrawer>
      <BookitSnackbar open={snackbarOpen} type='info' text={text.HEADER.LOGOUT_MESSAGE} onClose={handleSnackBarClose} />
    </>
  );
};

export default Profile;
