import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiService from '../../../services/api-service';
import { IProfileUpdateDTO } from '../../../services/serviceTypes';

const initialState = {
  data: {
    email: '',
    first_name: '',
    last_name: '',
    phone_number: {
      country_code: '',
      phone_number: '',
    },
    profile_picture: '',
    verified_account: undefined,
  },
};

const profileSlice = createSlice({
  name: 'PROFILE',
  initialState,
  reducers: {
    getProfileDataAction: (state, action) => {
      state.data = action.payload;
    },
    clearProfileDataAction: (state) => {
      state.data = initialState.data;
    },
  },
});

export const { getProfileDataAction, clearProfileDataAction } = profileSlice.actions;
export default profileSlice.reducer;

// Async thunk
export const getProfileDataThunk = createAsyncThunk('GET_PROFILE_DATA', async (payload: any, { dispatch, rejectWithValue }) => {
  try {
    const data = await ApiService.getProfileService().getProfileData();
    dispatch(getProfileDataAction(data));
  } catch (error) {
    return rejectWithValue({
      code: error.code,
      message: error.message,
      status: error.status,
    });
  }
});

export const updateProfileDataThunk = createAsyncThunk(
  'UPDATE_PROFILE_DATA',
  async (payload: IProfileUpdateDTO, { dispatch, rejectWithValue }) => {
    try {
      await ApiService.getProfileService().changeProfileData(payload);
      dispatch(getProfileDataThunk({}));
    } catch (error) {
      return rejectWithValue({
        code: error.code,
        message: error.message,
        status: error.status,
      });
    }
  },
);
