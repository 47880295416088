import { Snackbar } from '@mui/material';
import React from 'react';
import BookitAlertBox from '../BookitAlertBox/BookitAlertBox';

interface IProps {
  open: boolean;
  onClose: () => void;
  type?: 'info' | 'warning' | 'error' | 'success';
  text: string;
}

const BookitSnackbar = ({ open, type = 'success', text, onClose }: IProps) => {
  return (
    <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={open} autoHideDuration={5000} onClose={onClose}>
      <div>
        <BookitAlertBox
          type={type}
          variant='filled'
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          onClose={onClose}
        >
          {text}
        </BookitAlertBox>
      </div>
    </Snackbar>
  );
};

export default BookitSnackbar;
