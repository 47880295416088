import hall_04 from './balon_hala4i5.JPG';
import hall_05 from './balon_hala4i5.JPG';
import hall_01 from './hala01.JPG';
import hall_02 from './hala02.JPG';
import hall_03 from './hala03.JPG';
import logo from './logo.JPG';
import hall_06 from './multisportResized.JPG';
import court_01 from './tenis01.JPG';
import court_02 from './tenis02.JPG';
import court_03 from './tenis03.JPG';
import wallet from './wallet.png';
import empty_reservations from './empty.png';


const images = {
  FOOTBALL_HALL_01: hall_01,
  FOOTBALL_HALL_02: hall_02,
  FOOTBALL_HALL_03: hall_03,
  FOOTBALL_HALL_04: hall_04,
  FOOTBALL_HALL_05: hall_05,
  TENNIS_COURT_01: court_01,
  TENNIS_COURT_02: court_02,
  TENNIS_COURT_03: court_03,
  MULTISPORT_HALL_01: hall_06,
  CANCEL_RESERVATION: wallet,
  NO_RESERVATIONS: empty_reservations,
  LOGO: logo,
};

export default images;
