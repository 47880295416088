import { IAuthService } from '../../../services/serviceTypes';
import ApiClient from '../../../services/api-client';

export default class ApiAuthServices implements IAuthService {
  public async loginEmailAndPassword(email, password): Promise<string> {
    const data = await ApiClient.post<{ jwt: string }>(`/auth/login`, {
      email,
      password,
    });
    return data.jwt;
  }

  public async logout(): Promise<void> {
    await ApiClient.post<{ jwt: string }>(`/auth/logout`, {});
  }

  public async registerEmailAndPassword({ email, password, first_name, last_name, phone_number }): Promise<string> {
    const data = await ApiClient.post<{ jwt: string }>(`/auth/register`, {
      email: email,
      password: password,
      first_name: first_name,
      last_name: last_name,
      phone_number: {
        country_code: phone_number.country_code,
        phone_number: phone_number.phone_number,
      },
    });
    return data.jwt;
  }

  public async changePassword(email): Promise<void> {
    await ApiClient.post<{ jwt: string }>(`/auth/change-password`, {
      email,
    });
  }

  public async forgotPassword(email): Promise<void> {
    await ApiClient.post<{ jwt: string }>(`/auth/forgot-password`, {
      email: email,
    });
  }

  public async resendAccountConfirmationEmail(email: string): Promise<void> {
    await ApiClient.post<{ jwt: string }>(`/auth/resend-account-confirmation`, {
      email: email,
    });
  }

  public async loginSocial(token, client = 'GOOGLE'): Promise<string> {
    const data = await ApiClient.post<{ jwt: string }>(`/auth/login-social`, {
      client: client,
      token: token,
    });
    return data.jwt;
  }

  public async continueViaSocial(token, client = 'GOOGLE'): Promise<string> {
    const data = await ApiClient.post<{ jwt: string }>(`/auth/continue-social`, {
      client: client,
      token: token,
    });
    return data.jwt;
  }

  public async registerSocial({ token, phone_number, client = 'GOOGLE' }): Promise<string> {
    const data = await ApiClient.post<{ jwt: string }>(`/auth/register-social`, {
      client: client,
      token: token,
      phone_number: {
        country_code: phone_number.country_code,
        phone_number: phone_number.phone_number,
      },
    });
    return data.jwt;
  }
}
