import React from 'react';
import { Card, CardContent, CardMedia } from '@mui/material';

interface IProps {
  image?: React.ReactElement;
  title?: any;
  note?: any;
  content: any;
  style: React.CSSProperties;
  contentStyle?: React.CSSProperties;
}

const BookitCard = ({ image, title, content, style, note, contentStyle }: IProps) => (
  <Card sx={style}>
    <CardMedia>{image}</CardMedia>
    <CardContent sx={contentStyle}>
      {title}
      {note}
      {content}
    </CardContent>
  </Card>
);

export default BookitCard;
