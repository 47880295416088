import React, { useCallback, useEffect, useState } from 'react';
import SunriseLogo from '../../../../../customerComponents/components/SunriseLogo/SunriseLogo';
import { useStyles } from './BackOfficeHomePage.style';
import clsx from 'clsx';
import BackOfficeActiveAppointmentsTable from '../../components/BackOfficeActiveAppointmentsTable/BackOfficeActiveAppointmentsTable';
import BackOfficeUsersTable from '../../components/BackOfficeUsersTable/BackOfficeUsersTable';
import BackOfficeAllAppointmentsTable from '../../components/BackOfficeAllAppointmentsTable/BackOfficeAllAppointmentsTable';
import { useNavigate } from 'react-router';
import { isBoUserLoggedIn } from '../../../../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../../store/store';
import { handleNewErrorThunk } from '../../../../App/redux/errorSlice';
import { text } from '../../../../../constants/constants';
import { IStore } from '../../../../../types';
import { logoutBoThunk } from '../../../BackOfficeAuth/redux/boAuthSlice';
import BookitButton from '../../../../../components/foundation/BookitButton/BookitButton';
import BookitDrawer from '../../../../../components/foundation/BookitDrawer/BookitDrawer';
import BackOfficeScheduleAppointmentsTable from '../../components/BackOfficeScheduleAppointmentsTable/BackOfficeScheduleAppointmentsTable';

type TMenuItem = 'appointments' | 'schedule_appointments' | 'users' | 'last_14_days_active_appointments';
interface IMenuDataItem {
  title: string;
  name: TMenuItem;
}

const BackOfficeHomePage = () => {
  const styles = useStyles();
  const navigate = useNavigate();
  const isUserLoggedIn = isBoUserLoggedIn();
  const dispatch = useDispatch<AppDispatch>();
  const { loading } = useSelector((store: IStore) => store.backOfficeAuth);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState<TMenuItem>('schedule_appointments');

  const handleLogOut = useCallback(async () => {
    try {
      await dispatch(logoutBoThunk()).unwrap();
      navigate('/login_bo');
    } catch (error) {
      dispatch(handleNewErrorThunk(error));
    }
  }, [dispatch]);

  useEffect(() => {
    if (!isUserLoggedIn) {
      navigate('/login_bo');
      return;
    }
  }, [isUserLoggedIn]);

  const menuData: IMenuDataItem[] = [
    {
      title: 'Raspored rezervacija',
      name: 'schedule_appointments',
    },
    {
      title: 'Aktivne rezervacije',
      name: 'last_14_days_active_appointments',
    },
    {
      title: 'Sve rezervacije',
      name: 'appointments',
    },
    {
      title: 'Korisnici',
      name: 'users',
    },
  ];

  const toggleDrawerOpen = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const renderMenu = () => (
    <ul>
      {menuData.map((item) => (
        <li
          className={clsx(styles.menuText, { [styles.selectedMenuText]: selectedMenuItem === item.name })}
          onClick={() => setSelectedMenuItem(item.name)}
        >
          <h3
            onClick={() => {
              setSelectedMenuItem(item.name);
            }}
          >
            {item.title}
          </h3>
        </li>
      ))}
    </ul>
  );

  const renderRightSideContent = () => {
    switch (selectedMenuItem) {
      case 'schedule_appointments':
        return <BackOfficeScheduleAppointmentsTable />;
      case 'last_14_days_active_appointments':
        return <BackOfficeActiveAppointmentsTable />;
      case 'appointments':
        return <BackOfficeAllAppointmentsTable />;
      case 'users':
        return <BackOfficeUsersTable />;
    }
  };

  const renderRightSidePlaceholder = () => {
    return (
      <div className={styles.rightSideContentWrapper}>
        <h1 className={styles.titleText}>Admin panel</h1>
        {renderRightSideContent()}
      </div>
    );
  };

  const renderLeftSideDrawer = () => (
    <BookitDrawer
      isOpen={isDrawerOpen}
      onIconButtonClick={() => {
        toggleDrawerOpen();
      }}
      onClose={() => setIsDrawerOpen(false)}
      childrenStyle={{ overflow: 'auto' }}
    >
      <div className={styles.logoWrapper}>
        <SunriseLogo size={'large'} />
      </div>
      {renderMenu()}
      <BookitButton
        variant='text'
        label={text.HEADER.LOG_OUT_BUTTON}
        onClick={handleLogOut}
        type='basic'
        color='secondary'
        loading={loading}
        style={{ color: 'orange' }}
      />
    </BookitDrawer>
  );

  return (
    <div className={styles.wrapper}>
      {renderLeftSideDrawer()}
      {renderRightSidePlaceholder()}
    </div>
  );
};

export default BackOfficeHomePage;
