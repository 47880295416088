import React, { useEffect, useState } from 'react';
import BookitDialog from '../../../../../components/foundation/BookitDialog/BookitDialog';
import { TextField } from '@mui/material';
import { text } from '../../../../../constants/constants';
import styles from './BackOfficeViewEventDialog.style';

interface IProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (reservation: any) => void;
  selectedSlotData?: any;
}

const localeDateString = 'sr-RS';

const BackOfficeViewEventDialog = ({ open, onClose, onConfirm, selectedSlotData }: IProps) => {
  const [note, setNote] = useState(!selectedSlotData.userName ? selectedSlotData.title : selectedSlotData.note);
  const [date, setDate] = useState(new Date().toDateString());
  const [time, setTime] = useState(new Date().toTimeString());

  useEffect(() => {
    setDate(new Date(selectedSlotData?.start).toLocaleDateString(localeDateString));
    setTime(new Date(selectedSlotData?.start).toLocaleTimeString(localeDateString));
  }, [selectedSlotData]);

  const renderNote = () => (
    <div style={styles.noteWrapper}>
      <p style={styles.noteTitle}>Beleška:</p>
      <TextField
        multiline
        placeholder={'Unesite belešku'}
        value={note}
        onChange={(e) => setNote(e.target.value)}
        maxRows={4}
        sx={styles.note}
      />
    </div>
  );

  return (
    <BookitDialog
      buttonContainerStyle={styles.buttonContainer}
      buttonStyle={styles.button}
      open={open}
      onClose={onClose}
      onConfirm={() => {
        console.log('onConfirm');
      }}
      titleLabel={'Pregled rezervacije'}
      titleStyle={styles.title}
      discardButtonColor='primary'
      confirmButtonColor='primary'
      discardLabel={text.DIALOG_TEXT.DEFAULT_DISCARD}
      confirmLabel={text.DIALOG_TEXT.DEFAULT_CONFIRM}
      confirmButtonDisabled={!note}
    >
      <div style={styles.dialogContainer}>
        {selectedSlotData.userName && (
          <>
            <div style={styles.filedWrapper}>
              <p>Ime i prezime:</p>
              <p>{selectedSlotData.userName}</p>
            </div>
            <div style={styles.filedWrapper}>
              <p>Broj telefona:</p>
              <p>{selectedSlotData.userPhoneNumber}</p>
            </div>
          </>
        )}
        <div style={styles.filedWrapper}>
          <p>Datum:</p>
          <p>{date}</p>
        </div>
        <div style={styles.filedWrapper}>
          <p>Vreme:</p>
          <p>{time}</p>
        </div>
        <div style={styles.filedWrapper}>
          <p>Teren:</p>
          <p>{selectedSlotData.resourceName}</p>
        </div>
        {renderNote()}
      </div>
    </BookitDialog>
  );
};

export default BackOfficeViewEventDialog;
