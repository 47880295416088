import React, { useEffect, useState } from 'react';
import BookitCard from '../../components/foundation/BookitCard/BookitCard';
import BookitTimePicker from '../../components/foundation/BookitTimePicker/BookitTimePicker';
import { IField } from '../../types';
import styles from './FieldCard.style';
import { formatDateToHoursString } from '../../utils/utils';
import BookitIcon from '../foundation/BookitIcon/BookitIcon';
import { text } from '../../constants/constants';

interface IProps {
  field: IField;
  onTimeSlotClick: (field: IField, timeSlot: string, slot: any) => void;
  bookedField: IField;
  selectedDate: Date;
  selectedTime: string;
  slots: string[];
}

const FieldCard = ({ field, onTimeSlotClick, bookedField, selectedDate, selectedTime, slots = [] }: IProps) => {
  const [cardStyle, setCardStyle] = useState(styles.container);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const selected = bookedField.id === field.id;
    setCardStyle(selected ? styles.containerSelected : styles.containerNotSelected);
  }, [bookedField, field]);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const handleImageError = () => {
    setImageLoaded(false);
  };

  const renderImage = () => (
    <div style={styles.imageContainer}>
      {!imageLoaded && (
        <div style={styles.imagePlaceholderContainer}>
          <BookitIcon type='imagePlaceholder' style={styles.imagePlaceholder} />
        </div>
      )}
      <img src={field.image_url} alt='Field' onLoad={handleImageLoad} onError={handleImageError} style={styles.image} />
    </div>
  );

  const renderTimePicker = () => {
    if (slots.length === 0) {
      return (
        <div>
          <BookitIcon type='timeslotsPlaceholder' style={styles.timeslotsPlaceholder} />
          <p style={styles.timeslotsPlaceholderText}>{text.FIELD_CARD_NO_SLOTS}</p>
        </div>
      );
    }
    return (
      <div>
        {slots.map((slot: any) => (
          <BookitTimePicker
            key={slot.start_date}
            time={formatDateToHoursString(slot.start_date)}
            bookedField={bookedField}
            selectedTime={selectedTime}
            onClick={onTimeSlotClick}
            field={field}
            disabled={!slot.available}
            hash={slot.hash}
          />
        ))}
      </div>
    );
  };

  return (
    <BookitCard
      style={!selectedDate ? styles.containerDisabled : cardStyle}
      title={<p style={styles.title}>{field.name}</p>}
      note={<p style={styles.note}>{field.description}</p>}
      image={renderImage()}
      content={renderTimePicker()}
    />
  );
};

export default FieldCard;
